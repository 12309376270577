export class Login {
  title: string;
  titleResetPassword: string;
  //userName: string;
  //password: string;
}

export class ResetPassword {
  usernameReset: string;
  email: string;
}

export class ChangePassword {
  newPasswordConfirm: string;
  newPassword: string;
  oldPassword: string;
}

export class ShopDetails {
  loginDescription: string;
  mail: string;
}

export class QuestionResponse {
  idWebSiteFlow: string;
  idQuestion: string;
  pageName: string;
  nextQuestion: string;
  pageNameNext: string;
  questionLabel: string;
  idResponse: string;
  responseRoot: string;
  responseLabel: string;
}

export class ClientData {
  firstName: string;
  lastName: string;
  mobile: string;
  phone: string;
  mail: string;
  taxCode: string;
  address: string;
  zipCode: string;
  city: string;
  idProvince: number;
  idLogin = 0;
  total: number;
  vendorReference: string;
  relatedVendorReference: string;
  notes: string;
}

export class CustomerData {
  firstName: string;
  lastName: string;
  mobile: string;
  phone: string;
  mail: string;
  address: string;
  zipCode: string;
  city: string;
  idProvince: number;
  idloginShop: number;
}

export class SopralluogoData {

  inspectionCode: string;
  extraAccessoriesDescription: string;
  extraAccessoriesValue: number;
  discountPercentage: number;
  //customer: CustomerData
}

export class Contatorekwdata {
  kwData: string;
}
export class KoPrevFv {
  motivazione: string;
  motivazioneCustom: string;

}
export class SearchtData {
  date: string;
  ticketNumber: number;
  type: string;
  total: number;
  firstName: string;
  lastName: string;
}

export class ResponsePCalc {
  idWebSiteFlow: number;
  responseValue: string;
  responseValue2?: string;
}


export class PriceCalc {
  responseList: Array<ResponsePCalc>;
  additionalWorks: { idElement: number; description: string; quantity: number; }[];
  firstName: string;
  lastName: string;
  mobile: string;
  phone: string;
  mail: string;
  taxCode: string;
  address: string;
  zipCode: string;
  city: string;
  notes: string;
  idProvince: number;
  idLogin: number;
  //added
  inspectionCode: string;
  extraAccessoriesDescription: string;
  extraAccessoriesValue: number;
  discountPercentage: number;

  idWebSiteFlowTracking?: number;
  // estimateTotal: number;
  appointmentDate?: string
  idInstallationDrawAttachment?: number
  sellVendorReference?: string;

  IDpackage?:string;
  ProductType?:string;
}

export class PriceEstimationCalc {
  responseList: Array<ResponsePCalc>;
  idLogin: number;
  inspectionCode: string;
  extraAccessoriesDescription: string;
  extraAccessoriesValue: number;
  discountPercentage: number;
  additionalWorks: { idElement: number; description: string; quantity: number; }[];
}
export class PriceFvEstimationCalc {
  responseList: Array<ResponsePCalc>;
  idLogin: number;
  dimTetto: string;
}

export class PriceFvCalc {
  responseList: Array<ResponsePCalc>;
  idLogin: number;
  firstName: string;
  lastName: string;
  mobile: string;
  phone: string;
  mail: string;
  taxCode: string;
  address: string;
  zipCode: string;
  city: string;
  notes: string;
  idProvince: number;
  idWebSiteFlowTracking?: number;
  appointmentDate?: string;
  servicetype?: string;


}
export class Error {
  errorTitle: string
  errorDetail: string
}

//export class ShopList {
//  idLogin: number;
//  login: string

//}

export class ModalConfirm {
  title: string
  details: string

}

export interface AdditionalWork {
  idElement: number;
  description: string;
  shortDescription?: string;
  value: number;
  quantity: number;
  editableDescription?: boolean;
  editablePrice?: boolean;
  editableQuantity?: boolean;
}

export class InstallationData {
  btu: string;
  height: string;
}

export class DimensioniTetto {
  dim: string;
}
export class uploadedFile {

  filename: string;
  filesize: number;
  idAttachment: number;

}
