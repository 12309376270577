import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { SopralluogoData } from "../_models/models";
import { Location } from "@angular/common";
import { ModalErrorComponent } from "../modal-error/modal-error.component";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UcChoice } from "../_models/uc-choice";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  optionFirstQuestion: string = "";

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideSopralluogo: boolean = true;
  hideNumeroPreventivi: boolean = true;

  maxAllowedQuotations: number = 0;
  remainingQuotations: number = null;
  maxQuotations: number = 0;
  lastInspectionCode: string = "";
  quotationsErrorMessage: string = "";
  generatingQuotations: boolean = false;
  loadingNumeroPreventivi: boolean = false;

  inputSopralluogo: string = "";
  DataSopralluogoForm: FormGroup;
  Sopralluogo: SopralluogoData = new SopralluogoData();

  question: string = "";
  answers: UcChoice[] = [];
  currentAnswer: UcChoice | null = null;
  data: any[] = [];
  inspectionCodes: any;
  currentDataRecord: any;

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private _location: Location,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService
  ) {}

  public optionClass(index : number) {
    const classes = [
      'new-radiobutton-blue',
      'new-radiobutton-orange',
      'new-radiobutton-green',
      'new-radiobutton-light-blue'
    ]

    return classes[index % classes.length];
  }

  public optionParentClass(index : number) {
    const classes = [
      'new-blue label-visible',
      'new-orange label-visible',
      'new-green label-visible',
      'new-light-blue label-visible'
    ]

    return classes[index % classes.length];
  }

  ngOnInit() {

    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;

      this.climaSmartService
        .GetQuestionResponseList("selezionaPratica")
        .subscribe(
          (data) => {
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;

            this.data = data;
            this.question = this.data[0].questionLabel;

            const qArray: UcChoice[] = [];
            for (const q of this.data) {
              qArray.push({ label: q.responseLabel, id: q.idWebSiteFlow });
            }

            this.answers = qArray;
          },
          (err) => {
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          }
        );
    } catch {
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  onChoiceChanged($event: UcChoice) {
    this.currentAnswer = this.answers.find((a) => $event && a.id === $event.id);
    this.currentDataRecord = this.data.find(
      (d) => this.currentAnswer && d.idWebSiteFlow === this.currentAnswer.id
    );
  }

  isFormValid() {
    return !!this.currentAnswer;
  }

  goBack() {
   // console.log("inside go back button");
    this.preventivoService.setService('');
    this.preventivoService.setServiceType('');
    this._location.back();
  }

  setFirstQuestion() {
    this.preventivoService.setService(this.currentAnswer.id);
    this.preventivoService.setServiceType(this.currentDataRecord.responseRoot);

    // TODO: move to configuration
    const answers = [
      { answer: "onSite", route: "/zonaGeografica" },
      { answer: "onSite_MW", route: "/zonaGeografica" },
      { answer: "onSiteButali", route: "/zonaGeografica" },
      { answer: "priceCalculator", route: "/zonaGeografica" },
      { answer: "priceCalculatorWithEcobonus", route: "/zonaGeografica" }
    ];

    const nextRoute = answers.find((a) => a.answer == this.optionFirstQuestion);
    if (!nextRoute) {
      return;
    }

    this.router.navigate([nextRoute.route]);
  }
}
