import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-list-note',
  templateUrl: './modal-list-note.component.html',
  styleUrls: ['./modal-list-note.component.css']
})
export class ModalListNoteComponent implements OnInit {

  @ViewChild('childModal') public childModal: ModalDirective;
  modal = { ticket : '' }

  notesList = [];

  constructor(
 ) { }

  ngOnInit() {
  }

  public show(modal) {
    this.modal = modal;
    this.childModal.show()
  }


  hide() {
    this.childModal.hide()
  }

}
