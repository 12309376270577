import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements OnInit {

  loading: boolean = true
  hidePage: boolean = true
  hideLoader: boolean = false
  hideTimeSlot: boolean = true
  hideNoTimeSlotAvailableMsg: boolean = true
  hideNoAvailabilityMonthMsg: boolean = true
  showCalendarLoader: boolean = false
  hideMsg: boolean = true

  selectedDayArray = []

  currentDate = new Date();
  //set last available date for calendar
  sd = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1);
  lastAvailableDate = new Date(this.sd.getFullYear(), this.sd.getMonth() + 3, 1);

  //set start and end date for current month
  startDate = (new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1)).toISOString()
  endDate = (new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0)).toISOString()

  dateSelectedConverted: any
  selectedIndex: number = null

  calendarNextPrev: string = ""
  appointmentDateConverted: string = ""

  appointmentDate: string = ""
 
  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent
  options: any;
  eventsModel: any[] = []
  calendarWeekends = true
  calendarEvents: EventInput[] = [
    { title: 'Event Now', start: new Date() }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {

  }

  ngOnInit() {

    if (this.preventivoService.getAppointmentDate()) {
      this.appointmentDate = this.preventivoService.getAppointmentDate();
      console.log('if', this.appointmentDate)
      this.convertAppointmentDate()
      this.selectedMonthAvailabilitiesList()

    }
    else {
      console.log('else')
      this.loadAvailabilitiesList(this.startDate, this.endDate)

    }
    //calendar options
    this.options = {

      //to disable popover on events
      eventClick: function (info) {
      },

      //add fc-highlight class to selected day when selectable is true
      select: function (info) {
      },

      //event Limit 1 to show only 1 event for each day
      eventLimit: 1,
      eventLimitText: "",
      theme: 'standart',
      header: {
        right: 'next',
        center: 'title',
        left: 'prev',
      },

      //start and end of the calendar date
      validRange: {
        start: this.currentDate,
        //end: this.lastAvailableDate
      },

      visibleRange: {
        start: this.startDate,
        //end: this.lastAvailableDate
      },
      //limit highlight(selected day) to a single day
      selectConstraint: {
        //selecatble days of the week
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6]
      },
      plugins: [
        dayGridPlugin,
        interactionPlugin],
      locales: [itLocale],
      locale: 'it',
      eventBackgroundColor: '#8bbb13',

      customButtons: {
        next: {
          click: this.nextMonth.bind(this),
        },
        prev: {
          click: this.prevMonth.bind(this),
        }
      }
    };

  }

  //call api Availabilities List
  callApi(startDate, endDate) {
    try {
      this.climaSmartService.GetAvailabilitiesList(startDate, endDate).subscribe(res => {
        if (res) {
          this.eventsModel = res
          this.loading = false
          this.hideLoader = true
          this.hidePage = false
          this.showCalendarLoader = false
          if (res.length > 0) {
            var startDayForMonth = res[0].start
            //get Month and Year from start day to compare it with selected appointment date Month and Year
            var getMonthYear = startDayForMonth.substring(0, 7)
            if (this.appointmentDate !== '') {
              var getMonthYearSelected = this.appointmentDate.substring(0, 7)
            }
            this.showFirstDayAvailabilities(startDayForMonth)
            if (this.appointmentDate !== '' && getMonthYear === getMonthYearSelected) {
              if (this.calendarNextPrev === "NEXT" || this.calendarNextPrev === "PREV") {
                this.showSelectedDateTime(this.appointmentDate)
              }
              else {
                const calendarApi = this.fullcalendar.getApi();
                calendarApi.gotoDate(this.appointmentDate)
                this.showSelectedDateTime(this.appointmentDate)
              }
            }
          }
          else if (this.eventsModel.length === 0) {
            this.hideNoTimeSlotAvailableMsg = true
            this.hideNoAvailabilityMonthMsg = false
          }
        }

        window.dispatchEvent(new Event('resize'))
        if (this.calendarNextPrev === 'NEXT') {
          this.updateCalendarNext()
        }
        else if (this.calendarNextPrev === 'PREV') {
          this.updateCalendarPrev()
        }
      }
        , err => {
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        }
      );
    }
    catch (ex) {
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  //to get Availabilities List
  loadAvailabilitiesList(startDate, endDate) {
    this.showCalendarLoader = true
    this.callApi(startDate, endDate)
  }

  //to get Availabilities List with appointment date selected
  selectedMonthAvailabilitiesList() {
    const appDate = new Date(this.appointmentDate)
    this.startDate = (new Date(appDate.getFullYear(), appDate.getMonth())).toISOString();
    this.endDate = (new Date(appDate.getFullYear(), appDate.getMonth() + 1, 0)).toISOString()
    this.loadAvailabilitiesList(this.startDate, this.endDate)
  }


  //go to next month in calendar
  nextMonth() {
    this.calendarNextPrev = 'NEXT'
    this.resetSelectedDateTime()
    const calendarApi = this.fullcalendar.getApi();
    const currentDate = calendarApi.getDate();
    this.startDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)).toISOString();
    this.endDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1 + 1, 0)).toISOString()
    this.loadAvailabilitiesList(this.startDate, this.endDate)
  }

  updateCalendarNext() {
    const calendarApi = this.fullcalendar.getApi();
    calendarApi.next()
  }

  //go to prev month in calendar
  prevMonth() {
    this.calendarNextPrev = 'PREV'
    this.resetSelectedDateTime()
    const calendarApi = this.fullcalendar.getApi();
    const currentDate = calendarApi.getDate();
    this.startDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)).toISOString();
    this.endDate = (new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)).toISOString();
    this.loadAvailabilitiesList(this.startDate, this.endDate)
  }

  updateCalendarPrev() {
    const calendarApi = this.fullcalendar.getApi();
    calendarApi.prev();
  }

  showFirstDayAvailabilities(startDate) {
    const startDateForMonth = startDate.substring(0, 10)
    this.dateClick(startDateForMonth)

  }

  dateClick(model) {
    if (typeof model === "string") {
      var dateTimeArraySelected = this.eventsModel.filter(x => x.start.substring(0, 10) === model);
      var convertStartDateForMonth = model.split("-");
      var dateSelectedConverted = convertStartDateForMonth[2] + "/" + convertStartDateForMonth[1] + "/" + convertStartDateForMonth[0].substring(2);

    }
    else {
      var dateTimeArraySelected = this.eventsModel.filter(x => x.start.substring(0, 10) === model.dateStr);
      var convertDate = model.dateStr.split("-");
      var dateSelectedConverted = convertDate[2] + "/" + convertDate[1] + "/" + convertDate[0].substring(2);

    }

    this.dateSelectedConverted = dateSelectedConverted
    this.controlDateTimeArrayLength(dateTimeArraySelected)
  }

  controlDateTimeArrayLength(dateTimeArraySelected) {

    if (dateTimeArraySelected.length === 0) {
      this.hideTimeSlot = true;
      this.hideNoTimeSlotAvailableMsg = false;
    }
    else {
      this.selectedDayArray = dateTimeArraySelected
      this.hideTimeSlot = false;
      this.hideNoTimeSlotAvailableMsg = true
    }

  }


  selectedTimeSlot(appointmentDate, i) {
    this.selectedIndex = i;
    this.appointmentDate = appointmentDate
    this.convertAppointmentDate()
  }


  resetSelectedDateTime() {
    this.selectedIndex = null
    //this.appointmentDate = ""
    this.hideTimeSlot = true
    this.hideMsg = true
    this.hideNoTimeSlotAvailableMsg = true
    this.hideNoAvailabilityMonthMsg = true
  }

  setAppointment() {
    const overrideNextPageName = this.route.snapshot.queryParamMap.get('overrideNextPageName');
    if (overrideNextPageName) {
      this.preventivoService.setAppointmentDateFv(this.appointmentDate);
      this.router.navigate([overrideNextPageName]);
      
    } else {

      this.preventivoService.setAppointmentDate(this.appointmentDate);
      this.router.navigate(["/datiCliente"]);
    }

  }

  goBack() {
    this.appointmentDate = "";
    this.preventivoService.setAppointmentDateFv('');
    this.preventivoService.setAppointmentDate('');
    this._location.back();
  }

  showSelectedDateTime(selectedDateTime) {
    //convert date time selected
    var dateSelected = selectedDateTime.substring(0, 10)
    var dateTimeObjSelected = this.eventsModel.filter(x => x.start.substring(0, 10) === dateSelected);
    if (this.eventsModel.find(x => x.start === selectedDateTime) === undefined) {
      this.hideMsg = false
      this.appointmentDate = ""
    }
    //covert date
    var convertDate = dateSelected.split("-");
    var dateSelectedConverted = convertDate[2] + "/" + convertDate[1] + "/" + convertDate[0].substring(2);
    this.dateSelectedConverted = dateSelectedConverted
    this.selectedDayArray = dateTimeObjSelected
    this.hideTimeSlot = false;
    this.hideNoTimeSlotAvailableMsg = true
  }

  convertAppointmentDate() {
    //convert appointment date
    var appDate = (this.appointmentDate.substring(0, 10)).split("-")
    var appointmentDateSelected = appDate[2] + "/" + appDate[1] + "/" + appDate[0].substring(2);
    var appointmentTimeSelected = this.appointmentDate.substring(11, 16)
    this.appointmentDateConverted = appointmentDateSelected + ' ' + appointmentTimeSelected
  }

}
