import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { SopralluogoData, PriceCalc, ResponsePCalc, ClientData, AdditionalWork } from "../_models/models";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { environment } from "src/environments/environment"
import { Parameters } from "../_service/parameters";
import { ShopDetails } from "../_models/models";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";

@Component({
  selector: 'app-sopralluogo',
  templateUrl: './sopralluogo.component.html',
  styleUrls: ['./sopralluogo.component.css']
})
export class SopralluogoComponent implements OnInit {

  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  public ResponseList: any;
  public ResponseMessage: string = "";

  inputSopralluogo: string = "";
  inputOpereExtra: string = "";
  inputOpereExtraPrice: string = "";
  inputDiscountPercent: string = "";

  loading: boolean = false;
  hideLoader: boolean = true;
  hidePage: boolean = true;

  additionalWorks: AdditionalWork[] = null;

  public DataSopralluogoForm: FormGroup;

  public Sopralluogo: SopralluogoData = new SopralluogoData();

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService) { }

  ngOnInit() {


    if (this.preventivoService.getInspectionCode() || this.preventivoService.getOpereExtra() ||
      this.preventivoService.getOpereExtraPrice() || this.preventivoService.getDiscountPercent()) {
      this.Sopralluogo.inspectionCode = this.preventivoService.getInspectionCode();
      this.Sopralluogo.extraAccessoriesDescription = this.preventivoService.getOpereExtra();
      this.Sopralluogo.extraAccessoriesValue = this.preventivoService.getOpereExtraPrice();
      this.Sopralluogo.discountPercentage = this.preventivoService.getDiscountPercent();

      this.loadInspectionCustomerData();

    }

    this.DataSopralluogoForm = this._fb.group({
      DataSopralluogoFormGroup: this._fb.group({
        inspectionCode: ["", [Validators.required]],
        opereExtra: ["", []],
        opereExtraPrice: ["", []]
      })
    });
  }

  loadInspectionCustomerData() {

  this.loading = true;
  this.hidePage = true;
  this.hideLoader = false;

    try {

      this.climaSmartService.GetCustomerByInspectCode(this.Sopralluogo.inspectionCode)
        .subscribe(
          data => {
            this.ResponseList = data;
             this.loading = false;
             this.hideLoader = true;
            if (data.codeExist == true) {
              this.hidePage = false;
              this.preventivoService.setNomeCliente(data.customer.firstName);
              this.preventivoService.setCognomeCliente(data.customer.lastName);
              this.preventivoService.setTelefonoCliente(data.customer.phone);
              this.preventivoService.setMobileCliente(data.customer.mobile);
              this.preventivoService.setEmailCliente(data.customer.mail);
              this.preventivoService.setIndirizzoCliente(data.customer.address);
              this.preventivoService.setCittaCliente(data.customer.city);
              this.preventivoService.setProviniciaCliente(data.customer.idProvince);
              this.preventivoService.setCapCliente(data.customer.zipCode);
              this.preventivoService.setLoginCliente(+(data.customer.idLoginShop.toString()));
              this.preventivoService.setInspectionCode(this.Sopralluogo.inspectionCode);
              this.preventivoService.setOpereExtra(this.Sopralluogo.extraAccessoriesDescription);
              this.preventivoService.setCodiceFiscaleCliente(data.customer.taxCode);
              this.preventivoService.setCustomerNotes(data.customer.notes);
            }

            else {
              this.modalError.show({ errorTitle: "Errore", errorDetail: data.message });
              this.hideLoader = true;
              this.loading = false;
            }

          }
          , err => {

            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.hideLoader = true;
              this.loading = false;
            }
          });

    } catch {

      this.loading = true;
      this.hideLoader = false;
    }

    try {

      this.climaSmartService.getAdditionalWork(this.Sopralluogo.inspectionCode)
        .subscribe(d => {

          this.hideLoader = true;
          this.loading = false;
          this.additionalWorks = d;
          if (this.preventivoService
            && this.preventivoService.getAdditionalWorks()
            && this.preventivoService.getAdditionalWorks().length > 0) {

            for (const awrk of this.additionalWorks) {

              const found = this.preventivoService
                .getAdditionalWorks()
                .find(aw => awrk.idElement === aw.idElement);

              if (found) {

                if (awrk.editableQuantity) {
                  awrk.quantity = found.quantity;
                }

                if (awrk.editablePrice) {
                  awrk.value = found.value;
                }

                if (awrk.editableDescription) {
                  awrk.description = found.description;
                }

              }

            }

          }

        }, err => {

          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.hideLoader = true;
            this.loading = false;
          }

        });

    } catch {
      this.loading = true;
      this.hideLoader = false;
    }

  }

  onInspectCodeChange(inspCode: string) {
    if (inspCode.length != 16) {
      this.hidePage = true;
      return;
    }

    this.loading = true;
    this.hideLoader = false;
    this.hidePage = true;

    this.loadInspectionCustomerData()

  }

  isFormValid() {
    let inspCodeLen = false;
    if ( this.Sopralluogo.inspectionCode != undefined && this.Sopralluogo.inspectionCode.trim().length == 16){
      inspCodeLen = true;
    }

    let codeExist = false;
    if (this.ResponseList) {
      if (this.ResponseList.codeExist == true) {
        codeExist = true;
      } else {
        codeExist = false;
      }

    }
    let extra = false;

    if (this.Sopralluogo.extraAccessoriesDescription == undefined &&
      this.Sopralluogo.extraAccessoriesValue == undefined
    ) {
      extra = true;
    }

    if (this.Sopralluogo.extraAccessoriesDescription != undefined &&
      this.Sopralluogo.extraAccessoriesDescription.trim().length > 0
    ) {
      extra = true;
    }

    const e = (this.additionalWorks ? this.additionalWorks : []).find(aw => environment.idElementExtra.findIndex(i => i === aw.idElement) >= 0);
    extra = extra || !e || e.quantity <= 0;

    return (inspCodeLen && codeExist && extra);
  }

  isPrezzoValid() {
    if (this.Sopralluogo.extraAccessoriesDescription == undefined) {

      return false;
    }
    if (this.Sopralluogo.extraAccessoriesDescription != undefined &&
      this.Sopralluogo.extraAccessoriesDescription.trim().length > 0 &&
      this.Sopralluogo.extraAccessoriesValue == undefined &&
      this.Sopralluogo.extraAccessoriesValue != 0) {
      return true;
    }

    if (this.Sopralluogo.extraAccessoriesDescription != undefined &&
      this.Sopralluogo.extraAccessoriesDescription.trim().length > 0 &&
      this.Sopralluogo.extraAccessoriesValue != undefined &&
      this.Sopralluogo.extraAccessoriesValue == 0) {
      return true;
    }

    if (this.Sopralluogo.extraAccessoriesDescription != undefined &&
      this.Sopralluogo.extraAccessoriesDescription.trim().length > 0 &&
      this.Sopralluogo.extraAccessoriesValue != undefined &&
      this.Sopralluogo.extraAccessoriesValue >= 0) {
      return false;
    }
    return false;
  }


  isOperaExtraValid() {
    return true;
  }

  setDatiSopralluogo() {
    this.preventivoService.clearAdditionalWork();
    this.additionalWorks.forEach(aw => {
      const arg: AdditionalWork = {
        idElement: aw.idElement,
        description: aw.description,
        quantity: aw.quantity,
        value: aw.value
      };
      this.preventivoService.addAdditionalWork(arg);
    });
    this.preventivoService.setOpereExtra(this.Sopralluogo.extraAccessoriesDescription);
    this.router.navigate(["/datiPreventivo"]);

  }

  goBack() {
    this._location.back();
  }

}
