import { Injectable } from "@angular/core";
import { observable, Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http"; //from version5
import { Parameters } from "./parameters";
import { QuestionResponse, PriceEstimationCalc, PriceFvEstimationCalc } from "../_models/models";
import { Router } from "@angular/router";
import { delay, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ClimaSmartService {

  private downloadingAttachmentSubject: Subject<{ id: string, loading: boolean }> = new Subject<{ id: string, loading: boolean }>();

  questionResponse: any;

  constructor(
    private http: HttpClient,
    private router: Router) {
  }

  get downloadingAttachment$(): Observable<{ id: string, loading: boolean }> {
    return this.downloadingAttachmentSubject.asObservable();
  }

  loginAccess(username, password): Observable<any> {
    var data = {
      username: username,
      password: password
    };
    return this.http.post(
      Parameters.getBaseUrl() + "/api/v1/login",
      JSON.stringify(data),
      Parameters.getHeaderAppJson()
    );
  }

  logoutAndRedirect(thenReload: boolean = false, appenRedirectUrl: boolean = true) {

    this.logout();
    if (this.router.url.indexOf("redirectURL") < 1) {

      let queryParams = null;
      if (appenRedirectUrl) {
        queryParams = { 'redirectURL': this.router.url };
      }

      let promise = this.router.navigate(["/login"], { queryParams: queryParams });
      if (thenReload) {

        promise.then(p => window.location.reload());
      }

    }
  }

  logout() {
    localStorage.clear();
  }

  resetPassword(paramLogin, paramEmail): Observable<any> {
    return this.http.put(
      Parameters.getBaseUrl() +
      "/api/v1/Login/ResetPassword?Login=" +
      paramLogin +
      "&Mail=" +
      paramEmail,
      {},
      Parameters.getHeaderAppJsonAndToken()
    );
  }

  ChangePassword(paramPassword, paramOldPassword): Observable<any> {
    return this.http.put(
      Parameters.getBaseUrl() +
      "/api/v1/DatiAnagrafici/ChangePassword",
      JSON.stringify({ password: paramPassword, oldPassword: paramOldPassword }),
      Parameters.getHeaderAppJsonAndToken()
    );
  }

  GetFirstQuestionResponseList(): Observable<any> {
    // console.log(" inside GetFirstQuestionResponseList");
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetFirstQuestion/true',
      Parameters.getHeaderToken()
    )
  }

  GetQuestionResponseList(pageName): Observable<any> {
    //console.log(" GetQuestionResponseListByPageName");
    // response della pagina (h1, label, etc.)
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetByPageName/' + pageName,
      Parameters.getHeaderAppJsonAndToken()
    )
  }

  GetProductList(productType, idQuestion, idPredisposizione): Observable<any> {
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetProductByType/' + productType + '/' + idQuestion,
      Parameters.getHeaderAppJsonAndToken()
    )
  }

  QuestionResponses(data): Observable<any> {
    // console.log("QuestionResponses");
    return this.http.post(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse',
      JSON.stringify(data),
      Parameters.getHeaderAppJsonAndToken()
    )
  }
  QuestionResponsesFv(data): Observable<any> {
    return this.http.post(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/responsesFv',
      JSON.stringify(data),
      Parameters.getHeaderAppJsonAndToken()
    )
  }
  GetQuestionResponses(idWebSiteFlowTracking): Observable<any> {
    // console.log("GetQuestionResponses");
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPriceCalc/' + idWebSiteFlowTracking,
      Parameters.getHeaderToken()
    )
  }

  GetProvince(): Observable<any> {
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/DatiAnagrafici/GetProvince',
      Parameters.getHeaderToken())
  }

  GetShopList(): Observable<any> {
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/DatiAnagrafici/GetLogin',
      Parameters.getHeaderToken())
  }

  GetPriceCalcListStore(): Observable<any> {
    console.log('GetPriceCalcListStore')
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPriceCalcListStore',
      Parameters.getHeaderToken())
  }

  GetPriceCalcListStoreWithDate(startDate, endDate, limit, offset, ecobonusOnly, searchTerm): Observable<any> {
    var data
    if (endDate > new Date(2018, 0, 1)) {
      data = {
        "startDate": startDate,
        "endDate": endDate,
        "limit" : limit,
        "offset": offset,
        "searchTerm": searchTerm,
        "ecobonusOnly": ecobonusOnly
      }
    }
    else {
      data = {
        "startDate": startDate,
        "limit" : limit,
        "offset": offset,
        "searchTerm": searchTerm,
        "ecobonusOnly": ecobonusOnly

      }
    }
    console.log("JSON.stringify(data) ", JSON.stringify(data))
    return this.http.post(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPriceCalcListStore',
     // Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPriceCalcListStore?startDate=' + startDate +'&endDate=' + endDate + '&page=' + page +'&pageSize=' + pageSize ,
      JSON.stringify(data),
      Parameters.getHeaderAppJsonAndToken())
  }

  GetShopDetails(): Observable<any> {
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/DatiAnagrafici/GetDatiNegozio',
      Parameters.getHeaderToken())
  }

  GetCustomerByInspectCode(inspectionCode): Observable<any> {
    // console.log("GetCustomerByInspectCode");
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetCustomerByInspectionCode/' + inspectionCode,
      Parameters.getHeaderToken())
  }

  GetTranslation(root: string): Observable<any> {
    //console.log(" GetTranslation");
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetTranslation/' + root,
    )
  }

  getPdfList(idWebSiteFlowTracking: number, ticket: string): Observable<any> {
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPdfList/' + idWebSiteFlowTracking + '/' + ticket,
      Parameters.getHeaderToken())
  }

  openPdf(idWebSiteFlowTracking: number): void {
    window.open(Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPriceCalcPdf/' + idWebSiteFlowTracking);
  }

  openForDownloadDocument(link: string): void {
    window.open(link);
  }

  downloadDocument(idWebSiteFlowTracking: number, ticket: string, attachment: number, finalFileName: string) {
    const id = `${ticket}_${attachment}`;
    this.downloadingAttachmentSubject.next({ id, loading: true });
    this.http.get(Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPdf/' + idWebSiteFlowTracking + '/' + ticket + '/' + attachment,
      Parameters.getHeaderTokenForBlob())
      .subscribe((blobObject: any) => {
        let anchor = document.createElement('a');
        anchor.download = finalFileName;
        anchor.href = window.URL.createObjectURL(blobObject);
        anchor.dataset.downloadurl = [blobObject.type, anchor.download, anchor.href].join(':');
        anchor.click();
        this.downloadingAttachmentSubject.next({ id, loading: false });
      }, err => {
        this.downloadingAttachmentSubject.next({ id, loading: false });
      });
  }



  getEstimation(data: PriceEstimationCalc): Observable<{ message: string, webSiteFlowTracking: number, estimation: number, item1: any[] }> {
    return this.http.post<{ message: string, webSiteFlowTracking: number, estimation: number, item1: any[] }>(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/estimate',
      JSON.stringify(data),
      Parameters.getHeaderAppJsonAndToken()
    );
  }

  getEstimationFotovol(data: PriceFvEstimationCalc): Observable<{ message: string, webSiteFlowTracking: number, codicekw: string, rataEff: string, rataMens: string, detrIrpef: string, costStimImp:string, prezzoTot:string}> {
    return this.http.post<{ message: string, webSiteFlowTracking: number, codicekw: string, rataEff: string, rataMens: string, detrIrpef: string, costStimImp: string, prezzoTot: string }>(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/estimatefotovol',
      JSON.stringify(data),
      Parameters.getHeaderAppJsonAndToken()
    );
  }

  getAdditionalWork(inspectionCode: string): Observable<[{
    idElement: number,
    description: string,
    shortDescription: string,
    value: number,
    quantity: number,
    editableDescription: boolean,
    editablePrice: boolean,
    editableQuantity: boolean
  }]> {
    return this.http.get<[{
      idElement: number,
      description: string,
      shortDescription: string,
      value: number,
      quantity: number,
      editableDescription: boolean,
      editablePrice: boolean,
      editableQuantity: boolean
    }]>(Parameters.getBaseUrl() + `/api/v1/QuestionResponse/GetAdditionalWork/${inspectionCode}`,
      Parameters.getHeaderAppJsonAndToken()
      );
  }

  generateQuotations(inspectionCode: string, maxQuotations: number): Observable<any> {
    return this.http.patch<any>(
      Parameters.getBaseUrl() + `/api/v1/QuestionResponse/SetInspectionMaxQuotation/${inspectionCode}`,
      { maxQuotations: maxQuotations }, Parameters.getHeaderAppJsonAndToken()
    );
  }

  //to get Availabilities Time Slot for Calendar
  GetAvailabilitiesList(startDate, endDate): Observable<any> {
    //return this.http.get('../../assets/data/calendar.json').pipe(delay(5000));
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetAvailabilities?startDate=' + startDate +'&endDate=' + endDate,
      Parameters.getHeaderToken()
    )
  }

  uploadDocument(formData: FormData): Observable<any> {
    const headers =  Parameters.getHeaderAppJson()
    return this.http.post(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/UploadInstallationDraw',
      formData,
      Parameters.getHeaderToken()
      // { headers, reportProgress: true, observe: 'events'}
    );
  }

  uploadPdfContract(formData: FormData): Observable<any> {
    return this.http.post(
      Parameters.getBaseUrl() + '/api/v1/QuestionResponse/UploadPdfContract',
      formData,
      Parameters.getHeaderToken()
    );
  }

    getNotesList(inspectionCode, page, pageSize, idTicketItemLogType): Observable<any>{
      return this.http.get(
        Parameters.getBaseUrl() + `/api/v1/Ticket/${inspectionCode}`+ '/notes?page=' + page +'&pageSize=' + pageSize + '&idTicketItemLogType='+ idTicketItemLogType,
        Parameters.getHeaderToken()
      )
    }


  saveVendorReference(data): Observable<any> {
    console.log('saveVendorReferenceSet');
    return this.http.post(
      Parameters.getBaseUrl() + '/api/v1/Ticket/SaveVendorReferences',
      JSON.stringify(data),
      Parameters.getHeaderAppJsonAndToken()
    );
  }

  savePickupPoint(data): Observable<any> {
    console.log('savePickupPoint');
    return this.http.post(
      Parameters.getBaseUrl() + '/api/v1/Pickup/SavePickupPoint',
      JSON.stringify(data),
      Parameters.getHeaderAppJsonAndToken()
    );
  }


  GetPickupPointList(): Observable<any> {
    console.log('GetPickupPointList');
    return this.http.get(
      Parameters.getBaseUrl() + '/api/v1/Pickup/list' , Parameters.getHeaderToken()
    );
  }


  SetPaymentConfirmation(idWebSiteFlowTracking, idAttachment): Observable<any> {
    console.log('PostPaymentConfirmation', idWebSiteFlowTracking);
    return this.http.post(
      Parameters.getBaseUrl() + `/api/v1/Ticket/ConfirmSale`,
      JSON.stringify({ IDWebSiteFlowTracking: idWebSiteFlowTracking, IDAttachment: idAttachment }),
      Parameters.getHeaderAppJsonAndToken()
    );
  }
}
