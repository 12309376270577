//import { NgModule } from '@angular/core';
//import { Routes, RouterModule } from '@angular/router';

// const routes: Routes = [];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })

//export class AppRoutingModule { }

import { ROUTES, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { ItalyComponent } from "./italy/italy.component";
import { TipoImpiantoComponent } from "./tipo-impianto/tipo-impianto.component";
import { CondizionatoreInstallatoComponent } from "./condizionatore-installato/condizionatore-installato.component";
import { PredisposizioneComponent } from "./predisposizione/predisposizione.component";
import { DistanzaComponent } from "./distanza/distanza.component";
import { ShukoComponent } from "./shuko/shuko.component";
import { StaffaComponent } from "./staffa/staffa.component";
import { ProfonditaPareteComponent } from "./profondita-parete/profondita-parete.component";
import { PreventivoComponent } from "./preventivo/preventivo.component";
import { SmontaggioComponent } from "./smontaggio/smontaggio.component";
import { LavaggioComponent } from "./lavaggio/lavaggio.component";
import { DatiClienteComponent } from "./dati-cliente/dati-cliente.component";
import { StesseStaffeComponent } from "./stesse-staffe/stesse-staffe.component";
import { PredisposizioneCondizionatoreComponent } from "./predisposizione-condizionatore/predisposizione-condizionatore.component";
import { DistanzaSplitComponent } from "./distanza-split/distanza-split.component";
import { RicercaComponent } from "./ricerca/ricerca.component";
import { MieiDatiComponent } from "./miei-dati/miei-dati.component";
import { SopralluogoComponent } from "./sopralluogo/sopralluogo.component";
import { OtherServicesComponent } from "./other-services/other-services.component";
import { SplitSelectionComponent } from "./split-selection/split-selection.component";
import { DatiPreventivoComponent } from "./dati-preventivo/dati-preventivo.component";
import { DatiInstallazioneComponent } from "./dati-installazione/dati-installazione.component";
import { ProdottiComponent } from "./prodotti/prodotti.component";
import { SelectPackageComponent } from "./select-package/select-package.component";
import { ServizioComponent } from "./servizio/servizio.component";
import { GatewayComponent } from "./gateway/gateway.component";
import { CalendarioComponent } from "./calendario/calendario.component";
import { UploadInstallationDrawComponent } from "./upload-installation-draw/upload-installation-draw.component";
import { TokenCheckGuard } from "./token-check.guard";
import { ItalyNorthSplitComponent } from "./italy-north-split/italy-north-split.component";
import { environment } from "src/environments/environment";
import {SealedPlantComponent} from './sealed-plant/sealed-plant.component';
import { DocumentiComponent } from './documenti/documenti.component';
import { ConsulenzaEnergeticaPvComponent } from './consulenza-energetica-pv/consulenza-energetica-pv.component';
import { AutorizzazioneInstallazioneConsenergComponent } from './autorizzazione-installazione-consenerg/autorizzazione-installazione-consenerg.component';
import { CoperturaPareteComponent } from './copertura-parete/copertura-parete.component';
import { ZonaAbitazioneComponent } from './zona-abitazione/zona-abitazione.component';
import { DimensionitettoComponent } from './dimensionitetto/dimensionitetto.component';
import { StrutturaTettoComponent } from './struttura-tetto/struttura-tetto.component';
import { InserimentoContatorekwComponent } from './inserimento-contatorekw/inserimento-contatorekw.component';
import { FasciaOrariaConsumoComponent } from './fascia-oraria-consumo/fascia-oraria-consumo.component';
import { PiastraInduzioneComponent } from './piastra-induzione/piastra-induzione.component';
import { PompaCaloreComponent } from './pompa-calore/pompa-calore.component';
import { ClimatizzatoriComponent } from './climatizzatori/climatizzatori.component'
import { AutoElettricaComponent } from './auto-elettrica/auto-elettrica.component';
import { PreventivoFotovoltaicoComponent } from './preventivo-fotovoltaico/preventivo-fotovoltaico.component';
import { KoPreventivoFvComponent } from './ko-preventivo-fv/ko-preventivo-fv.component';
import { DatiClientefvComponent } from './dati-clientefv/dati-clientefv.component';
import { KoDefaultComponent } from './ko-default/ko-default.component';
import {ModalLoginComponent} from "./modal-login/modal-login.component";
import {LoginComponent} from "./login/login.component";
export const MODULE_ROUTES: Routes = [
  { path: "",
    redirectTo: "gateway",
    // redirectTo: localStorage.getItem("userToken") ?  "selezionaPratica" : "login",
    pathMatch: "full"
  },
  {
    path: "gateway",
    component: GatewayComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "selezionaPratica",
    component: SelectPackageComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "servizio",
    component: ServizioComponent,
    data: { page: "servizio" },
    canActivate: [TokenCheckGuard],
  },
  {
    path: "servizioEcobonus",
    component: ServizioComponent,
    data: { page: "servizioEcobonus" },
    canActivate: [TokenCheckGuard],
  },
  {
    path: "altriServizi",
    component: OtherServicesComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "selezioneSplit",
    component: SplitSelectionComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "tipoImpianto",
    component: TipoImpiantoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "condizionatoreInstallato",
    component: CondizionatoreInstallatoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "predisposizione",
    component: PredisposizioneComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "distanza",
    component: DistanzaComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "presaElettrica",
    component: ShukoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "staffa",
    component: StaffaComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "profonditaParete",
    component: ProfonditaPareteComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "preventivo",
    component: PreventivoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "smontaggio",
    component: SmontaggioComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "lavaggio",
    component: LavaggioComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "datiCliente",
    component: DatiClienteComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "datiSopralluogo",
    component: SopralluogoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "staffaEsistente",
    component: StesseStaffeComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "datiPreventivo",
    component: DatiPreventivoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "disegno",
    component: UploadInstallationDrawComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "predisposizioneCondizionatore",
    component: PredisposizioneCondizionatoreComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "distanzaSplit",
    component: DistanzaComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "ricerca",
    component: RicercaComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "documenti",
    component: DocumentiComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "datiNegozio",
    component: MieiDatiComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "datiInstallazione",
    component: DatiInstallazioneComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "prodotti",
    component: ProdottiComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "calendario",
    component: CalendarioComponent,
    canActivate: [TokenCheckGuard],
  },
  {
  path: "calendariofv",
    component: CalendarioComponent,
      canActivate: [TokenCheckGuard],
        data: {
    queryParam: {
      overrideNextPageName: 'DatiClientefv'
    }
  }
},

  {
    path: "zonaGeografica",
    component: ItalyComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "impiantoSigillato",
    component: SealedPlantComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'ConsulenzaEnergeticaPv',
    component: ConsulenzaEnergeticaPvComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'AutorizzazioneInstallazioneConsenerg',
    component: AutorizzazioneInstallazioneConsenergComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'CoperturaParete',
    component: CoperturaPareteComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'ZonaAbitazione',
    component: ZonaAbitazioneComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'Dimensionitetto',
    component: DimensionitettoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'StrutturaTetto',
    component: StrutturaTettoComponent,
    canActivate: [TokenCheckGuard],
  },
   {
     path: 'InserimentoContatorekw',
     component: InserimentoContatorekwComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'FasciaOrariaConsumo',
    component: FasciaOrariaConsumoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'PiastraInduzione',
      component: PiastraInduzioneComponent,
    canActivate: [TokenCheckGuard],
  },
   {
     path: 'PompaCalore',
     component: PompaCaloreComponent,
    canActivate: [TokenCheckGuard],
  },

   {
     path: 'Climatizzatori',
     component: ClimatizzatoriComponent,
     canActivate: [TokenCheckGuard],
  },

  {
    path: 'AutoElettrica',
    component: AutoElettricaComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'PreventivoFotovoltaico',
    component: PreventivoFotovoltaicoComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: 'KoPreventivoFv',
    component: KoPreventivoFvComponent,
    canActivate: [TokenCheckGuard],
  },
  {
    path: "DatiClientefv",
    component: DatiClientefvComponent,
    canActivate: [TokenCheckGuard],
  },

  {
    path: "KoDefault",
    component: KoDefaultComponent,
    canActivate: [TokenCheckGuard],
  },

];

export const MODULE_COMPONENTS = [
  AppComponent,
  ItalyComponent,
  ItalyNorthSplitComponent,
  TipoImpiantoComponent,
  CondizionatoreInstallatoComponent,
  PredisposizioneComponent,
  DistanzaComponent,
  ShukoComponent,
  StaffaComponent,
  ProfonditaPareteComponent,
  PreventivoComponent,
  SmontaggioComponent,
  LavaggioComponent,
  DatiClienteComponent,
  StesseStaffeComponent,
  PredisposizioneCondizionatoreComponent,
  DistanzaSplitComponent,
  DatiInstallazioneComponent,
  ProdottiComponent,
];
