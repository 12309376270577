import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { FormBuilder } from '@angular/forms';
import { UcChoice } from '../_models/uc-choice';
import { Router } from '@angular/router';
import { PreventivoService } from '../preventivo.service';
import { ClimaSmartService } from '../_service/clima-smart.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-ko-default',
  templateUrl: './ko-default.component.html',
  styleUrls: ['./ko-default.component.css']
})
export class KoDefaultComponent implements OnInit {

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private location: Location,
  ) {


  }

  ngOnInit() {
  }

  goBack() {
    this.preventivoService.setConsulenzaEnerg('');
    this.preventivoService.setPackage('');
    this.router.navigate(["/selezionaPratica"]);
  }


}
