import { Component, OnInit, VERSION } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-tipo-impianto",
  templateUrl: "./tipo-impianto.component.html",
  styleUrls: ["./tipo-impianto.component.css"]
})
export class TipoImpiantoComponent implements OnInit {
  //name: string;
  selectedPlan: string = "";
  selectedPlanMnemonic: string = "";
  public albums: Array<IAlbum>;
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  //added
  selectedZone: string = "";

  public QuestionResponseList: Array<any>;
  public ResponseLabelMono: string = "";
  public ResponseLabelDouble: string = "";
  public ResponseLabelTriple: string = "";
  public ResponseIdMono: string = "";
  public ResponseIdDouble: string = "";
  public ResponseIdTriple: string = "";
  public Question: string = "";
  public ResponseRootMono: string = "";
  public ResponseRootDouble: string = "";
  public ResponseRootTriple: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _lightbox2: Lightbox,
    private _lightbox3: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.albums = [];

    var src = "../assets/images/3DMONO-L.jpg";
    var caption = "";
    var thumb = "../assets/images/3DMONO-S.jpg";
    var album1 = {
      src: src,
      caption: "Impianto Mono",
      thumb: thumb
    };

    this.albums.push(album1);

    src = "../assets/images/3DDUAL-L.jpg";
    caption = "";
    thumb = "../assets/images/3DDUAL-S.jpg";
    var album2 = {
      src: src,
      caption: "Impianto Dual",
      thumb: thumb
    };

    this.albums.push(album2);

    src = "../assets/images/3DTRIAL-L.jpg";
    caption = "";
    thumb = "../assets/images/3DTRIAL-S.jpg";
    var album3 = {
      src: src,
      caption: "Impianto Trial",
      thumb: thumb
    };

    this.albums.push(album3);
  }

  openFirstLightBox(index: number): void {
    // open lightbox 1
    this._lightbox.open(this.albums, index);
  }

  openSecondLightBox(index: number): void {
    // open lightbox 2
    this._lightbox.open(this.albums, index);
  }

  openThirdLightBox(index: number): void {
    // open lightbox 3
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  goBack() {
    this.preventivoService.setTipoImpianto('');
    this.preventivoService.setTipoImpiantoMnemonic('');
    this._location.back();
    // this.router.navigate(['/servizio']);
  }

  isChecked(selected: any) {

   // console.log("isChecked value", selected)

    if (selected == this.preventivoService.getTipoImpianto()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  //event andler
  radioChangeHandler(event: any, mnemonic : string) {
    this.selectedPlan = event.target.value;
    this.selectedPlanMnemonic = mnemonic;

    console.log(event.target.value)
    console.log(mnemonic)
  }

  ngOnInit() {

   // console.log("inside ngOnInIt")

  //  console.log("getTipoImpianto", this.preventivoService.getTipoImpianto())
    if (this.preventivoService.getTipoImpianto()) {
      this.selectedPlan = this.preventivoService.getTipoImpianto();
    }

    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
  //  console.log("inside tipo impianto ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
     // console.log("inside try");
      this.climaSmartService
        .GetQuestionResponseList("tipoImpianto")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelMono = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelDouble = this.QuestionResponseList[1].responseLabel),
            (this.ResponseLabelTriple = this.QuestionResponseList[2].responseLabel),
            (this.ResponseIdMono = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdDouble = this.QuestionResponseList[1].idWebSiteFlow),
            (this.ResponseIdTriple = this.QuestionResponseList[2].idWebSiteFlow),
            (this.ResponseRootMono = this.QuestionResponseList[0].responseRoot),
            (this.ResponseRootDouble = this.QuestionResponseList[1].responseRoot),
            (this.ResponseRootTriple = this.QuestionResponseList[2].responseRoot),
          //  console.log("xxxx", this.QuestionResponseList);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;

        }
        , err => {

          console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
     // console.log("inside catch");
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  // ngAfterViewChecked() {

  //   console.log("ngAfterView")

  //   $('.tile')
  //   // tile mouse actions
  //   .on('mouseover', function(){
  //     $(this).children('.photo').css({'transform': 'scale('+ $(this).attr('data-scale') +')'});
  //   })
  //   .on('mouseout', function(){
  //     $(this).children('.photo').css({'transform': 'scale(1)'});
  //   })
  //   .on('mousemove', function(e){
  //     $(this).children('.photo').css({'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +'%'});
  //   })
  //   // tiles set up
  //   .each(function(){
  //     $(this)
  //       // add a photo container
  //       .append('<div class="photo"></div>')

  //       // set up a background image for each tile based on data-image attribute
  //       .children('.photo').css({'background-image': 'url('+ $(this).attr('data-image') +')'});
  //   })

  // }

  setTipoImpianto() {
    this.preventivoService.setTipoImpianto(this.selectedPlan);
    this.preventivoService.setTipoImpiantoMnemonic(this.selectedPlanMnemonic);

    //this.router.navigate(["/condizionatore-installato"]);
    this.router.navigate([this.QuestionResponseList[0].pageNameNext]);
  }
}
