import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-distanza-split",
  templateUrl: "./distanza-split.component.html",
  styleUrls: ["./distanza-split.component.css"]
})
export class DistanzaSplitComponent implements OnInit {
  inputDistanzaSplit: string = "";
  selectedPlan: string = "";
  public albums: Array<IAlbum>;
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelDistance: string = "";
  public Question: string = "";
  public ResponseIdDistance: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.selectedPlan = this.preventivoService.getTipoImpiantoMnemonic();

    this.albums = [];

    if (this.selectedPlan == preventivoService.getMONO()) {

      var src = "../assets/images/distanza-mono-split-L.jpg";
      var caption = "";
      var thumb = "../../assets/distanza-mono-split-S.jpg";
      var album = {
        src: src,
        caption:
          "DISTANZA TRA L'UNITÀ INTERNA ESISTENTE E LA POSIZIONE IN CUI DOVRÀ ESSERE INSTALLATO LA NUOVA UNITÀ INTERNA - Impianto Mono ",
        thumb: thumb
      };

      this.albums.push(album);
    }

    if (this.selectedPlan == preventivoService.getDUAL()) {
      //console.log("inside 2");

      src = "../assets/images/distanza-dual-split-L.jpg";
      caption = "";
      thumb = "../assets/images/distanza-dual-split-S.jpg";
      album = {
        src: src,
        caption:
          "DISTANZA TRA L'UNITÀ INTERNA ESISTENTE E LA POSIZIONE IN CUI DOVRÀ ESSERE INSTALLATO LA NUOVA UNITÀ INTERNA - Impianto Dual",
        thumb: thumb
      };

      this.albums.push(album);
    }

    if (this.selectedPlan == preventivoService.getTRIAL()) {
      src = "../assets/images/distanza-trial-split-L.jpg";
      caption = "";
      thumb = "../assets/images/distanza-trial-split-S.jpg";
      album = {
        src: src,
        caption:
          "DISTANZA TRA L'UNITÀ INTERNA ESISTENTE E LA POSIZIONE IN CUI DOVRÀ ESSERE INSTALLATO LA NUOVA UNITÀ INTERNA - Impianto Trial",
        thumb: thumb
      };

      this.albums.push(album);
    }
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  ngOnInit() {
   // console.log("getDistanzaPredispozisioneCondizionatorE", this.preventivoService.getDistanzaPredispozisioneCondizionatore())
    if (this.preventivoService.getDistanzaPredispozisioneCondizionatore()) {
      this.inputDistanzaSplit = this.preventivoService.getDistanzaPredispozisioneCondizionatore().toString();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
   // console.log("inside distnace ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("distanzaSplit")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelDistance = this.QuestionResponseList[0].responseLabel),
            (this.ResponseIdDistance = this.QuestionResponseList[0].idWebSiteFlow),
           // console.log("xxxx", this.QuestionResponseList);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
        , err => {

         // console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
      //console.log("inside catch");
      //console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  //  onKey(event: any) {
  //      this.inputDistanza +=  event.target.inputDistanza;

  //     console.log("Distance : ", parseInt(this.inputDistanza))
  //    }

  setDistanzaSplit() {
    //console.log("setDistanzaSplit");
    this.preventivoService.setDistanzaPredispozisioneCondizionatore(
      parseInt(this.inputDistanzaSplit)
    );
    this.preventivoService.setIdDistanzaPredispozisioneCondizionatore(this.ResponseIdDistance);
    //console.log("Distance : ", parseInt(this.inputDistanza))
    // this.router.navigate(["/smontaggio"]);
    this.router.navigate([this.QuestionResponseList[0].pageNameNext]);
  }

  onBlurMethod() {
    //console.log("Distance : ", this.inputDistanzaSplit);
  }

  goBack() {
  //  console.log("inside go back button");
    this._location.back();
  }
}
