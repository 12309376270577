import { Component, OnInit } from '@angular/core';
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { Location } from "@angular/common";
import { InstallationData } from "../_models/models";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";

@Component({
  selector: 'app-dati-installazione',
  templateUrl: './dati-installazione.component.html',
  styleUrls: ['./dati-installazione.component.css']
})
export class DatiInstallazioneComponent implements OnInit {

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideMessage: boolean = true;
  btuSelected: string = '';
  heightSelected: string = '';

  public heightAbove200: string = null;
  public QuestionResponseList: Array<any>;
  public Question: string = '';
  public ResponseId: string = '';

  heightDescription: string = '';

  public InstallationForm: FormGroup;
  public installation: InstallationData = new InstallationData();

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private _location: Location,
  ) { }

  ngOnInit() {

    if (this.preventivoService.getBTU() || this.preventivoService.getInstallationHeight()) {
      this.installation.btu = this.preventivoService.getBTU();
      this.installation.height = this.preventivoService.getInstallationHeight();
      if (this.installation.height == 'GT600') {
        this.hideMessage = false
      }
      else {
        this.hideMessage = true
      }
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("datiInstallazione")
        .subscribe(data => {
            (this.QuestionResponseList = data),
              (this.Question = this.QuestionResponseList[0].questionLabel),
              (this.ResponseId = this.QuestionResponseList[0].idWebSiteFlow),
              this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });

      this.climaSmartService.GetTranslation('HEIGHT_ABOVE_200')
        .subscribe(data => {
          this.heightDescription = data.translation;
        }, err => {
          if (err.status === 404) {
            this.heightDescription = null;
          }
        });
    } catch {
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }


    this.InstallationForm = this._fb.group({
      DataInstallationFormGroup: this._fb.group({
        btu: ["", [Validators.required]],
        height: ["", [Validators.required]],
      })
    });
  }


  selectBTU(event: any) {
    this.btuSelected = event.target.value;
  }

  selectHeignt(event: any) {
    this.installation.height = event.target.value;
    if (this.installation.height == 'GT600') {
      this.hideMessage = false
    }
    else {
      this.hideMessage = true
    }
  }

  isFormValid() {

    console.log('btu', this.installation.btu)
    console.log('pred', this.preventivoService.getPredispozisione())

    if (this.installation.btu && this.installation.height) {
      return true
    }
    return false
  }

  // setInstallation() {
  //
  //   this.preventivoService.setBTU(this.installation.btu);
  //   this.preventivoService.setInstallationHeight(this.installation.height);
  //   this.preventivoService.setIdInstallation(this.ResponseId)
  //
  //   if (this.preventivoService.isSonOfDigitalInspection()) {
  //
  //     this.router.navigate(['/disegno']);
  //     return;
  //
  //   }
  //
  //   // TODO: move to configuration
  //   const answers = [
  //     { answer: "priceCalculator", route: "/datiPreventivo" },
  //     { answer: "priceCalculatorWithNumber", route: "/datiSopralluogo" },
  //     { answer: "priceCalculatorWithEcobonus", route: "/datiPreventivo" }
  //   ];
  //
  //   const nextRoute = answers.find((a) => a.answer == this.preventivoService.getPackageType()
  //     || a.answer == this.preventivoService.getServiceType());
  //   if (!nextRoute) {
  //     return;
  //   }
  //
  //   this.router.navigate([nextRoute.route]);
  // }

  setBtu() {
    this.preventivoService.setBTU(this.installation.btu);
    this.preventivoService.setInstallationHeight(this.installation.height);
    this.preventivoService.setIdInstallation(this.ResponseId)

    if (this.preventivoService.isSonOfDigitalInspection()) {
      this.router.navigate(['/disegno']);
      return;
    }

    // TODO: move to configuration
    const answers = [
      { answer: "priceCalculator", route: "/datiPreventivo" },
      { answer: "priceCalculatorWithNumber", route: "/datiSopralluogo" },
      { answer: "priceCalculatorWithEcobonus", route: "/datiPreventivo" }
    ];

    const nextRoute = answers.find((a) => a.answer == this.preventivoService.getPackageType()
      || a.answer == this.preventivoService.getServiceType());
    if (!nextRoute) {
      return;
    }
    // rotta calcolo preventivo
    // this.router.navigate([nextRoute.route]);
    this.router.navigate([this.QuestionResponseList[0].pageNameNext]);
  }

  goBack() {
    this.preventivoService.setBTU('');
    this.preventivoService.setInstallationHeight('');
    this.preventivoService.setIdInstallation('');
    this._location.back();
  }

}
