import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClientData, PriceCalc, ResponsePCalc, SopralluogoData, CustomerData, PriceEstimationCalc, PriceFvEstimationCalc, PriceFvCalc } from "../_models/models";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { Parameters } from "../_service/parameters";
import { ShopDetails } from "../_models/models";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Console } from "@angular/core/src/console";
import { of } from "rxjs";
import { delay } from "rxjs/operators";

const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
const capRegex = /^\d{5}$/;
const cfRegex = /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$|([0-9]{11})$/;


@Component({
  selector: 'app-dati-clientefv',
  templateUrl: './dati-clientefv.component.html',
  styleUrls: ['./dati-clientefv.component.css']
})
export class DatiClientefvComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;
  inputNome: string = "";
  inputCognome: string = "";
  inputTelefono: string = "";
  inputMobile: string = "";
  inputEmail: string = "";
  inputIndirizzo: string = "";
  inputCap: string = "";
  inputCitta: string = "";
  inputProvincia: string = "";
  inputLogin: string = "";
  inputCF: string = "";

  loading: boolean = false;
  hideLoader: boolean = true;
  hideButtons: boolean = false;

  quotationValue: number = null;
  quotationInProgress: boolean = true;
  webSiteFlowTracking: number;
  isQuotation: boolean;

  //shopName: string = "";
  public shopDetails: ShopDetails = new ShopDetails();

  public DataClientForm: FormGroup;
  public client: ClientData = new ClientData();
  public customer: CustomerData = new CustomerData();

 // public priceCalc: PriceCalc = new PriceCalc();
  public priceCalcFv: PriceFvCalc = new PriceFvCalc();

  public provinceList: Array<any>;
  public shopList: Array<any>;
  public shopDetailsObject: Object;

  public json(): string { return JSON.stringify(this.client); }

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  ngOnInit() {
    if (this.preventivoService.getNomeCliente() || this.preventivoService.getCognomeCliente() ||
      this.preventivoService.getTelefonoCliente() || this.preventivoService.getMobileCliente() ||
      this.preventivoService.getEmailCliente() || this.preventivoService.getCodiceFiscaleCliente() ||
      this.preventivoService.getIndirizzoCliente() ||
      this.preventivoService.getCittaCliente() || this.preventivoService.getProvinciaCliente() ||
      this.preventivoService.getCapCliente() || this.preventivoService.getLoginCliente()) {
      this.client.firstName = this.preventivoService.getNomeCliente();
      this.client.lastName = this.preventivoService.getCognomeCliente();
      this.client.phone = this.preventivoService.getTelefonoCliente();
      this.client.mobile = this.preventivoService.getMobileCliente();
      this.client.mail = this.preventivoService.getEmailCliente();
      this.client.taxCode = this.preventivoService.getCodiceFiscaleCliente();
      this.client.address = this.preventivoService.getIndirizzoCliente();
      this.client.city = this.preventivoService.getCittaCliente();
      this.client.idProvince = +this.preventivoService.getProvinciaCliente();
      this.client.zipCode = this.preventivoService.getCapCliente();
      this.client.idLogin = +(this.preventivoService.getLoginCliente().toString());
      this.client.notes = this.preventivoService.getCustomerNotes();
      
     
    }

    this.DataClientForm = this._fb.group({
      DataClientFormGroup: this._fb.group({
        //serviceNumber: ["", []],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        phone: ["", []],
        mobile: ["", []],
        mail: ["", [Validators.required, Validators.pattern(mailRegex)]],
        address: ["", [Validators.required]],
        notes: ["", []],
        city: ["", [Validators.required]],
        province: ["", [Validators.required]],
        zipCode: ["", [Validators.required, Validators.pattern(capRegex)]],
        login: ["", [Validators.required]],
        taxCode: ["", [Validators.required, Validators.pattern(cfRegex)]]
      })
    });

    try {

      console.log('Checking for quotation:' + this.preventivoService.getServiceType());

      this.climaSmartService.GetProvince()
        .subscribe(
          data => {
            //console.debug("climaSmartService.GetProvince" + data);
            this.provinceList = data
            // console.log("province Lists xxxxxxxxxxxxxxxxxxxx" + this.provinceList);

          }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
            }
          }
        )
      this.climaSmartService.GetShopList()
        .subscribe(
          data => {
            this.shopList = data
          }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
            }
          }
        )
      if (localStorage.getItem("userToken") !== null) {

        this.climaSmartService.GetShopDetails()
          .subscribe(
            data => {
              this.shopDetails = data
            }
            , err => {

              console.error("ngOnInit Error ", err);
              if (err.status === 401) {
                this.climaSmartService.logoutAndRedirect();
              }
            }
          );


      }
    }

    catch (ex) {
      console.exception("ngOnInit Exception " + ex)
    }
  }


  goToPriceTable() {
    let arrResponse: Array<ResponsePCalc> = this.getResponseArray();


    let appointmentDate = this.preventivoService.getAppointmentDateFv();
    let servicetype = this.preventivoService.getSrvcTypefv();
    this.client.phone = this.client.phone ? this.client.phone : null;
    this.client.mobile = this.client.mobile ? this.client.mobile : null;

    this.preventivoService.setNomeCliente(this.client.firstName);
    this.preventivoService.setCognomeCliente(this.client.lastName);
    this.preventivoService.setTelefonoCliente(this.client.phone);
    this.preventivoService.setMobileCliente(this.client.mobile);
    this.preventivoService.setEmailCliente(this.client.mail);
    this.preventivoService.setCodiceFiscaleCliente(this.client.taxCode);
    this.preventivoService.setIndirizzoCliente(this.client.address);
    this.preventivoService.setCittaCliente(this.client.city);
    this.preventivoService.setProviniciaCliente(this.client.idProvince);
    this.preventivoService.setCapCliente(this.client.zipCode.toString());
    this.preventivoService.setLoginCliente(+(this.client.idLogin.toString()));


    //let data: PriceCalc = {
    //  responseList: arrResponse, firstName: this.client.firstName,
    //  lastName: this.client.lastName, mobile: this.client.mobile, phone: this.client.phone,
    //  mail: this.client.mail, taxCode: this.client.taxCode, address: this.client.address, zipCode: this.client.zipCode.toString(),
    //  city: this.client.city, idProvince: this.client.idProvince, idLogin: +(this.client.idLogin.toString()),
    //  notes: this.client.notes,
    //  inspectionCode: this.preventivoService.getInspectionCode(),
    //  extraAccessoriesDescription: this.preventivoService.getOpereExtra(),
    //  extraAccessoriesValue: this.preventivoService.getOpereExtraPrice(),
    //  discountPercentage: this.preventivoService.getDiscountPercent(),
    //  idWebSiteFlowTracking: this.preventivoService.getEstimationId(),
    //};

    //Creazione della nuova classe esterna che gestisce il calcolo per FV(FOTOVOLTAICO);
    //DA VERIFICARE..
    let datafvIn: PriceFvCalc = {
      responseList: arrResponse, firstName: this.client.firstName,
      lastName: this.client.lastName, mobile: this.client.mobile, phone: this.client.phone,
      mail: this.client.mail, taxCode: this.client.taxCode, address: this.client.address,
      zipCode: this.client.zipCode.toString(), city: this.client.city, idProvince: this.client.idProvince,
      idLogin: +(this.client.idLogin.toString()), notes: this.client.notes,
      idWebSiteFlowTracking: this.preventivoService.getIdWebSiteTrackingFlow(),
      appointmentDate: appointmentDate === "" ? null : appointmentDate,
      servicetype: servicetype === "" ? null : servicetype
      
    };
 
    console.log("SERVICE TYPE", this.preventivoService.getSrvcTypefv())
    try {
      this.hideLoader = false;
      this.loading = true;
      this.hideButtons = true

      if (arrResponse.length > 0) {
        this.climaSmartService
          .QuestionResponsesFv(datafvIn)
          .subscribe(data => {
            if (data) {
              if (data.isAdded) {
                this.hideLoader = true;
                this.loading = false;
                this.preventivoService.setIdWebSiteTrackingFlow(datafvIn.idWebSiteFlowTracking);
              
                
                this.router.navigate(["/preventivo"]);
              }
              else {
                this.modalError.show({ errorTitle: "Errore", errorDetail: data.message });
                this.hideLoader = true;
                this.loading = false;
                this.hideButtons = false
              }
            }
          }
            , err => {
              if (err.status === 401) {
                this.climaSmartService.logoutAndRedirect();
                this.hideLoader = true;
                this.loading = false;
              }

              else {
                this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
                this.hideLoader = true;
                this.loading = false;
                this.hideButtons = false
                this.preventivoService.setEstimationFvId(null);

              }
            }

          );
      }

      else {
        this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
        this.hideLoader = true;
        this.loading = false;
        this.hideButtons = false

      }

    }
    catch {
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hideLoader = false;

    }
  }

  private getResponseArray() {
    let arrResponse: Array<ResponsePCalc> = [];

    if (this.preventivoService.getFasciaOraria() != '' &&
      this.preventivoService.getFasciaOraria() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getFasciaOraria(),
        responseValue: null, //this.preventivoService.getFasciaOrariaMnemonic(),
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getPompaCalore() != '' &&
      this.preventivoService.getPompaCalore() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getPompaCalore(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getPiastraInduzione() != '' &&
      this.preventivoService.getPiastraInduzione() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getPiastraInduzione(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getAutoElettrica() != '' &&
      this.preventivoService.getAutoElettrica() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getAutoElettrica(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }


    return arrResponse;
  }

  goBack() {
    this._location.back();
  }

  isFormValid() {

    if (this.client.phone || this.client.mobile) {
      return this.DataClientForm.valid && true && (!this.isQuotation || this.quotationValue);
    }
    return this.DataClientForm.valid && false;
  }

  isMobilePhoneValid() {

    if (this.client.phone || this.client.mobile) {
      return true;
    }
    return false;
  }

  isShopHidden(): boolean {
    if (localStorage.getItem("userToken")) {
      return false;
    } else {
      return true;
    }
  }

  private handleStandardError(e: any) {
    if (e.status === 401) {
      this.climaSmartService.logoutAndRedirect();
      this.hideLoader = true;
      this.loading = false;
    }
    else {
      this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
      this.hideLoader = true;
      this.loading = false;
      this.hideButtons = false;
    }
  }
}

