import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClientData, PriceCalc, ResponsePCalc, SopralluogoData, CustomerData, PriceEstimationCalc, Contatorekwdata, KoPrevFv, PriceFvCalc } from "../_models/models";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { Parameters } from "../_service/parameters";
import { ShopDetails } from "../_models/models";
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";

@Component({
  selector: 'app-ko-preventivo-fv',
  templateUrl: './ko-preventivo-fv.component.html',
  styleUrls: ['./ko-preventivo-fv.component.css']
})
export class KoPreventivoFvComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  inputMotivoko: string = "";

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideButtons: boolean = false;

  question: string = '';
  data: any[] = [];
  inspectionCodes: any;
  currentDataRecord: any;
  previous: string | null = null;
  public client: ClientData = new ClientData();

  public KoForm: FormGroup;
  public motivoKo: KoPrevFv = new KoPrevFv();
  public priceCalcFv: PriceFvCalc = new PriceFvCalc();
  public QuestionResponseList: Array<any>;
  public Question: string = '';
  public ResponseId: string = '';
  public Defaultnotes: string = '';
  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  ngOnInit() {



    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("KoPreventivoFv")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseId = this.QuestionResponseList[0].idWebSiteFlow),


            this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });
    } catch {
      console.exception("input error ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }

    this.KoForm = this._fb.group({
        datiko: ["", [Validators.required]]
    });
  }
  isFormValid() {
    if (this.motivoKo.motivazione) {
      if (this.motivoKo.motivazione === 'Altro' && !this.motivoKo.motivazioneCustom) {
        return false
      } 
    }
    return true
  }

  showTextArea: boolean = false;
  selectMotivation(event: any) {
    this.motivoKo.motivazione = event.target.value;

  }

  private getResponseArray() {
    let arrResponse: Array<ResponsePCalc> = [];

    if (this.preventivoService.getFasciaOraria() != '' &&
      this.preventivoService.getFasciaOraria() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getFasciaOraria(),
        responseValue: null, //this.preventivoService.getFasciaOrariaMnemonic(),
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getPompaCalore() != '' &&
      this.preventivoService.getPompaCalore() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getPompaCalore(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getPiastraInduzione() != '' &&
      this.preventivoService.getPiastraInduzione() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getPiastraInduzione(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getAutoElettrica() != '' &&
      this.preventivoService.getAutoElettrica() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getAutoElettrica(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getIdKoPrevFv() != '' &&
      this.preventivoService.getIdKoPrevFv() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getIdKoPrevFv(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    return arrResponse;
  }


  SendMotivation() {
    this.preventivoService.setIdKoPrevFv(this.ResponseId);
    this.client.firstName = this.preventivoService.getNomeCliente();
    this.client.lastName = this.preventivoService.getCognomeCliente();
    this.client.phone = this.preventivoService.getTelefonoCliente();
    this.client.mobile = this.preventivoService.getMobileCliente();
    this.client.mail = this.preventivoService.getEmailCliente();
    this.client.taxCode = this.preventivoService.getCodiceFiscaleCliente();
    this.client.address = this.preventivoService.getIndirizzoCliente();
    this.client.city = this.preventivoService.getCittaCliente();
    this.client.idProvince = this.preventivoService.getProvinciaCliente();
    this.client.zipCode = this.preventivoService.getCapCliente().toString();
    this.client.idLogin = this.preventivoService.getLoginCliente();
    this.Defaultnotes = this.motivoKo.motivazione;
    this.preventivoService.setCustomerNotes(this.Defaultnotes);
    if (this.Defaultnotes === 'Altro' && this.motivoKo.motivazioneCustom != "") {
      this.Defaultnotes = this.motivoKo.motivazioneCustom;
      this.preventivoService.setCustomerNotes(this.Defaultnotes);
    }
    let arrResponse: Array<ResponsePCalc> = this.getResponseArray();
    let servicetype = this.preventivoService.getSrvcTypefv();
    let dataInput: PriceFvCalc = {
      responseList: arrResponse, firstName: this.client.firstName,
      lastName: this.client.lastName, mobile: this.client.mobile, phone: this.client.phone,
      mail: this.client.mail, taxCode: this.client.taxCode, address: this.client.address,
      zipCode: this.client.zipCode.toString(), city: this.client.city, idProvince: this.client.idProvince,
      idLogin: +(this.client.idLogin.toString()), notes: this.preventivoService.getCustomerNotes(),
      idWebSiteFlowTracking: this.preventivoService.getEstimationFvId(),
      servicetype: servicetype === "" ? "SOLAR INSPECTION" : servicetype

    };

    try {
      this.hideLoader = false;
      this.loading = true;
      this.hideButtons = true

      if (arrResponse.length > 0) {
        this.climaSmartService
          .QuestionResponsesFv(dataInput)
          .subscribe(data => {
            if (data) {
              if (data.isAdded) {
                this.hideLoader = true;
                this.loading = false;
                this.preventivoService.setIdWebSiteTrackingFlow(data.idWebSiteFlowTracking);

                //this.preventivoService.setPackage('');
                //this.preventivoService.setDimensioniTettoSelected('');
                //this.preventivoService.setAutoElettrica('');
                this.preventivoService.clear();
                this.router.navigate(["/selezionaPratica"]);

              }
              else {
                this.modalError.show({ errorTitle: "Errore", errorDetail: data.message });
                this.hideLoader = true;
                this.loading = false;
                this.hideButtons = false
              }
            }
          }
            , err => {
              if (err.status === 401) {
                this.climaSmartService.logoutAndRedirect();
                this.hideLoader = true;
                this.loading = false;
              }

              else {
                this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
                this.hideLoader = true;
                this.loading = false;
                this.hideButtons = false
                this.preventivoService.setEstimationFvId(null);

              }
            }

          );

        console.log("DATI DATA RESPONSES:", dataInput.idWebSiteFlowTracking);


      }

      else {
        this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
        this.hideLoader = true;
        this.loading = false;
        this.hideButtons = false

      }

    }
    catch {
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hideLoader = false;

    }
  }

  goBack() {
    this.preventivoService.clear();
    this.router.navigate(['/selezionaPratica']);
  }
}
