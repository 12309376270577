import { AdditionalWork } from "./_models/models";
import { environment } from "src/environments/environment";

export class DatiPreventivo {
  NORD: string = "NORD";
  CENTRO: string = "CENTRO";
  SUD: string = "SUD";

  MONO: string = "singleSplit";
  DUAL: string = "doubleSplits";
  TRIAL: string = "tripleSplits";

  SI: string = "SI";
  NO: string = "NO";

  package: string = "";
  packageType: string = "";
  service: string = "";
  serviceType: string = "";
  zonaGeografica: string = "";
  tipoImpianto: string = "";
  tipoImpiantoMnemonic: string = "";
  condizionatoreInstallato: string = "";
  smontaggio: string = "";
  lavaggio: string = "";
  predispozisione: string = "";
  staffa: string = "";
  shuko: string = "";
  idDistanzaMotoreSplit: string = "";
  distanzaMotoreSplit: number = 0;
  meterRangeMotoreSplit: string = '';
  profonditaPareteMaggioreDi40: string = "";
  nomeCliente: string = "";
  cognomeCliente: string = "";
  telefonoCliente: string = "";
  mobileCliente: string = "";
  emailCliente: string = "";
  codiceFiscaleCliente: string ="";
  indirizzoCliente: string = "";
  capCliente: string = "";
  cittaCliente: string = "";
  provinciaCliente: number = 0;
  loginCliente: number = 0;
  stesseStaffe: string = "";
  distanzaPredispozisioneCondizionatore: number = 0;
  idDistanzaPredispozisioneCondizionatore: string = "";
  posizione: string = "";
  idWebSiteFlowTracking: number = 0;
  total: number = 0;
  inspectionCode: string = "";
  extraAccessoriesDescription: string = "";
  extraAccessoriesValue: number = 0;
  discountPercentage: number = 0;
  additionalWorks: AdditionalWork[] = [];

  otherService: string = "";
  splitSelected: string = "";

  btuSelected: string = "";
  installtionHeightSelected: string = ""
  idInsatllation: string = "";

  //Calendar Virtual Inspection Appointment
  appointmentDate: string = "";
  installationDraw: any
  sonOfDigitalInspection: boolean

  vendorRefrence: string = '';
  relatedVendorRefrence: string = '';
  notes: string = "";
  sealedPlant: string = '';
  idSealedPlant: string;
  ConsEnerg: string = '';
  KwData: string = '';
  authCondo: string = '';
  autoElettrica: string = '';
  coperturaParete: string = '';
  idDimension: string = "";
  dimSelected: string = "";
  fasciaOraria: string = '';
  strutturaTetto: string = '';
  zonaAbitazione: string = "";
  pompaCalore: string = '';
  piastraInduzione: string = '';
  climatizzatoreInst: string = '';
  koprevfv: string  = '';
  idFasciaOraria: any;
  idContatorekw: any
  appointmentDateFv: string = "";
  serviceTypeFv: string = "";
  selectedProductPrice: string = "";
  selectedProductName: string = "";
  selectedProductIdPackage: string = "";
  selectedProductIDWebSiteFlow: string = "";

  clear() {
    this.package = "";
    this.service = "";
    this.zonaGeografica = "";
    this.tipoImpianto = "";
    this.tipoImpiantoMnemonic = "";
    this.condizionatoreInstallato = "";
    this.smontaggio = "";
    this.lavaggio = "";
    this.predispozisione = "";
    this.staffa = "";
    this.shuko = "";
    this.distanzaMotoreSplit = 0;
    this.meterRangeMotoreSplit = '';
    this.profonditaPareteMaggioreDi40 = "";
    this.nomeCliente = "";
    this.cognomeCliente = "";
    this.telefonoCliente = "";
    this.mobileCliente = "";
    this.emailCliente = "";
    this.codiceFiscaleCliente = "";
    this.indirizzoCliente = "";
    this.capCliente = "";
    this.cittaCliente = "";
    this.provinciaCliente = 0;
    this.loginCliente = 0;
    this.stesseStaffe = "";
    this.distanzaPredispozisioneCondizionatore = 0;
    this.posizione = "";
    this.idWebSiteFlowTracking = 0;
    this.total = 0;

    this.otherService = "";
    this.splitSelected = "";
    this.sealedPlant = '';

    this.inspectionCode = "";
    this.extraAccessoriesDescription = "";
    this.extraAccessoriesValue = 0;
    this.discountPercentage = 0;

    this.btuSelected = "";
    this.installtionHeightSelected = "";

    this.appointmentDate = "";
    this.installationDraw = null;
    this.sonOfDigitalInspection = false;
    this.vendorRefrence = '';
    this.relatedVendorRefrence = '';
    this.notes = "";

    this.ConsEnerg  = '';
    this.KwData  = '';
    this.authCondo  = '';
    this.autoElettrica = '';
    this.coperturaParete = '';
    this.idDimension = "";
    this.dimSelected = "";
    this.fasciaOraria = '';
    this.strutturaTetto = '';
    this.zonaAbitazione = "";
    this.pompaCalore = '';
    this.piastraInduzione = '';
    this.climatizzatoreInst = '';
    this.koprevfv  = '';
    this.idFasciaOraria = "";
    this.idContatorekw = "";
    this.serviceType = "";
  }

  clearAdditionalWork() {
    this.additionalWorks = [];
  }

  addAdditionalWork(arg0: AdditionalWork) {
    this.additionalWorks.push(arg0);
  }
}
