import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalErrorComponent } from "../modal-error/modal-error.component";
import { PreventivoService } from "../preventivo.service";
import { SopralluogoData } from "../_models/models";
import { UcChoice } from "../_models/uc-choice";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-servizio",
  templateUrl: "./servizio.component.html",
  styleUrls: ["./servizio.component.css"],
})
export class ServizioComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  optionFirstQuestion: string = "";

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideSopralluogo: boolean = true;
  hideNumeroPreventivi: boolean = true;

  maxAllowedQuotations: number = 0;
  remainingQuotations: number = null;
  maxQuotations: number = 0;
  lastInspectionCode: string = "";
  quotationsErrorMessage: string = "";
  generatingQuotations: boolean = false;
  loadingNumeroPreventivi: boolean = false;

  inputSopralluogo: string = "";
  DataSopralluogoForm: FormGroup;
  Sopralluogo: SopralluogoData = new SopralluogoData();

  question: string = "";
  answers: UcChoice[] = [];
  currentAnswer: UcChoice | null = null;
  data: any[] = [];
  inspectionCodes: any;
  currentDataRecord: any;
  previous: string | null = null;
  sonOfDigital: boolean;

  get canGoBack(): boolean {
    return this.preventivoService.getPackageType() !== "AFS";
  }

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService
  ) {}

  ngOnInit() {

    if(this.preventivoService.getInspectionCode()){
       this.Sopralluogo.inspectionCode =
        this.preventivoService.getInspectionCode();
        this.onInspectCodeChange(this.Sopralluogo.inspectionCode)
    }
    this.DataSopralluogoForm = this._fb.group({
      DataSopralluogoFormGroup: this._fb.group({
        inspectionCode: ["", [Validators.required]],
      }),
    });

    if (
      this.preventivoService.getPackageType() == "priceCalculatorWithNumber" &&
      this.preventivoService.getInspectionCode()
    ) {
      this.Sopralluogo.inspectionCode =
        this.preventivoService.getInspectionCode();
      this.hideSopralluogo = false;
    }

    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;

      const page = this.activatedRoute.snapshot.data["page"];

      this.climaSmartService.GetQuestionResponseList(page).subscribe(
        (data) => {
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;

          this.data = data;
          this.question = this.data[0].questionLabel;

          const qArray: UcChoice[] = [];
          for (const q of this.data) {
            qArray.push({ label: q.responseLabel, id: q.idWebSiteFlow.toString() });
          }

          this.answers = qArray;
          if (this.preventivoService.getService()) {
            this.previous = this.preventivoService.getService();
          }
        },
        (err) => {
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        }
      );
    } catch {
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  onChoiceChanged($event: UcChoice) {
    console.log('onChoiceChanged')
    this.currentAnswer = this.answers.find((a) => $event && a.id === $event.id);
    this.currentDataRecord = this.data.find(
      (d) => this.currentAnswer && d.idWebSiteFlow.toString() === this.currentAnswer.id
    );
    console.log('Event:',$event);
    console.log('currentAnswer:',this.currentAnswer);
    console.log('requiresSopralluogo:',this.requiresSopralluogo);
    console.log('currentDataRecord:',this.currentDataRecord);
    this.hideSopralluogo = !this.requiresSopralluogo;
  }

  get requiresSopralluogo(): boolean {
    return (
      this.currentDataRecord &&
      this.currentDataRecord.responseRoot === "priceCalculatorWithNumber"
    );
  }

  //Adds for sopralluogo
  onInspectCodeChange(inspCode: string) {
    console.log('onInspectCodeChange', inspCode)
    inspCode = inspCode.trim();
    this.lastInspectionCode = inspCode;

    if (inspCode.length != 16) {
      return;
    }

    try {
      this.hideNumeroPreventivi = true;
      this.remainingQuotations = null;
      this.loadingNumeroPreventivi = true;

      this.climaSmartService.GetCustomerByInspectCode(inspCode).subscribe(
        (data) => {
          this.inspectionCodes = data;

          this.loadingNumeroPreventivi = false;
          this.loading = false;
          this.hideLoader = true;

          const message: string = data.message;
          const remainingQuotations: number = data.remainingQuotations;
          const codeExist: boolean = data.codeExist;
          const maxQuotationsUnset: boolean = data.maxQuotationsUnset;
          const maxAllowedQuotations: number = data.maxAllowedQuotations;
          const isSonOfDigital : boolean = data.isSonOfDigital;

          if (message === "OK" && codeExist) {
            this.hideNumeroPreventivi = !maxQuotationsUnset;
            this.maxAllowedQuotations = 0;
            this.remainingQuotations = 0;
            this.sonOfDigital = isSonOfDigital;

            if (maxQuotationsUnset) {
              this.maxAllowedQuotations = maxAllowedQuotations;
            } else if (remainingQuotations <= 0) {
              this.modalError.show({
                errorTitle: "Errore",
                errorDetail:
                  "Hai terminato il numero massimo di preventivi concessi per questo sopralluogo",
              });
            } else {
              this.remainingQuotations = remainingQuotations;
              this.hidePage = false;
              this.preventivoService.setInspectionCode(inspCode);
            }
          } else {
            this.modalError.show({
              errorTitle: "Errore",
              errorDetail: data.message,
            });
          }
        },
        (err) => {
          this.loadingNumeroPreventivi = false;

          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.hideLoader = true;
            this.loading = false;
          }
        }
      );
    } catch {
      this.loading = true;
      this.hideLoader = false;
    }
  }

  isFormValid() {
    // if current choice does not require sopralluogo then its valid
    if (!this.requiresSopralluogo) {
      // when option does not require inspection code
      return true;
    }

    // otherwise inspection code should be valid
    let inspCodeLen = false;

    inspCodeLen =
      this.Sopralluogo.inspectionCode != undefined &&
      this.Sopralluogo.inspectionCode.trim().length == 16;

    let codeExist =
      (this.inspectionCodes && this.inspectionCodes.codeExist == true) ||
      !this.inspectionCodes;

    return (
      inspCodeLen &&
      codeExist &&
      this.hideNumeroPreventivi &&
      this.remainingQuotations > 0
    );
  }

  setFirstQuestion() {
    console.log('setFirstQuestion', this.currentAnswer.id, this.currentDataRecord.responseRoot)
    this.preventivoService.setService(this.currentAnswer.id);
    this.preventivoService.setServiceType(this.currentDataRecord.responseRoot);
    this.preventivoService.setSonOfDigitalInspection(this.sonOfDigital);

    // TODO: move to configuration
    const answers = [
      { answer: "onSite", route: "/datiCliente" },
      { answer: "onSite_MW", route: "/datiCliente" },
      { answer: "onSiteButali", route: "/datiCliente" },
      { answer: "onSiteWithEcobonus", route: "/datiCliente" },
      { answer: "onSiteWithEcobonusButali", route: "/datiCliente" },
      { answer: "onSiteWithEcobonus_MW", route: "/datiCliente" },

      // { answer: "priceCalculator", route: "/tipoImpianto" },
      // { answer: "priceCalculatorWithNumber", route: "/tipoImpianto" },
      // { answer: "priceCalculatorWithEcobonus", route: "/tipoImpianto" },

      { answer: "priceCalculator", route: "/zonaGeografica" },
      { answer: "priceCalculatorWithNumber", route: "/zonaGeografica" },
      { answer: "priceCalculatorWithEcobonus", route: "/zonaGeografica" },

      { answer: "onSiteDigital", route: "/calendario"},
      { answer: "onSiteDigital_MW", route: "/calendario"},
      { answer: "onSiteDigitalWithEcobonus", route: "/calendario"},
      { answer: "onSiteDigitalButali", route: "/calendario"},
      { answer: "onSiteDigitalWithEcobonus_MW", route: "/calendario"},
      { answer: "onSiteDigitalWithEcobonusButali", route: "/calendario"}
    ];

    const nextRoute = answers.find(
      (a) => a.answer == this.currentDataRecord.responseRoot
    );
    if (!nextRoute) {
      console.log('next ruote', nextRoute)
      return;
    }
    this.router.navigate([nextRoute.route]);
  }

  generateQuotations() {
    try {
      this.generatingQuotations = true;

      this.climaSmartService
        .generateQuotations(this.lastInspectionCode, this.maxQuotations)
        .subscribe(
          (data) => {
            this.generatingQuotations = false;

            if (!!data && data.codeExists) {
              this.onInspectCodeChange(this.lastInspectionCode);
            } else {
              this.quotationsErrorMessage = data.message;
            }
          },
          (err) => {
            this.generatingQuotations = false;
            this.quotationsErrorMessage =
              "Si è verificato un errore imprevisto, ti invitiamo a riprovare";
          }
        );
    } catch {
      this.modalError.show({
        errorTitle: "Errore",
        errorDetail:
          "Si è verificato un errore imprevisto, ti invitiamo a riprovare",
      });
    }
  }

  goBack() {
    this.preventivoService.setService('');
    this.preventivoService.setServiceType('');
    this.preventivoService.setSonOfDigitalInspection(false);
    this._location.back();
  }
}
