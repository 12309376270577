import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Error } from '../_models/models';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.css']
})
export class ModalErrorComponent implements OnInit {

  @ViewChild('childModal') public childModal: ModalDirective;
  modal: Error = new Error()

  constructor() { }

  ngOnInit() {
  }

  public show(modal: Error) {
    this.modal = modal
    this.childModal.show()
  }

  hide() {
    this.childModal.hide()
  }

}
