import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-italy",
  templateUrl: "./italy-north-split.component.html",
  styleUrls: ["./italy-north-split.component.css"]
})
export class ItalyNorthSplitComponent implements OnInit {

  selectedZone: string = "";
  selectedId: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelNorthEast: string = "";
  public ResponseLabelNorthWest: string = "";
  public ResponseLabelCenter: string = "";
  public ResponseLabelSouth: string = "";
  public ResponseIdNorthEast: string = "";
  public ResponseIdNorthWest: string = "";
  public ResponseIdCenter: string = "";
  public ResponseIdSouth: string = "";
  public Question: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private _location: Location,
  ) {}

  ngOnInit() {
   // console.log("inside ngOnInIt")

  //  console.log("getZonaGeografica", this.preventivoService.getZonaGeografica())
    if (this.preventivoService.getZonaGeografica()) {
      this.selectedZone = this.preventivoService.getZonaGeografica();
    }

    //console.log("inside italy ngOnInit");
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
    //  console.log("inside try", this.hidePage);
      this.climaSmartService
        .GetQuestionResponseList("zonaGeografica")
        .subscribe(data => {
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;

          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelCenter = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSouth = this.QuestionResponseList[1].responseLabel),
            (this.ResponseLabelNorthEast = this.QuestionResponseList[2].responseLabel),
            (this.ResponseLabelNorthWest = this.QuestionResponseList[3].responseLabel),
            (this.ResponseIdCenter = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSouth = this.QuestionResponseList[1].idWebSiteFlow);
            (this.ResponseIdNorthEast = this.QuestionResponseList[2].idWebSiteFlow),
            (this.ResponseIdNorthWest = this.QuestionResponseList[3].idWebSiteFlow)

        });
    } catch {
      //console.log("inside catch");
     // console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

    //console.log("isChecked value", selected)

    if (selected == this.preventivoService.getZonaGeografica()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  //event andler
  radioChangeHandlerZone(event: any) {

    this.selectedZone = event.target.value

  }

  setZonaGeografica() {
    this.preventivoService.setZonaGeografica(this.selectedZone);
   // console.log("select", this.selectedZone);
    //this.router.navigate(["/tipo-impianto"]);
    this.router.navigate([this.QuestionResponseList[0].pageNameNext]);
  }

  goBack() {
    this.preventivoService.setZonaGeografica('');
    this._location.back();
  }
}
