import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import {version } from "package.json";
//import { DatiPreventivo } from '../dati-preventivo.model';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  selectedZone: string = "";
  selectedPlan: string = "";
  optionConditioner: string = "";
  optionSmontaggio: string = "";
  optionLavaggio: string = "";
  optionPredispozione: string = "";
  optionStaffa: string = "";
  optionShuko: string = "";
  inputDistanza: number = 0;
  optionProfondita: string = "";
  inputNome: string = "";
  inputCognome: string = "";
  inputTelefono: string = "";
  inputEmail: string = "";
  inputIndirizzo: string = "";
  inputCitta: string = "";
  version = version;
  constructor(
    private router: Router,
    public preventivoService: PreventivoService
  ) {}

  ngOnInit() {}

  goToFirstQuestion() {
   // console.log("inside");

    this.preventivoService.clear();
    this.router.navigate(["/"]);
  }
}
