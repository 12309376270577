import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ClimaSmartService } from '../_service/clima-smart.service';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-pickup-point',
  templateUrl: './modal-pickup-point.component.html',
  styleUrls: ['./modal-pickup-point.component.css']
})
export class ModalPickupPointComponent implements OnInit {
  @ViewChild('childModal') public childModal: ModalDirective;
  @Output() refreshTable = new EventEmitter<string>();

  today = new Date();

  hidePage: boolean = true;
  loadingPage: boolean = false;
  loading: boolean = false;

  pickupForm: FormGroup;

  selectedDate: string = '';
  number: string = '';
  ticket: string = '';
  idWebSiteFlowTracking: number ;
  showMessage: boolean = false;
  showSubmitButton: boolean = true;

  pickupPointArray = [];

  pickupPointSelected = '';

  datePickerConfig: Partial<BsDatepickerConfig>;

  hidePickupPoint: boolean = true

  constructor(  private _fb: FormBuilder, private climaSmartService: ClimaSmartService) {

    this.datePickerConfig = Object.assign(
      {},

      {
        containerClass: "theme-dark-blue",
        showWeekNumbers: false,
        dateInputFormat: "DD/MM/YYYY"
      }
    );
   }

  ngOnInit() {

    this.pickupForm = this._fb.group({
      date: ['', [Validators.required]],
      pickupPoint : ['', []],
    });
  }


  callResearchPage() {
    this.refreshTable.emit('refreshTable');
  }

  public show(idWebSiteFlowTracking, ticket ) {
    this.showSubmitButton = true;
    this.GetPickupPointList();
    this.idWebSiteFlowTracking = idWebSiteFlowTracking;
    this.ticket = ticket;
    this.childModal.show();
    this.pickupForm.reset();
  }

  hide() {

    this.pickupForm.reset();
    this.showMessage = false;
    this.childModal.hide();
  }

  GetPickupPointList() {

    this.loadingPage = true;
     try {
      this.climaSmartService.GetPickupPointList()
        .subscribe(data => {
          this.loadingPage = false;
          this.hidePage = false;
            if (data) {
              this.pickupPointArray = data.pickupPoints;
            }
            if (data.pickupPoints.length === 1) {
                this.pickupPointSelection(data.pickupPoints[0].idPickupPoint);
            }
            else {
            }
          },
        err => {
              this.loadingPage = false;
          }
        );
    } catch (ex) {
      this.loadingPage = false;
    }
  }
  onSubmitPickupPoint() {
    const data = {
      deliveryDate: this.selectedDate,
      idWebSiteFlowTracking : this.idWebSiteFlowTracking,
      IdpickupPoint : this.pickupPointSelected,
      idBusiness : 'MEDIAINST'
    };
    this.loading = true;


    try {
      this.climaSmartService
        .savePickupPoint(data)
        .subscribe(data => {
            if (data) {
              this.showMessage = true;
              this.loading = false;
              this.showSubmitButton = false;
              this.hide();
              this.callResearchPage();
            }
            else {
            }
          },
        err => {
              this.loading = false;
          }
        );
    } catch (ex) {

      this.loading = false;
    }
  }

  pickupPointSelection(pickupPoint) {

    this.pickupPointSelected = pickupPoint;
  }

}
