import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-staffa",
  templateUrl: "./staffa.component.html",
  styleUrls: ["./staffa.component.css"]
})
export class StaffaComponent implements OnInit {
  optionStaffa: string = "";
  public albums: Array<IAlbum>;
  optionPredisposizione: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelSI: string = "";
  public ResponseLabelSI2: string = "";
  public ResponseLabelNO: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdSI2: string = "";
  public ResponseIdNO: string = "";
  public Question: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.optionPredisposizione = this.preventivoService.getPredispozisione();

    this.albums = [];

    var src = "../assets/images/3DSTAFFA-L.jpg";
    var caption = "";
    var thumb = "../assets/images/3DSTAFFA-S.jpg";
    var album = {
      src: src,
      caption: "STAFFA",
      thumb: thumb
    };

    this.albums.push(album);
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  ngOnInit() {
  //  console.log("inside ngOnInIt")

  // console.log("getStaffa", this.preventivoService.getStaffa())
    if (this.preventivoService.getStaffa()) {
      this.optionStaffa = this.preventivoService.getStaffa();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    //console.log("inside support ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("staffa")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
            //(this.ResponseLabelSI2 = this.QuestionResponseList[2].responseLabel),
            (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
            //(this.ResponseIdSI2 = this.QuestionResponseList[2].idWebSiteFlow),
           // console.log("xxxx", this.QuestionResponseList);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
        , err => {

          //console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
     // console.log("inside catch");
      //console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

    //console.log("isChecked value", selected)

    if (selected == this.preventivoService.getStaffa()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  //event andler
  radioChangeHandler(event: any) {
    this.optionStaffa = event.target.value;
    //console.log(" Selected option Staffa is: ", this.optionStaffa);
  }

  setStaffa() {
    this.preventivoService.setStaffa(this.optionStaffa);
    // this.router.navigate(["/shuko"]);

    // PAGINA PRODOTTI STATICA
    // this.router.navigate(['/prodotti']);


    this.router.navigate([this.QuestionResponseList[0].pageNameNext]);
  }

  // backClicked() {
  //   this.preventivoService.setPredispozisione(this.optionPredisposizione);
  //   var prevRoute: string = "/distanza";
  //   if (this.optionPredisposizione == this.preventivoService.getSI()) {
  //     prevRoute = "/predisposizione";
  //   }

  //   this.router.navigate([prevRoute]);
  // }

  goBack() {
    this.preventivoService.setStaffa('');
    this._location.back();
  }
}
