import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PreventivoService } from "../preventivo.service";

@Component({
  selector: "app-gateway",
  templateUrl: "./gateway.component.html",
  styleUrls: ["./gateway.component.css"],
})
export class GatewayComponent implements OnInit {

  inspectionCode: string = "";

  constructor(
    private preventivoService: PreventivoService,
    private router: Router
  ) {

    //if there is an inspection code(ic) as a query string in the url we save it 
    this.inspectionCode = router.parseUrl(router.url).queryParams.ic
    if (this.inspectionCode != undefined && this.inspectionCode.length === 16) {
      console.log('inspection code')
      this.preventivoService.setInspectionCode(this.inspectionCode)
    }
  }

  ngOnInit() {
    console.log('ngOnInit app-gateway')
    // If logged in we expect to be a shop
    if (localStorage.getItem('userToken')) {

      this.router.navigate(['/selezionaPratica']);

    } else { // otherwise we expect it to be an afs

      console.log('AFS')
      this.preventivoService.setPackageType("AFS");
      //this.preventivoService.setInspectionCode('')
      this.router.navigate(['/servizio']);

    }

  }
}
