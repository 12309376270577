import { Component, OnInit, ViewChild, ɵConsole } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { SopralluogoData } from "../_models/models";
import { Location } from "@angular/common";
import { ModalErrorComponent } from "../modal-error/modal-error.component";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

@Component({
  selector: "app-other-services",
  templateUrl: "./other-services.component.html",
  styleUrls: ["./other-services.component.css"],
})
export class OtherServicesComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  optionOtherServices: string = "";
  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;

  public ResponseList: any;

  public QuestionResponseList: Array<any>;
  public ResponseLabelPreventivo: string = "";
  public ResponseLabelSopralluogo: string = "";
  public ResponseLabelpreventivoConNumero: string = "";
  public ResponseIdPreventivo: string = "";
  public ResponseIdSopralluogo: string = "";
  public ResponseIdpreventivoConNumero: string = "";
  public ResponseIdOtherServices: string = "";
  public Question: string = "";

  inputSopralluogo: string = "";
  public DataSopralluogoForm: FormGroup;
  public Sopralluogo: SopralluogoData = new SopralluogoData();

  arrayLength: number;

  public OtherSercvicesForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private _location: Location
  ) { }

  ngOnInit() {


    this.OtherSercvicesForm = this._fb.group({
      OtherServiceFormGroup: this._fb.group({
        otherService: ["", [Validators.required]],

      })
    });


    console.log("OtherServicesComponent", this.preventivoService.getOtherService())
    if (this.preventivoService.getOtherService()) {
      this.optionOtherServices = this.preventivoService.getOtherService();
    }

    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      console.log("inside try", this.hidePage);

      this.climaSmartService.GetQuestionResponseList("altriServizi").subscribe(
        (data) => {
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;

          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel)

          if (this.QuestionResponseList.length === 1) {
            this.arrayLength = this.QuestionResponseList.length;

          }
          console.log("QuestionResponseList", this.QuestionResponseList, this.optionOtherServices);
        },
        (err) => {
          // console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        }
      );
    } catch {
      // console.log("inside catch");
      //console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

    if (this.QuestionResponseList.length === 1) {

      console.log("is checked ", selected)

      this.radioChangeHandler(selected);
      return true;
    }

    if (selected == this.preventivoService.getOtherService()) {

      console.log("get other service", this.preventivoService.getOtherService())
      return true;
    }

    return false;

  }


  // //event andler
  radioChangeHandler(id) {
    this.optionOtherServices = id;
    console.log("Selected option is: ", this.optionOtherServices);
  }


  setOtherService() {

    console.log(" setOtherService", this.optionOtherServices)
    this.preventivoService.setOtherService(this.optionOtherServices);
    console.log("selected other service is ", this.optionOtherServices);
    this.router.navigate(["/selezioneSplit"]);

  }

  goBack() {
    this.preventivoService.setOtherService('');

    this._location.back();
  }
}
