import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PreventivoService } from "../preventivo.service";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { Location } from "@angular/common";
import { HttpEventType } from "@angular/common/http";

@Component({
  selector: "app-upload-installation-draw",
  templateUrl: "./upload-installation-draw.component.html",
  styleUrls: ["./upload-installation-draw.component.css"],
})
export class UploadInstallationDrawComponent implements OnInit {
  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideMessage: boolean = false;
  uploading = false;
  uploadProgress = 0;
  uploadingMessage = null;
  filename = 'Nessun file selezionato';
  filesize: number
  error = null;

  public Question: string = "";
  public installationDrawMessage: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private _location: Location
  ) {}

  ngOnInit() {
    if (this.preventivoService.getInstallationDraw()) {
      this.filename = this.preventivoService.getInstallationDraw().filename;
      this.filesize = this.preventivoService.getInstallationDraw().filesize;
    }

    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetTranslation("INSTALLATION_DRAW_TITLE")
        .subscribe(
          (data) => {
            this.Question = data.translation;
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          },
          (err) => {
            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          }
        );

      this.climaSmartService
        .GetTranslation("INSTALLATION_DRAW_MESSAGE")
        .subscribe(
          (data) => {
            this.installationDrawMessage = data.translation;
          },
          (err) => {
            if (err.status === 404) {
              this.installationDrawMessage = null;
            }
          }
        );
    } catch {
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isFormValid() {
    return !!this.preventivoService.getInstallationDraw()
      && !this.uploading
      && !this.loading;
  }

  next() {
    // TODO: move to configuration
    const answers = [
      { answer: "priceCalculator", route: "/datiPreventivo" },
      { answer: "priceCalculatorWithNumber", route: "/datiSopralluogo" },
      { answer: "priceCalculatorWithEcobonus", route: "/datiPreventivo" },
    ];

    const nextRoute = answers.find(
      (a) =>
        a.answer == this.preventivoService.getPackageType() ||
        a.answer == this.preventivoService.getServiceType()
    );
    if (!nextRoute) {
      return;
    }

    this.router.navigate([nextRoute.route]);
  }

  goBack() {
    this._location.back();
  }

  uploadFile(files: any[]) {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    this.uploading = true;
    this.filename = fileToUpload.name;
    this.filesize = fileToUpload.size;

    this.climaSmartService.uploadDocument(formData).subscribe(
      (event) => {
        console.log("Upload Event: ", event);
        let updateProgress = 0;
        let completed = false;

        if (event.type === HttpEventType.UploadProgress)
          updateProgress = Math.round((100 * event.loaded) / event.total);
        else if (event.type === HttpEventType.Response) {
          updateProgress = 100;
          completed = true;
          if (event.ok && event.status === 200) {
            this.preventivoService.setInstallationDraw({
              filename: this.filename,
              filesize: this.filesize,
              idAttachment: event.body.idAttachment
            });
          }
        }

        this.uploadProgress = updateProgress;
        this.uploading = !completed || updateProgress < 100;
        if (!completed && this.uploadProgress < 100) {
          this.uploadingMessage = `Caricamento file ${this.uploadProgress} %`;
        } else if (!completed) {
          this.uploadingMessage = `Salvataggio file...`;
        } else {
          this.uploadingMessage = null;
        }
      },
      (e) => {
        console.log(e);
        this.uploadProgress = 0;
        this.uploading = false;
        this.error = e.error;
      },
      () => {
        console.log('Complete')
      }
    );
  }
}
