export const environment = {
  production: false,
  idElementExtra: [2733],
  shopTicketNoteType: 86,
  linkToExample: '',
  quotationHeaderOnSite: "Gentile Cliente, dovrà recarsi presso il punto vendita Mediaworld da lei indicato e presentare il pdf scaricabile qui sotto e che invieremo a breve al suo indirizzo mail per acquistare il servizio al prezzo di 49,99 euro",
  quotationHeaderOnSiteNotice: "Le ricordiamo che il documento avrà una durata di 30 giorni dalla data di emissione in esso riportata.",
  quotationHeaderOnSiteDig: "Gentile Cliente, dovrà recarsi presso il punto vendita Mediaworld da lei indicato e presentare il pdf scaricabile qui sotto e che invieremo a breve al suo indirizzo mail per acquistare il servizio al prezzo di 24,99 euro",
  quotationHeaderOnSiteDigNotice: "Le ricordiamo che il documento avrà una durata fino al termine della giornata successiva alla data di emissione in esso riportata.",
  splitNorth: true,
  searchColumns: [
    "insertDate",
    "ticket",
    "service",
    "status",
    "appointmentDate",
    "estimateTot",
    "fullName",
    "shop",
    "idWebSiteFlowTracking",    
    "shopActions"
  ],
  documents: [
    { fileName: "Regolamento Clima Smart", link: "https://cdn.covercare.it/climasmart/mediaworld/RegolamentoClimaSmart.pdf", type: 'REGOLAMENTO', format: 'PDF' },
    { fileName: "Listino al Pubblico", link: "https://cdn.covercare.it/climasmart/mediaworld/ListinoOpereAccessorieClimaSmart.pdf", type: 'LISTINO', format: 'PDF' }
  ]
};
