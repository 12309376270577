import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-condizionatore-installato",
  templateUrl: "./condizionatore-installato.component.html",
  styleUrls: ["./condizionatore-installato.component.css"]
})
export class CondizionatoreInstallatoComponent implements OnInit {
  optionConditioner: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelNO: string = "";
  public ResponseLabelSI: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdNO: string = "";
  public Question: string = "";
  answer: any

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {}

  ngOnInit() {
   // console.log("inside ngOnInIt")

    //console.log("getCondizionatoreInstallato", this.preventivoService.getCondizionatoreInstallato())
    if (this.preventivoService.getCondizionatoreInstallato()) {
      this.optionConditioner = this.preventivoService.getCondizionatoreInstallato();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    //console.log("inside area geografica ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("condizionatoreInstallato")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
            (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
           // console.log("xxxx", this.QuestionResponseList);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
        , err => {

        //  console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();

            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
      //console.log("inside catch");
     // console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }


  isChecked(selected: any) {

   // console.log("isChecked value", selected)

    if (selected == this.preventivoService.getCondizionatoreInstallato()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }


  //event andler
  radioChangeHandler(event: any) {
    this.optionConditioner = event.target.value;
    this.answer = this.QuestionResponseList.find(qr => qr.idWebSiteFlow.toString() === this.optionConditioner)
   // console.log("Selected option is: ", this.optionConditioner);
  }

  // goBack() {
  //   console.log("inside go back button");
  //   this.optionConditioner = "";
  //   console.log(" selected item is ", this.optionConditioner);
  // }

  setCondizionatoreInstallato() {
    this.preventivoService.setCondizionatoreInstallato(this.optionConditioner);
    this.preventivoService.setCondizionatoreInstallatoMnemonic(!!this.answer ? this.answer.responseRoot : null);
    var nextRoute: string = this.QuestionResponseList[0].pageNameNext;
    if (this.optionConditioner == this.ResponseIdSI) {
      //nextRoute="/smontaggio";
      nextRoute = this.QuestionResponseList[1].pageNameNext;
    }

    this.router.navigate([nextRoute]);
  }

  goBack() {
    this.preventivoService.setCondizionatoreInstallato('');
    this.preventivoService.setCondizionatoreInstallatoMnemonic('');
    this._location.back();
  }
}
