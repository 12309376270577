import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ModalErrorComponent } from "../modal-error/modal-error.component";
import { PreventivoService } from "../preventivo.service";
import { SopralluogoData } from "../_models/models";
import { UcChoice } from "../_models/uc-choice";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-select-package",
  templateUrl: "./select-package.component.html",
  styleUrls: ["./select-package.component.css"],
})
export class SelectPackageComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  optionFirstQuestion: string = "";

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideSopralluogo: boolean = true;
  hideNumeroPreventivi: boolean = true;

  maxAllowedQuotations: number = 0;
  remainingQuotations: number = null;
  maxQuotations: number = 0;
  lastInspectionCode: string = "";
  quotationsErrorMessage: string = "";
  generatingQuotations: boolean = false;
  loadingNumeroPreventivi: boolean = false;

  inputSopralluogo: string = "";
  DataSopralluogoForm: FormGroup;
  Sopralluogo: SopralluogoData = new SopralluogoData();

  question: string = "";
  answers: UcChoice[] = [];
  currentAnswer: UcChoice | null = null;
  data: any[] = [];
  inspectionCodes: any;
  currentDataRecord: any;
  previous: string | null = null;

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;

      this.climaSmartService
        .GetQuestionResponseList("selezionaPratica")
        .subscribe(
          (data) => {
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;

            this.data = data;
            this.question = this.data[0].questionLabel;

            const qArray: UcChoice[] = [];
            for (const q of this.data) {
              qArray.push({ label: q.responseLabel, id: q.idWebSiteFlow.toString() });
            
            }
            
            this.answers = qArray;
            if (this.preventivoService.getPackage()) {
              this.previous = this.preventivoService.getPackage();
            }
          },
          (err) => {
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          }
        );
    } catch {
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  onChoiceChanged($event: UcChoice) {
    this.currentAnswer = this.answers.find((a) => $event && a.id === $event.id);
    this.currentDataRecord = this.data.find(
      (d) => this.currentAnswer && d.idWebSiteFlow.toString() === this.currentAnswer.id
    );

    console.log('currentAnswer', this.currentAnswer);
    console.log('currentDataRecord', this.currentDataRecord);
  }

  isFormValid() {
    return !!this.currentDataRecord;
  }

  setFirstQuestion() {
    this.preventivoService.setPackage(this.currentAnswer.id);
    this.preventivoService.setPackageType(this.currentDataRecord.responseRoot);
    const nextRoute = this.currentDataRecord.pageNameNext;
    if (!nextRoute) {
      return;
    }

    this.router.navigate([nextRoute]);
  }
}
