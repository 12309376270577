import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { environment } from "src/environments/environment"

@Component({
  selector: "app-predisposizione",
  templateUrl: "./predisposizione.component.html",
  styleUrls: ["./predisposizione.component.css"]
})
export class PredisposizioneComponent implements OnInit {
  optionPredispozisione: string = "";
  selectedPlan: string = "";
  //selectedType: number = 0;
  public albums: Array<IAlbum>;
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelSI: string = "";
  public ResponseLabelNO: string = "";
  public ResponseIdSI: number = 0;
  public ResponseIdNO: number = 0;
  public Question: string = "";

  get linkToExamples():string{
    return environment.linkToExample;
  }

  answer: any

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.selectedPlan = this.preventivoService.getTipoImpiantoMnemonic();

    //console.log("xxxxxxxx", this.selectedPlan);
    //this.selectedPlan = this.climaSmartService.GetQuestionResponseListGeographicZone()

    this.albums = [];

    //console.log("preventivoService", preventivoService.getMONO());

    if (this.selectedPlan == preventivoService.getMONO()) {
    //  console.log("inside");

      var src = "../assets/images/mono-predisposizione3D.jpg";
      var caption = "";
      var thumb = "../assets/images/mono-predisposizione3D-S.jpg";
      var album = {
        src: src,
        caption: "PREDISPOSIZIONE - Impianto Mono",
        thumb: thumb
      };

      this.albums.push(album);
    }

    if (this.selectedPlan == preventivoService.getDUAL()) {
     // console.log("inside 2");

      src = "../assets/images/dual-predisposizione3D.jpg";
      caption = "";
      thumb = "../assets/images/dual-predisposizione3D-S.jpg";
      album = {
        src: src,
        caption: "PREDISPOSIZIONE - Impianto Dual",
        thumb: thumb
      };

      this.albums.push(album);
    }

    if (this.selectedPlan == preventivoService.getTRIAL()) {
      src = "../assets/images/trial-predisposizione3D.jpg";
      caption = "";
      thumb = "../assets/images/trial-predisposizione3D-S.jpg";
      album = {
        src: src,
        caption: "PREDISPOSIZIONE - Impianto Trial",
        thumb: thumb
      };

      this.albums.push(album);
    }
  }

  open(index: number): void {
    // open lightbox
    //console.log("inside open");
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  ngOnInit() {

    //console.log("inside ngOnInIt")

   // console.log("getPredispozisione", this.preventivoService.getPredispozisione())
    if (this.preventivoService.getPredispozisione()) {
      this.optionPredispozisione = this.preventivoService.getPredispozisione();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    //console.log("inside predisposition ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("predisposizione")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
            (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
          //  console.log("xxxx", this.QuestionResponseList);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
        , err => {

        //  console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
     // console.log("inside catch");
    //  console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

   // console.log("isChecked value", selected)

    if (selected == this.preventivoService.getPredispozisione()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  //event andler
  radioChangeHandler(event: any) {
    this.optionPredispozisione = event.target.value;
    this.answer = this.QuestionResponseList.find(qr => qr.idWebSiteFlow.toString() === this.optionPredispozisione)
    console.log("Predispozisione : ", event.target.value);
  }

  setPredispozisione() {
   // console.log("inside xxxxxxxxxxxxxx");
    this.preventivoService.setPredispozisione(this.optionPredispozisione);
    this.preventivoService.setPredisposizioneMnemonic(!!this.answer ? this.answer.responseRoot : null);
    //var nextRoute: string = "/distanza";
    var nextRoute: string = this.QuestionResponseList[0].pageNameNext;
    if (this.optionPredispozisione == this.ResponseIdSI.toString() ) {
      nextRoute = this.QuestionResponseList[1].pageNameNext;
     // console.log("im inside if")
    }

    this.router.navigate([nextRoute]);
  }
  goBack() {
    this.preventivoService.setPredispozisione('');
    this.preventivoService.setPredisposizioneMnemonic(null);
    this._location.back();
  }
}
