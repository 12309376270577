import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { FormBuilder } from '@angular/forms';
import { UcChoice } from '../_models/uc-choice';
import { Router } from '@angular/router';
import { PreventivoService } from '../preventivo.service';
import { ClimaSmartService } from '../_service/clima-smart.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fascia-oraria-consumo',
  templateUrl: './fascia-oraria-consumo.component.html',
  styleUrls: ['./fascia-oraria-consumo.component.css']
})
export class FasciaOrariaConsumoComponent implements OnInit {
  optionConditioner: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelDopole19: string = "";
  public ResponseLabelPrimale19: string = "";
  public ResponseIdDopole19: string = "";
  public ResponseIdPrimale19: string = "";
  public Question: string = "";
  answer: any

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  ngOnInit() {
    if (this.preventivoService.getFasciaOraria()) {
      this.optionConditioner = this.preventivoService.getFasciaOraria();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("FasciaOrariaConsumo")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelPrimale19 = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelDopole19 = this.QuestionResponseList[1].responseLabel),
            (this.ResponseIdPrimale19 = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdDopole19 = this.QuestionResponseList[1].idWebSiteFlow),
            this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
          , err => {

           
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();

              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });
    } catch {
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }


  isChecked(selected: any) {


    if (selected == this.preventivoService.getFasciaOraria()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }


  //event andler
  radioChangeHandler(event: any) {
    this.optionConditioner = event.target.value;
    this.answer = this.QuestionResponseList.find(qr => qr.idWebSiteFlow.toString() === this.optionConditioner)
  }

  confermaFasciaOraria() {

    this.preventivoService.setFasciaOraria(this.optionConditioner);
    var nextRoute: string = this.QuestionResponseList[0].pageNameNext;
    if (this.optionConditioner == this.ResponseIdDopole19 || this.optionConditioner == this.ResponseIdPrimale19) {
      nextRoute = this.QuestionResponseList[1].pageNameNext;
    }
    this.router.navigate([nextRoute]);
  }

  goBack() {
    this.preventivoService.setFasciaOraria('');
    this.preventivoService.setFasciaOrariaMnemonic('');
    this._location.back();
  }
}

