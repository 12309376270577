import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-lavaggio",
  templateUrl: "./lavaggio.component.html",
  styleUrls: ["./lavaggio.component.css"]
})
export class LavaggioComponent implements OnInit {
  optionLavaggio: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelSI: string = "";
  public ResponseLabelNO: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdNO: string = "";
  public Question: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {}

  ngOnInit() {

   // console.log("inside ngOnInIt")

   // console.log("getLavaggio", this.preventivoService.getLavaggio())
    if (this.preventivoService.getLavaggio()) {
      console.log('ifffff',this.preventivoService.getLavaggio() )
      this.optionLavaggio = this.preventivoService.getLavaggio();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
   // console.log("inside Wash ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("lavaggio")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
            (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
          //  console.log("xxxx", this.QuestionResponseList);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
        , err => {

          console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
     // console.log("inside catch");
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

   console.log("isChecked value", selected)

    if (selected == this.preventivoService.getLavaggio()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  radioChangeHandler(event: any) {
    this.optionLavaggio = event.target.value;
   // console.log("Lavaggio is: ", this.optionLavaggio);
  }

  setLavaggio() {
    console.log('setLavaggio', this.optionLavaggio)
    this.preventivoService.setLavaggio(this.optionLavaggio);
    //this.router.navigate(["/datiCliente"]);
    // var nextRoute: string = "/datiSopralluogo";
    // if (this.preventivoService.getFirstQuestion() == "50") {

    //     //nextRoute = "/datiPreventivo";
    //     nextRoute = "/datiInstallazione";
    // }

    // this.router.navigate([nextRoute]);

    this.router.navigate(['./datiInstallazione']);
  }

  goBack() {
    this.preventivoService.setLavaggio('');
    this._location.back();
  }
}
