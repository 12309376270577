import { Injectable } from "@angular/core";
import { DatiPreventivo } from "./dati-preventivo.model";
import { PrezziPreventivo } from "./prezzi-preventivo.model";
import { BehaviorSubject } from "rxjs";
import { AdditionalWork, InstallationData, uploadedFile as UploadedFile } from "./_models/models";

@Injectable({
  providedIn: "root",
})
export class PreventivoService {

  datiPreventivo = new DatiPreventivo();
  webSiteFlowTracking: number;
  condizionatoreInstallatoMnemonic: any
  predisposizioneMnemonic: any
  fasciaOrariaMnemonic: any

  constructor() {}

  // getTipoImpiantoID(): number {
  //   return this.datiPreventivo.tipoImpiantoID;
  // }

  // setTipoImpiantoID(tiID: number) {
  //   this.datiPreventivo.tipoImpiantoID = tiID;
  // }
  ///

  getNORD(): string {
    return this.datiPreventivo.NORD;
  }

  getCENTRO(): string {
    return this.datiPreventivo.CENTRO;
  }

  getSUD(): string {
    return this.datiPreventivo.SUD;
  }

  getMONO(): string {
    return this.datiPreventivo.MONO;
  }

  getDUAL(): string {
    return this.datiPreventivo.DUAL;
  }

  getTRIAL(): string {
    return this.datiPreventivo.TRIAL;
  }

  getSI(): string {
    return this.datiPreventivo.SI;
  }

  getNO(): string {
    return this.datiPreventivo.NO;
  }

  getPackage(): string {
    return this.datiPreventivo.package;
  }

  setPackage(pkg: string) {
    this.datiPreventivo.package = pkg;
  }

  getPackageType(): string {
    return this.datiPreventivo.packageType;
  }

  setPackageType(pkgType: string) {
    this.datiPreventivo.packageType = pkgType;
  }

  getService(): string {
    return this.datiPreventivo.service;
  }

  setServiceType(serviceType: string) {
    this.datiPreventivo.serviceType = serviceType;
  }

  getServiceType(): string {
    return this.datiPreventivo.serviceType;
  }

  setService(service: string) {
    this.datiPreventivo.service = service;
  }

  getZonaGeografica(): string {
    return this.datiPreventivo.zonaGeografica;
  }

  setZonaGeografica(zg: string) {
    this.datiPreventivo.zonaGeografica = zg;
  }

  getTipoImpianto(): string {
    return this.datiPreventivo.tipoImpianto;
  }

  setTipoImpianto(ti: string) {
    this.datiPreventivo.tipoImpianto = ti;
  }

  getTipoImpiantoMnemonic(): string {
    return this.datiPreventivo.tipoImpiantoMnemonic;
  }

  setTipoImpiantoMnemonic(ti: string) {
    this.datiPreventivo.tipoImpiantoMnemonic = ti;
  }

  getCondizionatoreInstallato(): string {
    return this.datiPreventivo.condizionatoreInstallato;
  }

  setCondizionatoreInstallato(co: string) {
    this.datiPreventivo.condizionatoreInstallato = co;
  }

  getSmontaggio(): string {
    return this.datiPreventivo.smontaggio;
  }

  setSmontaggio(sm: string) {
    this.datiPreventivo.smontaggio = sm;
  }

  getLavaggio(): string {
    return this.datiPreventivo.lavaggio;
  }

  setLavaggio(la: string) {
    this.datiPreventivo.lavaggio = la;
  }

  getPredispozisione(): string {
    return this.datiPreventivo.predispozisione;
  }

  setPredispozisione(pr: string) {
    this.datiPreventivo.predispozisione = pr;
  }

  getStaffa(): string {
    return this.datiPreventivo.staffa;
  }

  setStaffa(st: string) {
    this.datiPreventivo.staffa = st;
  }

  getShuko(): string {
    return this.datiPreventivo.shuko;
  }

  setShuko(sh: string) {
    this.datiPreventivo.shuko = sh;
  }

  getProductPrice(): string {
    return this.datiPreventivo.selectedProductPrice;
  }

  setProductPrice(price: string) {
    this.datiPreventivo.selectedProductPrice = price;
  }

  getProductName(): string {
    return this.datiPreventivo.selectedProductName;
  }

  setProductName(name: string) {
    this.datiPreventivo.selectedProductName = name;
  }

  getProductIdPackage(): string {
    return this.datiPreventivo.selectedProductIdPackage;
  }

  setProductIdPackage(idPackage: string) {
    this.datiPreventivo.selectedProductIdPackage = idPackage;
  }

  getProductIDWebSiteFlow(): string {
    return this.datiPreventivo.selectedProductIDWebSiteFlow;
  }

  setProductIDWebSiteFlow(IDWebSiteFlow: string) {
    this.datiPreventivo.selectedProductIDWebSiteFlow = IDWebSiteFlow;
  }


  //added
  getIdDistanzaMotoreSplit(): string {
    return this.datiPreventivo.idDistanzaMotoreSplit;
  }

  setIdDistanzaMotoreSplit(idm: string) {
    this.datiPreventivo.idDistanzaMotoreSplit = idm;
  }

  getDistanzaMotoreSplit(): number {
    return this.datiPreventivo.distanzaMotoreSplit;
  }

  setDistanzaMotoreSplit(dm: number) {
    this.datiPreventivo.distanzaMotoreSplit = dm;
  }

  getMeterRangeMotoreSplit() {
    return this.datiPreventivo.meterRangeMotoreSplit;
  }

  setMeterRangeMotoreSplit(cc: string) {
    this.datiPreventivo.meterRangeMotoreSplit = cc;
  }

  getProfonditaPareteMaggioreDi40(): string {
    return this.datiPreventivo.profonditaPareteMaggioreDi40;
  }

  setProfonditaPareteMaggioreDi40(pr: string) {
    this.datiPreventivo.profonditaPareteMaggioreDi40 = pr;
  }

  getStesseStaffe(): string {
    return this.datiPreventivo.stesseStaffe;
  }

  setStesseStaffe(sst: string) {
    this.datiPreventivo.stesseStaffe = sst;
  }
  //added
  getIdDistanzaPredispozisioneCondizionatore(): string {
    return this.datiPreventivo.idDistanzaPredispozisioneCondizionatore;
  }

  setIdDistanzaPredispozisioneCondizionatore(idprc: string) {
    this.datiPreventivo.idDistanzaPredispozisioneCondizionatore = idprc;
  }
  //

  getDistanzaPredispozisioneCondizionatore(): number {
    return this.datiPreventivo.distanzaPredispozisioneCondizionatore;
  }

  setDistanzaPredispozisioneCondizionatore(dprc: number) {
    this.datiPreventivo.distanzaPredispozisioneCondizionatore = dprc;
  }

  getPosizione(): string {
    return this.datiPreventivo.posizione;
  }

  setPosizione(po: string) {
    this.datiPreventivo.posizione = po;
  }

  // Dati Cliente

  getNomeCliente(): string {
    return this.datiPreventivo.nomeCliente;
  }

  setNomeCliente(nc: string) {
    this.datiPreventivo.nomeCliente = nc;
  }

  getCognomeCliente(): string {
    return this.datiPreventivo.cognomeCliente;
  }

  setCognomeCliente(cc: string) {
    this.datiPreventivo.cognomeCliente = cc;
  }

  getTelefonoCliente(): string {
    return this.datiPreventivo.telefonoCliente;
  }

  setTelefonoCliente(tc: string) {
    this.datiPreventivo.telefonoCliente = tc;
  }

  getMobileCliente(): string {
    return this.datiPreventivo.mobileCliente;
  }

  setMobileCliente(mo: string) {
    this.datiPreventivo.mobileCliente = mo;
  }

  getEmailCliente(): string {
    return this.datiPreventivo.emailCliente;
  }

  setEmailCliente(ec: string) {
    this.datiPreventivo.emailCliente = ec;
  }

  getCodiceFiscaleCliente() {
    return this.datiPreventivo.codiceFiscaleCliente
  }

  setCodiceFiscaleCliente(cf: string) {
    this.datiPreventivo.codiceFiscaleCliente = cf
  }

  getIndirizzoCliente(): string {
    return this.datiPreventivo.indirizzoCliente;
  }

  setIndirizzoCliente(ic: string) {
    this.datiPreventivo.indirizzoCliente = ic;
  }

  getCustomerNotes(): string {
    return this.datiPreventivo.notes;
  }

  setCustomerNotes(cn: string) {
    this.datiPreventivo.notes = cn;
  }

  getCapCliente(): string {
    return this.datiPreventivo.capCliente;
  }

  setCapCliente(ca: string) {
    this.datiPreventivo.capCliente = ca;
  }

  getCittaCliente(): string {
    return this.datiPreventivo.cittaCliente;
  }

  setCittaCliente(ci: string) {
    this.datiPreventivo.cittaCliente = ci;
  }

  getProvinciaCliente(): number {
    return this.datiPreventivo.provinciaCliente;
  }

  setProviniciaCliente(pr: number) {
    this.datiPreventivo.provinciaCliente = pr;
  }

  getIdWebSiteTrackingFlow(): number {
    return this.datiPreventivo.idWebSiteFlowTracking;
  }

  setIdWebSiteTrackingFlow(idwf: number) {
    this.datiPreventivo.idWebSiteFlowTracking = idwf;
  }

  getLoginCliente(): number {
    return this.datiPreventivo.loginCliente;
  }

  setLoginCliente(lo: number) {
    this.datiPreventivo.loginCliente = lo;
  }

  getTotal(): number {
    return this.datiPreventivo.total;
  }

  setTotal(to: number) {
    this.datiPreventivo.total = to;
  }

  //dati sopralluogo

  getInspectionCode(): string {
    return this.datiPreventivo.inspectionCode;
  }

  setInspectionCode(ic: string) {
    this.datiPreventivo.inspectionCode = ic;
  }

  getOpereExtra(): string {
    return this.datiPreventivo.extraAccessoriesDescription;
  }

  setOpereExtra(oe: string) {
    this.datiPreventivo.extraAccessoriesDescription = oe;
  }

  getOpereExtraPrice(): number {
    return this.datiPreventivo.extraAccessoriesValue;
  }

  setOpereExtraPrice(oep: number) {
    this.datiPreventivo.extraAccessoriesValue = oep;
  }

  getDiscountPercent(): number {
    return this.datiPreventivo.discountPercentage;
  }

  setDiscountPercent(dp: number) {
    this.datiPreventivo.discountPercentage = dp;
  }

  addAdditionalWork(arg0: AdditionalWork): void {
    this.datiPreventivo.addAdditionalWork(arg0);
  }

  clearAdditionalWork() {
    this.datiPreventivo.clearAdditionalWork();
  }

  getAdditionalWorks(): AdditionalWork[] {
    return this.datiPreventivo.additionalWorks;
  }

  clear() {
    this.datiPreventivo.clear();
    this.datiPreventivo.clearAdditionalWork();
    this.webSiteFlowTracking = null;
  }

  isQuotation(): boolean {
    return this.getServiceType() == "priceCalculator";
  }

  //added for other services

  getOtherService(): string {
    return this.datiPreventivo.otherService;
  }

  setOtherService(os: string) {
    this.datiPreventivo.otherService = os;
  }

  getSplitSelection(): string {
    return this.datiPreventivo.splitSelected;
  }

  setSplitSelection(ss: string) {
    this.datiPreventivo.splitSelected = ss;
  }

  //added for installation BTU

  getBTU(): string {
    return this.datiPreventivo.btuSelected;
  }

  setBTU(btu: string) {
    this.datiPreventivo.btuSelected = btu;
  }

  getInstallationHeight(): string {
    return this.datiPreventivo.installtionHeightSelected;
  }

  setInstallationHeight(ih: string) {
    this.datiPreventivo.installtionHeightSelected = ih;
  }

  getIdInstallation(): string {
    return this.datiPreventivo.idInsatllation;
  }

  setIdInstallation(idi: string) {
    this.datiPreventivo.idInsatllation = idi;
  }
  getEstimationId(): number {
    return this.webSiteFlowTracking;
  }
  setEstimationId(webSiteFlowTracking: number) {
    this.webSiteFlowTracking = webSiteFlowTracking;
  }

  getEstimationFvId(): number {
    return this.webSiteFlowTracking;
  }
  setEstimationFvId(webSiteFlowTracking: number) {
    this.webSiteFlowTracking = webSiteFlowTracking;
  }

  //Calendar Appointment
  getAppointmentDate(): string {
    return this.datiPreventivo.appointmentDate;
  }

  setAppointmentDate(ad: string) {
    this.datiPreventivo.appointmentDate = ad;
  }

  getAppointmentDateFv(): string {
    return this.datiPreventivo.appointmentDateFv;
  }

  setAppointmentDateFv(adfv: string) {
    this.datiPreventivo.appointmentDateFv = adfv;
  }
  getInstallationDraw():UploadedFile {
    return this.datiPreventivo.installationDraw;
  }

  setInstallationDraw(installationDraw : UploadedFile) {
    this.datiPreventivo.installationDraw = installationDraw;
  }

  isSonOfDigitalInspection() {
    return this.datiPreventivo.sonOfDigitalInspection
  }

  setSonOfDigitalInspection(sonOfDigitalInspection : boolean) {
    this.datiPreventivo.sonOfDigitalInspection = sonOfDigitalInspection;
  }

  shouldAsksForLavaggio() : boolean {
    return this.condizionatoreInstallatoMnemonic === "noPrevPlantInstalled"
      && this.predisposizioneMnemonic === "predispositionExist"
  }

  setCondizionatoreInstallatoMnemonic(condizionatoreInstallatoMnemonic: any) {
    this.condizionatoreInstallatoMnemonic = condizionatoreInstallatoMnemonic;
  }

  setPredisposizioneMnemonic(predisposizioneMnemonic: any) {
    this.predisposizioneMnemonic = predisposizioneMnemonic;
  }

  setSealedPlant(sealedPlant: any) {
    this.datiPreventivo.sealedPlant = sealedPlant;
  }

  getSealedPlant(): string {
    return this.datiPreventivo.sealedPlant;
  }

  setConsulenzaEnerg(consEnerg: any) {
    this.datiPreventivo.ConsEnerg = consEnerg;

  }
  getConsulenzEnerg(): string  {
    return this.datiPreventivo.ConsEnerg;
  }
  setContatorekw(kwdata: any) {
    this.datiPreventivo.KwData = kwdata;
  }
  getContatoreKw() {
    return this.datiPreventivo.KwData;
  }
  setAuthCondo(authcondo: any) {
    this.datiPreventivo.authCondo = authcondo;
  }
  getAuthCondo(): string  {
    return this.datiPreventivo.authCondo;
  }
  setAutoElettrica(autoel: any) {
    this.datiPreventivo.autoElettrica = autoel;
  }
  getAutoElettrica(): string  {
    return this.datiPreventivo.autoElettrica;
  }
  setCoperturaParete(copPar: any) {
    this.datiPreventivo.coperturaParete = copPar;
  }
  getCoperturaParete(): string  {
    return this.datiPreventivo.coperturaParete;
  }
  setDimensioniTettoSelected(dim: any) {
    this.datiPreventivo.dimSelected = dim;
  }
  getDimensioniTettoSelected(): string  {
    return this.datiPreventivo.dimSelected;
  }

  getIdDimensioni(): string  {
    return this.datiPreventivo.idDimension;
  }

  setIdDimensioni(idi: any) {
    this.datiPreventivo.idDimension = idi;
  }
  setFasciaOraria(now: any) {
    this.datiPreventivo.fasciaOraria = now;
  }
  getFasciaOraria(): string  {
    return this.datiPreventivo.fasciaOraria;
  }
  setFasciaOrariaMnemonic(fasciaoraria: any) {
    this.fasciaOrariaMnemonic = fasciaoraria;
  }
  getFasciaOrariaMnemonic(): any {
    return this.fasciaOrariaMnemonic;
  }
  setIdFasciaOraria(id: any) {
    this.datiPreventivo.idFasciaOraria = id;
  }
  getIdFasciaOraria() {
    return this.datiPreventivo.idFasciaOraria;
  }
  setStrutturaTetto(stTetto: any) {
    this.datiPreventivo.strutturaTetto = stTetto;
  }
  getStrutturaTetto(): string  {
    return this.datiPreventivo.strutturaTetto;
  }
  setZonaAbitazione(zona: any) {
    this.datiPreventivo.zonaAbitazione = zona;
  }
  getZonaAbitazione(): string {
    return this.datiPreventivo.zonaAbitazione;
  }
  setPompaCalore(pompa: any) {
    this.datiPreventivo.pompaCalore = pompa;
  }
  getPompaCalore(): string {
    return this.datiPreventivo.pompaCalore;
  }
  setPiastraInduzione(piastra: any) {
    this.datiPreventivo.piastraInduzione = piastra;
  }
  getPiastraInduzione(): string {
   return  this.datiPreventivo.piastraInduzione;
  }
  setClimatizzatoreInst(clima: any) {
    this.datiPreventivo.climatizzatoreInst = clima;
  }
  setIdContatorekw(idkw: any) {
    this.datiPreventivo.idContatorekw = idkw;
  }
  getIdContatorekw(): any {
    return this.datiPreventivo.idContatorekw;
  }
  getClimatizzatoreInst(): string {
    return this.datiPreventivo.climatizzatoreInst;
  }
  setIdKoPrevFv(idWebsite: any) {;
    return this.datiPreventivo.koprevfv = idWebsite
  }
  getIdKoPrevFv(): string {
    return this.datiPreventivo.koprevfv;
  }
  setSrvcTypefv(srvtp: any) {
    this.datiPreventivo.serviceTypeFv = srvtp;
  }
  getSrvcTypefv(): string {
    return this.datiPreventivo.serviceTypeFv;
  }
}
