import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-shuko",
  templateUrl: "./shuko.component.html",
  styleUrls: ["./shuko.component.css"]
})
export class ShukoComponent implements OnInit {
  optionShuko: string = "";
  optionoPredispozisione: string = "";
  optionPreventivo: string = "";
  public albums: Array<IAlbum>;
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelSI: string = "";
  public ResponseLabelNO: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdNO: string = "";
  public Question: string = "";
  public presaElettricaNB: string = null;

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.optionoPredispozisione = this.preventivoService.getPredispozisione();

    this.albums = [];

    var src = "../assets/images/3DSHUKO-L.jpg";
    var caption = "";
    var thumb = "../assets/images/3DSHUKO-S.jpg";
    var album = {
      src: src,
      caption: "PRESA ELETTRICA",
      thumb: thumb
    };

    this.albums.push(album);
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  ngOnInit() {

    //console.log("inside ngOnInIt")

   // console.log("getShuko", this.preventivoService.getShuko())
    if (this.preventivoService.getShuko()) {

      this.optionShuko = this.preventivoService.getShuko();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
   // console.log("inside socket ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService.GetQuestionResponseList("presaElettrica").subscribe(data => {
        (this.QuestionResponseList = data),
          (this.Question = this.QuestionResponseList[0].questionLabel),
          (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
          (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
          (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
          (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
          //console.log("xxxx", this.QuestionResponseList);
        this.loading = false;
        this.hidePage = false;
        this.hideLoader = true;
      }
        , err => {

          //console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });

        this.presaElettricaNB = null;
        this.climaSmartService.GetTranslation('PRESA_ELETTRICA_NB').subscribe(data => {
          this.presaElettricaNB = data.translation;
        }, err => {
          if (err.status === 404) {
            this.presaElettricaNB = null;
          }
        });


    } catch {
    //  console.log("inside catch");
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

  //  console.log("isChecked value", selected)

    if (selected == this.preventivoService.getShuko()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  //event andler
  radioChangeHandler(event: any) {
    this.optionShuko = event.target.value;
    //console.log("Shuko : ", this.optionShuko);
  }

  setShuko() {
   // console.log("inside setShuko", this.preventivoService.getFirstQuestion())
    this.preventivoService.setShuko(this.optionShuko);
   // this.router.navigate(["/datiCliente"]);
    // var nextRoute: string = "/datiSopralluogo";

    // if (this.preventivoService.getFirstQuestion() == "50" )
    //  {
    //    //nextRoute="/datiPreventivo";
    //    nextRoute = "/datiInstallazione";
    // }

    // this.router.navigate([nextRoute]);

    const nextRoute = this.QuestionResponseList[0].pageNameNext;
    this.router.navigate([nextRoute]);
  }

  // backClicked() {

  //   console.log("inside back shuko");
  //   this._location.back();
  // };

  goBack() {
    this.preventivoService.setShuko('');
    this._location.back();
  }
}
