import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { FormBuilder } from '@angular/forms';
import { UcChoice } from '../_models/uc-choice';
import { Router } from '@angular/router';
import { PreventivoService } from '../preventivo.service';
import { ClimaSmartService } from '../_service/clima-smart.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-auto-elettrica',
  templateUrl: './auto-elettrica.component.html',
  styleUrls: ['./auto-elettrica.component.css']
})
export class AutoElettricaComponent implements OnInit {
  optionConditioner: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelNO: string = "";
  public ResponseLabelSI: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdNO: string = "";
  public Question: string = "";
  answer: any

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  ngOnInit() {
    if (this.preventivoService.getAutoElettrica()) {
      this.optionConditioner = this.preventivoService.getAutoElettrica();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("AutoElettrica")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[1].responseLabel),
            (this.ResponseIdSI = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdNO = this.QuestionResponseList[1].idWebSiteFlow),
            this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
          , err => {
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();

              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });
    } catch {
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }


  isChecked(selected: any) {

    if (selected == this.preventivoService.getAutoElettrica()) {
      return true
    }

    return false
  }


  //event andler
  radioChangeHandler(event: any) {
    this.optionConditioner = event.target.value;
    this.answer = this.QuestionResponseList.find(qr => qr.idWebSiteFlow.toString() === this.optionConditioner)
  }

    confermaAutoElettrica() {
      this.preventivoService.setAutoElettrica(this.optionConditioner);
      var nextRoute: string = this.QuestionResponseList[0].pageNameNext;
    if (this.optionConditioner == this.ResponseIdSI || this.optionConditioner == this.ResponseIdNO) {
      nextRoute = this.QuestionResponseList[1].pageNameNext;
      // this.router.navigate(["/PreventivoFotovoltaico"]);

    }
      this.router.navigate([nextRoute]);

  }

  goBack() {
    this.preventivoService.setAutoElettrica('');
    this._location.back();
  }
}

