import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClientData, PriceCalc, ResponsePCalc, SopralluogoData, CustomerData, PriceEstimationCalc, Contatorekwdata } from "../_models/models";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { Parameters } from "../_service/parameters";
import { ShopDetails } from "../_models/models";
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
@Component({
  selector: 'app-inserimento-contatorekw',
  templateUrl: './inserimento-contatorekw.component.html',
  styleUrls: ['./inserimento-contatorekw.component.css']
})
export class InserimentoContatorekwComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  inputContatoreKw: string = "";

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;

  question: string = '';
  data: any[] = [];
  inspectionCodes: any;
  currentDataRecord: any;
  previous: string | null = null;
  public contatoreWR: string = null;

  public ContatoreForm: FormGroup;
  public contatorekw: Contatorekwdata = new Contatorekwdata()

  public QuestionResponseList: Array<any>;
  public Question: string = '';
  public ResponseId: string = '';
  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  ngOnInit() {

    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("InserimentoContatorekw")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseId = this.QuestionResponseList[0].idWebSiteFlow),


            this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });

      this.contatoreWR = null;
      this.climaSmartService.GetTranslation('CONTATORE_WR').subscribe(data => {
        this.contatoreWR = data.translation;
      }, err => {
        if (err.status === 404) {
          this.contatoreWR = null;
        }
      });

    } catch {
      console.exception("input error ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }

    this.ContatoreForm = this._fb.group({
      ContatoreFormGroup: this._fb.group({
        datikw: ["", [Validators.required, this.validateNumber]]
      })
    });
  }

  validateNumber(control) {
    const value = control.value;
    if (isNaN(value)) {
      return { invalidNumber: true };
    }
    return null;
  }
  isFormValid(): boolean {
    const kwDataValue = parseFloat(this.contatorekw.kwData);
    if (!isNaN(kwDataValue)) {
      return true;
    }
    return false;
  }

  setContatorekw() {
    this.preventivoService.setContatorekw(this.contatorekw.kwData);
    this.preventivoService.setIdContatorekw(this.ResponseId);
    if (this.contatorekw.kwData != '') {
      this.router.navigate(['/FasciaOrariaConsumo']);
     
    }
  }

  goBack() {
    this.preventivoService.setContatorekw('');
    this._location.back();
  }
}

