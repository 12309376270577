import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import {
    PriceEstimationCalc,
  PriceFvEstimationCalc,
  ResponsePCalc
} from "../_models/models";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { ModalErrorComponent } from "../modal-error/modal-error.component";
@Component({
  selector: 'app-preventivo-fotovoltaico',
  templateUrl: './preventivo-fotovoltaico.component.html',
  styleUrls: ['./preventivo-fotovoltaico.component.css']
})
export class PreventivoFotovoltaicoComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  loading: boolean = false;
  hideLoader: boolean = true;
  hideButtons: boolean = false;

  quotationValue: number = null;
  quotationInProgress: boolean = true;
  webSiteFlowTracking: number;
  isQuotation: boolean;
  fatalError: boolean = false;
  public rataEff: number;
  public rataMens: number;
  public detrIrpef: number;
  public costStimImp: number;
  public prezzoTot: number;
  public codicekw: string = "";
  public pompaCalore: string = "";
  public DimensioniTetto: string = "";
  public TotalePriceTitle: string = "Importo Preventivo Iva Inclusa";
  public error: boolean = false;
  public message: string = "";
  public preventivofvWR: string = null;

  public pFvEstmCalc: PriceFvEstimationCalc = new PriceFvEstimationCalc();

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  get isShowConfig() {
    return this.DimensioniTetto = this.preventivoService.getDimensioniTettoSelected();
  }
  ngOnInit() {
    this.estimateFotovol();

    this.preventivofvWR = null;
    this.climaSmartService.GetTranslation('PREVENTIVO_WR').subscribe(data => {
      this.preventivofvWR = data.translation;
    }, err => {
      if (err.status === 404) {
        this.preventivofvWR = null;
      }
    });
  }

  estimateFotovol() {
    let arrResponse = this.getResponseArray();

    let data: PriceFvEstimationCalc = {
      responseList: arrResponse,
      idLogin: this.preventivoService.getLoginCliente()
        ? this.preventivoService.getLoginCliente()
        : 1,
      dimTetto: this.preventivoService.getDimensioniTettoSelected()
    };

    this.quotationInProgress = true;
    this.hideLoader = false;
    this.loading = true;
    this.fatalError = false;
    return this.climaSmartService.getEstimationFotovol(data).subscribe(
      (d) => {
        this.message = d.message;
        this.error = d.message != "OK";
        this.quotationInProgress = false;
        this.hideLoader = true;
        this.loading = false;
        this.codicekw = d.codicekw;
        this.detrIrpef = +d.detrIrpef;
        this.costStimImp = +d.costStimImp;
        this.quotationValue = +d.prezzoTot;
        this.webSiteFlowTracking = d.webSiteFlowTracking;
        this.preventivoService.setIdWebSiteTrackingFlow(d.webSiteFlowTracking);

        this.prezzoTot = +d.prezzoTot;
        
      },
      (e) => {
        this.message = "Si è verificato un errore imprevisto";
        this.fatalError = true;
        this.error = true;
        this.quotationInProgress = false;
        this.hideLoader = true;
        this.loading = false;
        this.handleStandardError(e);
      }
    );


  }

  goToDatiCliente() {

    this.router.navigate(["/DatiClientefv"]);
    this.preventivoService.setSrvcTypefv('SOLAR INSPECTION');
    
  }

  goToCalendar() {
    
    this.router.navigate(['/calendariofv'], { queryParams: { overrideNextPageName: 'DatiClientefv' } });
    this.preventivoService.setSrvcTypefv('SOLAR REMOTE INSPECTION');
  }

  getResponseArray() {

    let arrResponse: Array<ResponsePCalc> = [];

    if (this.preventivoService.getFasciaOraria() != '' &&
      this.preventivoService.getFasciaOraria() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getFasciaOraria(),
        responseValue: null,
      };

      arrResponse.push(resp);


    }

    var resp: ResponsePCalc = {
      idWebSiteFlow:
        +this.preventivoService.getPackage(),
      responseValue: null,


    };
    arrResponse.push(resp);
    if (this.preventivoService.getPompaCalore() != '' &&
      this.preventivoService.getPompaCalore() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getPompaCalore(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getPiastraInduzione() != '' &&
      this.preventivoService.getPiastraInduzione() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getPiastraInduzione(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getAutoElettrica() != '' &&
      this.preventivoService.getAutoElettrica() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getAutoElettrica(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getConsulenzEnerg() != '' &&
      this.preventivoService.getConsulenzEnerg() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getConsulenzEnerg(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getAuthCondo() != '' &&
      this.preventivoService.getAuthCondo() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getAuthCondo(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getCoperturaParete() != '' &&
      this.preventivoService.getCoperturaParete() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getCoperturaParete(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getZonaAbitazione() != '' &&
      this.preventivoService.getZonaAbitazione() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getZonaAbitazione(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getStrutturaTetto() != '' &&
      this.preventivoService.getStrutturaTetto() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getStrutturaTetto(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getIdContatorekw() != '' &&
      this.preventivoService.getIdContatorekw() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getIdContatorekw(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getClimatizzatoreInst() != '' &&
      this.preventivoService.getClimatizzatoreInst() != null
    ) {

      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getClimatizzatoreInst(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    var resp: ResponsePCalc = {
      idWebSiteFlow:
        +this.preventivoService.getIdDimensioni(),
      responseValue: this.preventivoService.getDimensioniTettoSelected()
    };
    arrResponse.push(resp);



    return arrResponse;
  }

  goBack() {
    this.router.navigate(["/KoPreventivoFv"]);
  }

  private handleStandardError(e: any) {
    if (e.status === 401) {
      this.climaSmartService.logoutAndRedirect();
      this.hideLoader = true;
      this.loading = false;
    } else {
      this.modalError.show({
        errorTitle: "Errore",
        errorDetail:
          "Si è verificato un errore imprevisto, la invitiamo a riprovare",
      });
      this.hideLoader = true;
      this.loading = false;
      this.hideButtons = false;
    }
  }
}
