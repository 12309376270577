import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { FormBuilder } from '@angular/forms';
import { UcChoice } from '../_models/uc-choice';
import { Router } from '@angular/router';
import { PreventivoService } from '../preventivo.service';
import { ClimaSmartService } from '../_service/clima-smart.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-consulenza-energetica-pv',
  templateUrl: './consulenza-energetica-pv.component.html',
  styleUrls: ['./consulenza-energetica-pv.component.css']
})
export class ConsulenzaEnergeticaPvComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;

  maxAllowedQuotations: number = 0;
  remainingQuotations: number = null;

  question: string = '';
  answers: UcChoice[] = [];
  currentAnswer: UcChoice | null = null;
  data: any[] = [];
  inspectionCodes: any;
  currentDataRecord: any;
  previous: string | null = null;
  pack: any = '';
  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private location: Location,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;

      this.climaSmartService
        .GetQuestionResponseList('ConsulenzaEnergeticaPv')
        .subscribe(
          (data) => {
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;

            this.data = data;
            this.question = this.data[0].questionLabel;

            const qArray: UcChoice[] = [];
            for (const q of this.data) {
              qArray.push({ label: q.responseLabel, id: q.idWebSiteFlow.toString() });
            }

            this.answers = qArray;
            if (this.preventivoService.getPackage()) {
              this.previous = this.preventivoService.getPackage();
            }
          },
          (err) => {
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          }
        );
    } catch {
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  onChoiceChanged($event: UcChoice) {
    this.currentAnswer = this.answers.find((a) => $event && a.id === $event.id);
    this.currentDataRecord = this.data.find(
      (d) => this.currentAnswer && d.idWebSiteFlow.toString() === this.currentAnswer.id
    );

    console.log('currentAnswer', this.currentAnswer);
    console.log('currentDataRecord', this.currentDataRecord);
  }

  isFormValid() {
    return !!this.currentDataRecord;
  }
 
  setAnswer() {
    
    this.preventivoService.setConsulenzaEnerg(this.currentAnswer.id);
    const nextRoute = this.currentDataRecord.pageNameNext;
    if (!nextRoute) {
      return;
    }

    this.router.navigate([nextRoute]);
  }

  goBack() {
    this.preventivoService.setConsulenzaEnerg('');
    this.preventivoService.setPackage('');
    this.location.back();
  }
}
