import { Component, OnInit , ViewChild} from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { ChangePassword, ShopDetails } from "../_models/models";
import { Router, ActivatedRoute } from "@angular/router";
import { PasswordValidators } from "../_validators/password/password-validators";
import { PasswordConfirmValidators } from "../_validators/password-confirm/password-confirm-validators";
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from '../modal-error/modal-error.component';

import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-miei-dati",
  templateUrl: "./miei-dati.component.html",
  styleUrls: ["./miei-dati.component.css"]
})
export class MieiDatiComponent implements OnInit {
  @ViewChild(ModalConfirmComponent) public modalConfirm: ModalConfirmComponent;
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;
  public ShopForm: FormGroup;
  public DatiForm: FormGroup;
  public PasswordForm: FormGroup;
  public passwordForm: ChangePassword = new ChangePassword();
  public shopDetails: ShopDetails = new ShopDetails();

  public MieiDatiList: Array<any>;
  //public ShopName: string = "";
  //public Telephone: string = "";
  //public Email: string = "";

  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  loadingConFrimNewPass: boolean = false;
  hideLoaderConFrimNewPass: boolean = true;

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private climaSmartService: ClimaSmartService
  ) {}

  ngOnInit() {
    this.ShopForm = this._fb.group({
      shopGroup: this._fb.group({
        shopName: [""]
      })
    });

    this.DatiForm = this._fb.group({
      confirmData: this._fb.group({
        telephone: ["", [Validators.required]],
        email: ["", [Validators.required]]
      })
    });

    this.PasswordForm = this._fb.group({
      changePassword: this._fb.group({
        oldPassword: ["", [Validators.required]],
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            PasswordValidators.isPassword
          ]
        ],
        newPasswordConfirm: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            PasswordConfirmValidators.isPasswordConfirm
          ]
        ]
      })
    });

    //console.log("inside miei dati ngOnInit");
    try {
      this.climaSmartService.GetShopDetails().subscribe(data => {
        (this.shopDetails = data),
          //(this.ShopName = this.shopDetails[0].shopName),
          //(this.Telephone = this.shopDetails[0].telephone),
          //(this.Email = this.shopDetails[0].email),
          //console.log("xxxx", this.shopDetails);
        this.loading = false;
        this.hidePage = false;
        this.hideLoader = true;
      }

        , err => {

         // console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
     // console.log("inside catch");
      //console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }

  }

  ChangePassword() {

    this.loadingConFrimNewPass = true;
    this.hideLoaderConFrimNewPass = false;

    try {
      //console.debug(
      //  "ChangePassword oldPassword " +
      //    this.PasswordForm.get("changePassword").get("oldPassword").value
      //);
      //console.debug(
      //  "ChangePassword newPassword " +
      //    this.PasswordForm.get("changePassword").get("newPassword").value
      //);
      //console.debug(
      //  "ChangePassword newPasswordConfirm " +
      //    this.PasswordForm.get("changePassword").get("newPasswordConfirm")
      //      .value
      //);

      if (
        this.PasswordForm.get("changePassword").get("newPassword").value ==
        this.PasswordForm.get("changePassword").get("newPasswordConfirm").value
      ) {
        this.passwordForm.newPassword = this.PasswordForm.get(
          "changePassword"
        ).get("newPassword").value;
        this.passwordForm.oldPassword = this.PasswordForm.get(
          "changePassword"
        ).get("oldPassword").value;

        this.climaSmartService
          .ChangePassword(
            this.passwordForm.newPassword,
            this.passwordForm.oldPassword
          )
          .subscribe(
          data => {

            this.loadingConFrimNewPass = false;
            this.hideLoaderConFrimNewPass = true;
            //this.loadingConFrimNewPass = false;
            //this.hideLoaderConFrimNewPass = true;
              this.passwordForm = new ChangePassword();
              if (data.message != "OK") {
                //console.log("ChangePassword KO=" + data.message);
                //alert(data.message);
                 this.modalError.show({
                   errorTitle: "Errore",
                   errorDetail: data.message
                 });
                this.PasswordForm.reset();
              } else {
                //console.log("ChangePassword OK=" + data);
                // alert('Password modificata correttamente');
                this.modalConfirm.show({
                   title: "Conferma Nuova password",
                   details: "Password modificata correttamente"
                 });
                this.PasswordForm.reset();
              }
            },
            err => {
              //console.error("ChangePassword Error ", err);
              if (err.status === 401) {
                this.climaSmartService.logout();
                // this.router.navigate(["/login"]);
                this.router.navigate(["/login"], {
                  queryParams: { redirectURL: this.router.url }
                });
              }
            }
          );
      } else {
        //alert('La password di conferma non coincide')
         this.modalError.show({
           errorTitle: "Errore",
           errorDetail: "La password di conferma non coincide"
         });
      }
    } catch (ex) {
      //console.exception("ChangePassword Exception " + ex);
    }
  }

  //isEmailTelephoneValid() {
  //  console.log(this.shopDetails.mail);
  //  //console.log(this.shopDetails.telephone);

  //  if (this.shopDetails.mail || this.shopDetails.telephone) {
  //    //console.log("inside if")
  //    return this.DatiForm.valid && true;
  //  }
  //  return this.DatiForm.valid && false;
  //}

  salvaShopDetails() {
    //console.log("inside salva shop details");
  }
}
