import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UcChoice } from "../_models/uc-choice";

@Component({
  selector: "app-uc-single-choice",
  templateUrl: "./uc-single-choice.component.html",
  styleUrls: ["./uc-single-choice.component.css"],
})
export class UcSingleChoiceComponent implements OnInit {
  internalChoices: UcChoice[] = [];

  /**
   * Question text to show
   */
  @Input()
  question!: string;

  @Input()
  labelClass?: string[];

  @Input()
  isAligned?: boolean;

  classCustom: string;
  

  /**
   * Possible choices
   */
  @Input()
  set choices(value: UcChoice[]) {
    this.internalChoices = value;
    if (this.internalChoices && this.internalChoices.length === 1) {
      this.setAndRaiseChoice(this.internalChoices[0].id);
    }
  }

  @Input()
  set selectedChoice(id: string | null) {
    this.setAndRaiseChoice(id);
  }

  /**
   * Raised when selected choice changes
   */
  @Output()
  readonly choiceChanged: EventEmitter<UcChoice> = new EventEmitter<UcChoice>();

  /**
   * Current selected id
   */
  selectedId: string | null = null;

  constructor() {}

  ngOnInit() { this.classCustom = this.isAligned ? 'col-lg-12 col-md-12 col-sm-12' : 'col-md-12 col-sm-12'; console.log("classe nuova", this.classCustom); console.log("ISALIGNED", this.isAligned) }

  isChecked(id: string): boolean {
    return this.selectedId === id;
  }

  onChoiceChanged($event: any) {
    this.setAndRaiseChoice($event.target.value);
  }

  optionClass(index: number) {
    const classes = [
      "new-radiobutton-blue",
      "new-radiobutton-orange",
      "new-radiobutton-green",
      "new-radiobutton-light-blue",
    ];

    return classes[index % classes.length];

  
  }

  optionParentClass(index: number) {
    const classes = [

      "new-blue label-visible",
      "new-orange label-visible",
      "new-green label-visible",
      "new-light-blue label-visible",
    ];

    console.log(this.labelClass);
    if (this.labelClass && this.labelClass[index]) {
      return this.labelClass[index];
    }

    

    return classes[index % classes.length];
  }

  private raiseChoiceChanged() {
    console.log("internalChoices:", this.internalChoices);
    console.log("selectedId:", this.selectedId);
    this.choiceChanged.emit(
      this.internalChoices.find((c) => c.id === this.selectedId)
    );
  }

  private setAndRaiseChoice(value: string) {
    this.selectedId = value;
    this.raiseChoiceChanged();
  }
}
