import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Login, ResetPassword } from "../_models/models";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ClimaSmartService } from "../_service/clima-smart.service";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  loadingReset: boolean = false;

  public showMessage: boolean = false;
  public loginForm: FormGroup;
  public resetPasswordForm: FormGroup;

  public username: string = "";
  public password: string = "";
  public message: string = "";
  public usernameReset: string = "";
  public email: string = "";

  public resetPassword: ResetPassword = new ResetPassword();

  @ViewChild("childModal") public childModal: ModalDirective;
  modal: Login = new Login();

  hidePassword: boolean = true;
  hideError: boolean = true;
  errorMessage: string = '';

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private climaSmartService: ClimaSmartService
  ) {}

  ngOnInit() {
    this.loginForm = this._fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]]
    });

    this.resetPasswordForm = this._fb.group({
      usernameReset: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(emailRegex)]]
    });
  }

  public show(modal: Login) {
    this.modal = modal;
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
    this.hidePassword = true;
    this.showMessage = false;
    this.usernameReset = "";
    this.email = "";
  }

  // login() {
  //   console.log("inside login button");

  //   this.router.navigate(["/miei-dati"]);
  // }

  forget() {
    //console.log("inside forget password");
    this.hidePassword = !this.hidePassword;
    // this.loadingReset = true;
  }

  onSubmitLogin(username, password) {
    this.loading = true;
    //console.log("onSubmitLogin");
    //console.log("onSubmitLogin username", username);
   // console.log("onSubmitLogin password", password);
    try {
      this.climaSmartService.loginAccess(username, password).subscribe(
        data => {
         // console.log("onSubmitLogin data" + data);
          localStorage.setItem("userToken", data.token);
          this.loading = false;
          this.username = "";
          this.password = "";
          this.loginForm.reset();
          this.hideError = true;

         //console.log("onSubmitLogin username password", this.username, this.password)

          if (this.activatedRoute.snapshot.queryParams.redirectURL) {

            this.router.navigate([
              this.activatedRoute.snapshot.queryParams.redirectURL
            ]);
          } else {

            this.router.navigate(["/ricerca"]);
            this.hide();
          }
        },

        err => {
         // console.log("inside err")
          //console.error("onSubmitLogin", err);
          if (err.status === 401) {
            this.loading = false;
            this.hideError = false;
          }

          if (err.error && err.error.errorMessage) {
            this.errorMessage = err.error.errorMessage;
          } else {
            this.errorMessage = 'Username o Password non corretti';
          }


          this.hideError = false;
        }
      );
    } catch (ex) {
      //console.exception("onSubmitLogin Exception " + ex);
      this.loading = false;
      this.hideError = false;
    }
  }

  resetPasswordConfirm() {
   // console.log("reset password confirm");

    this.loadingReset = true;

    try {
      //console.log("username", this.resetPassword.usernameReset);
      //console.log("email", this.resetPassword.email);
      this.climaSmartService
        .resetPassword(
          this.resetPassword.usernameReset,
          this.resetPassword.email
        )
        .subscribe(
          data => {
            this.showMessage = true;
            if (data) {
              if (data.isChanged) {
                this.message = "mail sent";
                this.loadingReset = false;
              } else {
                //console.error(
                //  "resetPasswordConfirm data.message: ",
                //  data.message
                //);
                this.message = data.message;
                this.loadingReset = false;
              }
            } else {
              //console.error(
              //  "resetPasswordConfirm somethings goes wrong! data "
              //);
              this.message = "Somethings goes wrong!";
              this.loadingReset = false;
            }
          },
        err => {
            //console.error("ResetPassword Error ", err);
            if (err.status === 401) {
              this.router.navigate(["/login"]);
              this.loading = false;
            }
          }
        );
    } catch (ex) {
     // console.exception("resetPassword Exception " + ex);
      this.loading = false;
    }
  }
}
