import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ClimaSmartService } from '../_service/clima-smart.service';

@Component({
  selector: 'app-modal-vendor-reference',
  templateUrl: './modal-vendor-reference.component.html',
  styleUrls: ['./modal-vendor-reference.component.css']
})
export class ModalVendorReferenceComponent implements OnInit {

  @ViewChild('childModal') public childModal: ModalDirective;
  @Output() refreshTable = new EventEmitter<string>();

  vendorReferenceForm: FormGroup;
  hidePage: boolean = true;
  loadingPage: boolean = false;
  loading: boolean = false;

  vendorReference: string = '';
  ticket: string = '';
  idWebSiteFlowTracking: number ;
  showMessage: boolean = false;
  showSubmitButton: boolean = true;


  constructor(private _fb: FormBuilder, private climaSmartService: ClimaSmartService) { }

  ngOnInit() {
    console.log('ModalVendorRefrenceComponent');
    this.vendorReferenceForm = this._fb.group({
      vendorReference: ['', [Validators.required]]
    });
  }


  callResearchPage() {
    console.log('callResearchPage');
    this.refreshTable.emit('refreshTable');
  }


  show(idWebSiteFlowTracking, ticket ) {
    console.log('show modal vendor refrence', idWebSiteFlowTracking, ticket  );
    this.showSubmitButton = true;
    this.ticket = ticket;
    this.idWebSiteFlowTracking = idWebSiteFlowTracking;
    this.showMessage = false;
    this.childModal.show();
    this.vendorReferenceForm.reset();
  }

  hide() {
    console.log('hide');

    this.vendorReferenceForm.reset();
    this.showMessage = false;
    this.childModal.hide();
  }


  onSubmitSaveVendorRefrence() {

    const vendorReferences = {
      sellVendorReference: this.vendorReference,
      idWebSiteFlowTracking : this.idWebSiteFlowTracking,
    };
    console.log('onSubmit',  vendorReferences);
    this.loading = true;

    try {
      this.climaSmartService
        .saveVendorReference(vendorReferences)
        .subscribe(data => {
            if (data) {
              console.log('data', data);
              this.showMessage = true;
              this.loading = false;
              this.showSubmitButton = false;
              this.hide();
              this.callResearchPage();
            }
            else {
            }
          },
        err => {
              this.loading = false;
          }
        );
    } catch (ex) {

      this.loading = false;
    }
  }

}
