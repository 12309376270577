import { Component, OnInit, Input } from '@angular/core';
import { AdditionalWork } from '../_models/models';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { isNumber } from 'util';

@Component({
  selector: 'app-additional-work',
  templateUrl: './additional-work.component.html',
  styleUrls: ['./additional-work.component.css']
})
export class AdditionalWorkComponent implements OnInit {

  private _additionalWork: AdditionalWork = null;

  loading: boolean = true;
  hideLoader: boolean = false;
  hidePage: boolean = true;

  form: FormGroup = null;

  constructor(private fb: FormBuilder) { }

  get showQuantity() {
    return !!this.additionalWork
      && this.additionalWork.editableQuantity;
  }

  get additionalWork(): AdditionalWork {
    return this._additionalWork;
  }

  @Input()
  set additionalWork(val: AdditionalWork) {
    this._additionalWork = val;
    this.refreshForm(val);
  }

  get placeholder(): string {
    if (this.additionalWork) {
      return this.additionalWork.shortDescription;
    }

    return '';
  }

  get total() {
    return this.value.value * this.quantity.value;
  }

  get description(): AbstractControl {
    return this.getFormComponent('description');
  }

  get quantity(): AbstractControl {
    return this.getFormComponent('quantity');
  }

  get value(): AbstractControl {
    return this.getFormComponent('value');
  }

  ngOnInit() {

    this.form = this.fb.group({

      description: ['', []],
      quantity: ['', [Validators.min(0)]],
      value: ['', [Validators.min(0)]]

    }, { validator: AdditionalWorkValidator });

    this.refreshForm(this.additionalWork);
    this.description.valueChanges.subscribe(d => this._additionalWork.description = d);
    this.quantity.valueChanges.subscribe(q => this._additionalWork.quantity = q);
    this.value.valueChanges.subscribe(v => this._additionalWork.value = v);
  }

  incrementQuantity() {
    let value = this.quantity.value;
    if (!isNumber(value)) {
      value = -1;
    }

    this.quantity.setValue(value + 1);
  }

  decrementQuantity() {
    let value = this.quantity.value;

    if (!isNumber(value) || value < 0) {
      value = 1;
    }

    if (value === 0) {
      return;
    }

    this.quantity.setValue(value - 1);
  }

  private getFormComponent(name: string) {
    if (!this.form) {
      return null;
    }

    return this.form.get(name);
  }

  private updateFormComponent(control: AbstractControl, value: any, state: boolean, onEnableSet: boolean) {

    if (!control) {
      return;
    }

    if (!state || onEnableSet) {
      control.setValue(value);
    }

    if (state) {
      control.enable();
    } else {
      control.disable();
    }
  }

  private refreshForm(additionalWork: AdditionalWork) {
    
    if (additionalWork == null) {
      this.description.reset();
      this.quantity.reset();
      this.value.reset();
      return;
    }

    this.updateFormComponent(
      this.description,
      additionalWork.description,
      additionalWork.editableDescription,
      false);

    this.updateFormComponent(
      this.quantity,
      additionalWork.quantity,
      additionalWork.editableQuantity,
      true);

    this.updateFormComponent(
      this.value,
      additionalWork.value,
      additionalWork.editablePrice,
      false);

      this.hidePage = false
      this.loading = false
      this.hideLoader = true
  }

}

export const AdditionalWorkValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  const description = control.get('description');
  const value = control.get('value');
  const quantity = control.get('quantity');

  let descriptionValue = description.value;
  let valueValue = value.value;
  let quantityValue = quantity.value;

  let descriptionIsSet = descriptionValue && descriptionValue !== "" && descriptionValue.trim() !== "";
  let descriptionIsSettable = description.enabled;

  let valueIsSet = valueValue && valueValue > 0;
  let valueIsSettable = value.enabled;

  let quantityIsSet = quantityValue && quantityValue > 0;
  let quantityIsSetable = quantity.enabled;

  if (descriptionIsSettable) {
    if (!descriptionIsSet && ((valueIsSettable && valueIsSet) || (quantityIsSetable && quantityIsSet))) {
      return { descriptionRequired: true };
    }
  }

  if (valueIsSettable) {
    if (!valueIsSet && ((descriptionIsSettable && descriptionIsSet) || (quantityIsSetable && quantityIsSet))) {
      return { valueRequired: true };
    }
  }

  if (quantityIsSetable) {
    if (!quantityIsSet && ((descriptionIsSettable && descriptionIsSet) || (valueIsSettable && valueIsSet))) {
      return { quantityRequired: true };
    }
  }

  return null;

};
