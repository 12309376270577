import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import * as jspdf from "jspdf";
import * as html2canvas from "html2canvas";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { Renderer } from '@angular/core';
import { Parameters } from "../_service/parameters";
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { environment } from "src/environments/environment"

@Component({
  selector: "app-preventivo",
  templateUrl: "./preventivo.component.html",
  styleUrls: ["./preventivo.component.css"]
})
export class PreventivoComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;
  selectedZone: string = "";
  selectedPlan: string = "";
  optionConditioner: string = "";
  optionSmontaggio: string = "";
  optionLavaggio: string = "";
  optionPredisposizione: string = "";
  optionStaffa: string = "";
  optionShuko: string = "";
  distanzaTuboCondensa: number = 0;
  distanzaTuboRame: number = 0;
  distanzaCavoSegnale: number = 0;
  optionProfondita: string = "";
  optionStessaStaffa: string = "";
  //optionPredispozisioneCondizionatore: string = "";
  distanzaTuboCondensa2: number = 0;
  distanzaTuboRame2: number = 0;
  distanzaCavoSegnale2: number = 0;
  optionPosizione: string = "";

  //added for other services
  optionOtherServices: string = "";
  optionSplitSelected: string = "";

  TotalePriceTitle: string = "Importo Preventivo Imponibile";
  // TotalePriceTitle: string = "Importo Preventivo Scontato Iva Inclusa";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public PreventivoList: Array<any>;
  public item1: Array<any>;
  public FirstName: string = "";
  public LastName: string = "";
  public Mobile: string = "";
  public Phone: string = "";
  public Mail: string = "";
  public TaxCode : string = "";
  public Address: string = "";
  public City: string = "";
  public ZipCode: string = "";
  public Province: string = "";
  public Login: string = "";
  public Pdf: string = "";
  public Total: string = "";
  public SelectedProductName: string = "";

  public DiscountPercentage: string = "";

  public DatiPreventivo: [];

  public Element: string = "";
  public Price: number = 0;
  public Element2: string = "";
  public Price2: number = 0;
  public Element3: string = "";
  public Price3: number = 0;
  public Element4: string = "";
  public Price4: number = 0;

  //prices

  monoNordPrezzoConPredisposizione: number = 249.9;
  monoCentroPrezzoConPredisposizione: number = 199.9;
  monoSudPrezzoConPredisposizione: number = 159.9;

  dualNordPrezzoConPredisposizione: number = 499.9;
  dualCentroPrezzoConPredisposizione: number = 349.9;
  dualSudPrezzoConPredisposizione: number = 299.9;

  trialNordPrezzoConPredisposizione: number = 699.9;
  trialCentroPrezzoConPredisposizione: number = 499.9;
  trialSudPrezzoConPredisposizione: number = 399.9;

  monoNordPrezzoSenzaPredisposizione: number = 319.9;
  monoCentroPrezzoSenzaPredisposizione: number = 249.9;
  monoSudPrezzoSenzaPredisposizione: number = 229.9;

  dualNordPrezzoSenzaPredisposizione: number = 599.9;
  dualCentroPrezzoSenzaPredisposizione: number = 499.99;
  dualSudPrezzoSenzaPredisposizione: number = 399.99;

  trialNordPrezzoSenzaPredisposizione: number = 799.9;
  trialCentroPrezzoSenzaPredisposizione: number = 699.9;
  trialSudPrezzoSenzaPredisposizione: number = 649.9;

  monoNordPrezzo: number = 0;
  dualNordPrezzo: number = 0;
  trialNordPrezzo: number = 0;

  monoCentroPrezzo: number = 0;
  dualCentroPrezzo: number = 0;
  trialCentroPrezzo: number = 0;

  monoSudPrezzo: number = 0;
  dualSudPrezzo: number = 0;
  trialSudPrezzo: number = 0;

  smontaggioMonoPrezzo: number = 60.0;
  smontaggioDualPrezzo: number = 90.0;
  smontaggioTrialPrezzo: number = 120.0;

  lavaggioPrezzo: number = 120.0;

  lavaggioMonoPrezzo: number = 120;
  lavaggioDualPrezzo: number = 150;
  lavaggioTrialPrezzo: number = 0;

  staffaPrezzo: number = 39.0;

  tuboCondesaPrezzo: number = 12.0;
  tuboRamePrezzo: number = 30.0;
  tuboSegnalePrezzo: number = 5.0;

  totale: number = 0;

  //added for other services

  monoSplitPuliziaPrezzo: number = 99.90;
  dualSplitPuliziaPrezzo: number = 129.90;
  trialSplitPuliziaPrezzo: number = 169.90;

  //Dati Cliente

  inputNome: string = "";
  inputCognome: string = "";
  inputTelefono: string = "";
  inputMobile: string = "";
  inputEmail: string = "";
  inputIndirizzo: string = "";
  inputCap: string = "";
  inputCitta: string = "";
  inputProvincia: number = 0;
  inputCF: string = "";

  quotationHeaderOnSite : string = "";
  quotationHeaderOnSiteNotice : string = "";
  quotationHeaderOnSiteDig : string = "";
  quotationHeaderOnSiteDigNotice : string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService, private renderer: Renderer
  ) {
    this.selectedZone = this.preventivoService.getZonaGeografica();
    this.selectedPlan = this.preventivoService.getTipoImpiantoMnemonic();
    this.optionConditioner = this.preventivoService.getCondizionatoreInstallato();
    this.optionSmontaggio = this.preventivoService.getSmontaggio();
    this.optionLavaggio = this.preventivoService.getLavaggio();
    this.optionPredisposizione = this.preventivoService.getPredispozisione();
    this.optionStaffa = this.preventivoService.getStaffa();
    this.optionShuko = this.preventivoService.getShuko();

    this.distanzaTuboCondensa =
      this.preventivoService.getDistanzaMotoreSplit() - 3;
    this.distanzaTuboRame = this.preventivoService.getDistanzaMotoreSplit();
    this.distanzaCavoSegnale = this.preventivoService.getDistanzaMotoreSplit();
    this.optionProfondita = this.preventivoService.getProfonditaPareteMaggioreDi40();
    this.optionStessaStaffa = this.preventivoService.getStesseStaffe();

    this.optionPosizione = this.preventivoService.getPosizione();

    this.distanzaTuboCondensa2 =
      this.preventivoService.getDistanzaPredispozisioneCondizionatore() - 3;
    this.distanzaTuboRame2 = this.preventivoService.getDistanzaPredispozisioneCondizionatore();
    this.distanzaCavoSegnale2 = this.preventivoService.getDistanzaPredispozisioneCondizionatore();

    this.inputNome = this.preventivoService.getNomeCliente();
    this.inputCognome = this.preventivoService.getCognomeCliente();
    this.inputTelefono = this.preventivoService.getTelefonoCliente();
    this.inputMobile = this.preventivoService.getMobileCliente();
    this.inputEmail = this.preventivoService.getEmailCliente();
    this.inputIndirizzo = this.preventivoService.getIndirizzoCliente();
    this.inputCap = this.preventivoService.getCapCliente();
    this.inputCitta = this.preventivoService.getCittaCliente();
    this.inputProvincia = this.preventivoService.getProvinciaCliente();
    this.inputCF = this.preventivoService.getCodiceFiscaleCliente();

    console.log('codice fiscale is :', this.preventivoService.getCodiceFiscaleCliente())

    //added for other services
    this.optionOtherServices = this.preventivoService.getOtherService();
    this.optionSplitSelected = this.preventivoService.getSplitSelection();

    console.log("other services is ", this.optionOtherServices, this.optionSplitSelected)

    this.monoNordPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.monoNordPrezzoConPredisposizione
        : this.monoNordPrezzoSenzaPredisposizione;

    this.dualNordPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.dualNordPrezzoConPredisposizione
        : this.dualNordPrezzoSenzaPredisposizione;

    this.trialNordPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.trialNordPrezzoConPredisposizione
        : this.trialNordPrezzoSenzaPredisposizione;

    this.monoCentroPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.monoCentroPrezzoConPredisposizione
        : this.monoCentroPrezzoSenzaPredisposizione;

    this.dualCentroPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.dualCentroPrezzoConPredisposizione
        : this.dualCentroPrezzoSenzaPredisposizione;

    this.trialCentroPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.trialCentroPrezzoConPredisposizione
        : this.trialCentroPrezzoSenzaPredisposizione;

    this.monoSudPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.monoSudPrezzoConPredisposizione
        : this.monoSudPrezzoSenzaPredisposizione;

    this.dualSudPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.dualSudPrezzoConPredisposizione
        : this.dualSudPrezzoSenzaPredisposizione;

    this.trialSudPrezzo =
      this.optionPredisposizione == this.preventivoService.getSI() ||
        this.optionConditioner == this.preventivoService.getSI()
        ? this.trialSudPrezzoConPredisposizione
        : this.trialSudPrezzoSenzaPredisposizione;

    switch (this.selectedPlan) {
      case this.preventivoService.getMONO():
        switch (this.selectedZone) {
          case this.preventivoService.getNORD():
            this.totale += this.monoNordPrezzo;

            console.log("inside second nord mono", this.monoNordPrezzo);
            break;

          case this.preventivoService.getCENTRO():
            this.totale += this.monoCentroPrezzo;

            console.log("inside second  centro mono", this.monoCentroPrezzo);
            break;

          case this.preventivoService.getSUD():
            this.totale += this.monoSudPrezzo;
            break;
        }

        if (this.optionSmontaggio == this.preventivoService.getSI()) {
          this.totale += this.smontaggioMonoPrezzo;

          console.log("inside smontaggio mono", this.smontaggioMonoPrezzo);
        }

        //added

        if (this.optionLavaggio == this.preventivoService.getSI()) {
          this.totale += this.lavaggioMonoPrezzo;

          console.log("inside lavaggio mono", this.lavaggioMonoPrezzo);
        }

        break;

      case this.preventivoService.getDUAL():
        switch (this.selectedZone) {
          case this.preventivoService.getNORD():
            this.totale += this.dualNordPrezzo;
            break;

          case this.preventivoService.getCENTRO():
            this.totale += this.dualCentroPrezzo;
            break;

          case this.preventivoService.getSUD():
            this.totale += this.dualSudPrezzo;
        }

        if (this.optionSmontaggio == this.preventivoService.getSI()) {
          this.totale += this.smontaggioDualPrezzo;

          console.log("inside lavaggio dual", this.smontaggioDualPrezzo);
        }

        //added

        if (this.optionLavaggio == this.preventivoService.getSI()) {
          this.totale += this.lavaggioDualPrezzo;

          //console.log("inside lavaggio dual", this.lavaggioDualPrezzo);
        }

        break;

      case this.preventivoService.getTRIAL():
        switch (this.selectedZone) {
          case this.preventivoService.getNORD():
            this.totale += this.trialNordPrezzo;
            break;

          case this.preventivoService.getCENTRO():
            this.totale += this.trialCentroPrezzo;
            break;

          case this.preventivoService.getSUD():
            this.totale += this.trialSudPrezzo;
            break;
        }

        if (this.optionSmontaggio == this.preventivoService.getSI()) {
          this.totale += this.smontaggioTrialPrezzo;

          //console.log("inside smontaggio trial", this.smontaggioTrialPrezzo);
        }

        //added

        if (this.optionLavaggio == this.preventivoService.getSI()) {
          this.totale += this.lavaggioTrialPrezzo;

          //console.log("inside lavaggio trial", this.lavaggioTrialPrezzo);
        }

        break;
    }
    this.totale +=
      this.optionStaffa == this.preventivoService.getNO()
        ? this.staffaPrezzo
        : 0;
    // console.log("staffa", this.staffaPrezzo);
    // console.log("inside totale third", this.totale);

    this.totale +=
      this.optionStessaStaffa == this.preventivoService.getNO()
        ? this.staffaPrezzo
        : 0;
    // console.log("staffa", this.staffaPrezzo);
    // console.log("inside totale third", this.totale);

    if (this.optionPredisposizione == this.preventivoService.getNO()) {
      this.totale += this.distanzaCavoSegnale * this.tuboSegnalePrezzo;
      this.totale += this.distanzaTuboCondensa * this.tuboCondesaPrezzo;
      this.totale += this.distanzaTuboRame * this.tuboRamePrezzo;

      // console.log("inside totale FORTH", this.totale);
    } else if (this.optionPosizione == this.preventivoService.getNO()) {
      //  console.log("inside if optionPosizione");

      this.totale += this.distanzaCavoSegnale2 * this.tuboSegnalePrezzo;
      this.totale += this.distanzaTuboCondensa2 * this.tuboCondesaPrezzo;
      this.totale += this.distanzaTuboRame2 * this.tuboRamePrezzo;

      // console.log("inside if optionPosizione total", this.totale);
    }


  }

  ngOnInit() {

    if (this.preventivoService.getPackageType() === "otherServicesRes") {

      this.TotalePriceTitle = "Importo totale"
    }

    this.quotationHeaderOnSite = environment.quotationHeaderOnSite;
    this.quotationHeaderOnSiteNotice = environment.quotationHeaderOnSiteNotice;
    this.quotationHeaderOnSiteDig = environment.quotationHeaderOnSiteDig;
    this.quotationHeaderOnSiteDigNotice = environment.quotationHeaderOnSiteDigNotice;

    //console.log("inside italy ngOnInit");
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    try {

      console.log("IdWebSiteTrackingFlow", this.preventivoService.getIdWebSiteTrackingFlow())
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      // console.log("inside try", this.hidePage);
      this.climaSmartService
        .GetQuestionResponses(this.preventivoService.getIdWebSiteTrackingFlow())
        //.GetQuestionResponses(134)
        .subscribe(data => {
          if (data) {
            console.log("data prevent", data);
            this.FirstName = data.item1[0].firstName,
              this.LastName = data.item1[0].lastName,
              this.Phone = data.item1[0].phone,
              this.Mobile = data.item1[0].mobile,
              this.Mail = data.item1[0].mail,
              this.TaxCode = data.item1[0].taxCode,
              this.Address = data.item1[0].address,
              this.ZipCode = data.item1[0].zipCode,
              this.City = data.item1[0].city,
              this.Province = data.item1[0].province,
              this.Login = data.item1[0].login,
              this.Total = data.item1[0].estimateTot,
              this.DatiPreventivo = data.item1,
              this.DiscountPercentage = data.item1[0].discountPercentage
          }
          this.SelectedProductName = this.preventivoService.getProductName()
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }

          , err => {


             console.error("ngOnInit Error ", err);
            if (err.status === 401 || err.status === 404) {
             // this.climaSmartService.logoutAndRedirect();
              this.hideLoader = true;
              this.loading = false;

            }

            else {

              //console.log("inside else")

              this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
              this.hideLoader = true;
              this.loading = false

            }
          }

        );
    }

    catch {
      // console.log("inside catch");
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
    //console.log("ZG = " + this.preventivoService.getZonaGeografica());
    //console.log("TI = " + this.preventivoService.getTipoImpianto());
    //console.log("CI = " + this.preventivoService.getCondizionatoreInstallato());
    //console.log("SM = " + this.preventivoService.getSmontaggio());
    //console.log("LA = " + this.preventivoService.getLavaggio());
    //console.log("PR = " + this.preventivoService.getPredispozisione());
    //console.log("ST = " + this.preventivoService.getStaffa());
    //console.log("SH = " + this.preventivoService.getShuko());
    //console.log("DI = " + this.preventivoService.getDistanzaMotoreSplit());
    //console.log(
    //  "PR = " + this.preventivoService.getProfonditaPareteMaggioreDi40()
    //);
    //console.log("SST = " + this.preventivoService.getStesseStaffe());
    //console.log(
    //  "DI 2 = " +
    //    this.preventivoService.getDistanzaPredispozisioneCondizionatore()
    //);
    //console.log("PO 2= " + this.preventivoService.getPosizione());

    //  console.log("PRiCE MONO = " + this.preventivoService.getImpantoMonoPrezzo());

    //  if (this.preventivoService.getZonaGeografica() === "nord") {
    //  } else if (this.preventivoService.getZonaGeografica() === "centro") {
    //  } else if (this.preventivoService.getZonaGeografica() === "sud") {
    //  } else {
    //  }
  }

  //this.preventivoService.getZonaGeografica();

  isButtonHidden(): boolean {
    if (localStorage.getItem("userToken")) {
      return true;
    } else {
      return false;
    }
  }

  backClicked() {
    this._location.back();
  }

  newEstimate() {
    // console.log("inside newEstimate");
    this.preventivoService.clear();
    this.router.navigate(["/zona-geografica"]);
  }

  ConvertToInt(val) {
    return parseInt(val);
  }

  isInspection(): boolean {

    //console.log("isInspection")
    var ret = false;
    if (this.preventivoService.getServiceType() == "onSite"
      || this.preventivoService.getServiceType() == "onSite_MW"
      || this.preventivoService.getServiceType() == "onSiteButali"
      || this.preventivoService.getServiceType() == "onSiteWithEcobonus"
      || this.preventivoService.getServiceType() == "onSiteWithEcobonusButali"
      || this.preventivoService.getServiceType() == "onSiteWithEcobonus_MW") {
      ret = true;
    }
    return ret;
  }

  isInspectionDig(): boolean {

    //console.log("isInspection")
    var ret = false;
    if (this.preventivoService.getServiceType() == "onSiteDigital"
      || this.preventivoService.getServiceType() == "onSiteDigital_MW"
      || this.preventivoService.getServiceType() == "onSiteDigitalButali"
      || this.preventivoService.getServiceType() == "onSiteDigitalWithEcobonus"
      || this.preventivoService.getServiceType() == "onSiteDigitalWithEcobonusButali"
      || this.preventivoService.getServiceType() == "onSiteDigitalWithEcobonus_MW") {
      ret = true;
    }
    return ret;
  }

  public downloadPdf() {
    //console.log("stampa");
    window.open(Parameters.getBaseUrl() + '/api/v1/QuestionResponse/GetPriceCalcPdf/' + this.preventivoService.getIdWebSiteTrackingFlow())
  }
}
