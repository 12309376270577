import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {ClimaSmartService} from '../_service/clima-smart.service';


@Component({
  selector: 'app-documenti',
  templateUrl: './documenti.component.html',
  styleUrls: ['./documenti.component.css']
})
export class DocumentiComponent implements OnInit {
  documents: {fileName: string; link: string, type: string; format: string}[];
  constructor(private climaSmartService: ClimaSmartService) {
  }

  ngOnInit() {
    this.documents = environment.documents;
  }

  download(link: string): void {
    this.climaSmartService.openForDownloadDocument(link);
  }
}
