import { AbstractControl } from "@angular/forms";
import { Util } from "./../util";

const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const specials = "!\"#$%&'()*+,-./:;<=>?@[]^_`{|}~";

export class PasswordConfirmValidators {
  public static isPasswordConfirm(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (Util.isNotPresent(control)) return null;

    let password = control.value;

    const passwordConfirm = control.parent.controls["newPassword"];
    if (passwordConfirm.valid && passwordConfirm.touched) {
      var isValid1 = false;
      if (passwordConfirm.value === password) isValid1 = true;

      if (!isValid1) return { equalvalue: true };
    }

    return null;
  }
}
