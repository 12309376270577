import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalConfirm } from '../_models/models';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent implements OnInit {

  @ViewChild('childModal') public childModal: ModalDirective;
  modal: ModalConfirm = new ModalConfirm()

  constructor() { }

  ngOnInit() {
  }

  public show(modal: ModalConfirm) {
    this.modal = modal
    this.childModal.show()
  }

  hide() {
    this.childModal.hide()
  }


}
