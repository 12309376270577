import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MODULE_COMPONENTS, MODULE_ROUTES } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http"; //from version5

//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ItalyComponent } from "./italy/italy.component";
import { TipoImpiantoComponent } from "./tipo-impianto/tipo-impianto.component";
import { CondizionatoreInstallatoComponent } from "./condizionatore-installato/condizionatore-installato.component";
import { PredisposizioneComponent } from "./predisposizione/predisposizione.component";
//import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
//import {ImageZoomModule} from 'angular2-image-zoom';
import { LightboxModule } from "ngx-lightbox";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule, BsLocaleService } from "ngx-bootstrap/datepicker";
import { ShowHidePasswordModule } from "ngx-show-hide-password";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatCheckboxModule
} from "@angular/material";
import { CurrencyMaskModule } from "ng2-currency-mask";
// ********************** angular-modal-gallery *****************************
import "hammerjs"; // <------ mandatory dependency for angular-modal-gallery
import "mousetrap"; // <------ mandatory dependency for angular-modal-gallery
import { ModalGalleryModule } from "angular-modal-gallery";
import { Ng2OdometerModule } from 'ng2-odometer';
import { DistanzaComponent } from "./distanza/distanza.component";
import { StaffaComponent } from "./staffa/staffa.component";
import { ShukoComponent } from "./shuko/shuko.component";
import { ProfonditaPareteComponent } from "./profondita-parete/profondita-parete.component";
import { PreventivoComponent } from "./preventivo/preventivo.component";
import { SmontaggioComponent } from "./smontaggio/smontaggio.component";
import { LavaggioComponent } from "./lavaggio/lavaggio.component"; // <----------------- angular-modal-gallery

import { PreventivoService } from "./preventivo.service";
import { DatiClienteComponent } from "./dati-cliente/dati-cliente.component";
import { StesseStaffeComponent } from "./stesse-staffe/stesse-staffe.component";
import { PredisposizioneCondizionatoreComponent } from "./predisposizione-condizionatore/predisposizione-condizionatore.component";
import { MenuComponent } from "./menu/menu.component";
import { AreaRiservataComponent } from "./area-riservata/area-riservata.component";
import { DistanzaSplitComponent } from "./distanza-split/distanza-split.component";
import { ModalLoginComponent } from "./modal-login/modal-login.component";
import { ModalPickupPointComponent } from "./modal-pickup-point/modal-pickup-point.component";
import { RicercaComponent } from "./ricerca/ricerca.component";
import { MieiDatiComponent } from "./miei-dati/miei-dati.component";
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SopralluogoComponent } from './sopralluogo/sopralluogo.component';
import { CookieLawComponent } from './cookie-law/cookie-law.component';
import { AdditionalWorkComponent } from './additional-work/additional-work.component';
import { OtherServicesComponent } from './other-services/other-services.component';
import { SplitSelectionComponent } from './split-selection/split-selection.component';
import { ModalListComponent } from './modal-list/modal-list.component';
import { LoaderComponent } from './loader/loader.component';
import { DatiPreventivoComponent } from './dati-preventivo/dati-preventivo.component';
import { DatiInstallazioneComponent } from './dati-installazione/dati-installazione.component';
import { ProdottiComponent } from "./prodotti/prodotti.component";
import { SelectPackageComponent } from './select-package/select-package.component';
import { UcSingleChoiceComponent } from './uc-single-choice/uc-single-choice.component';
import { GatewayComponent } from './gateway/gateway.component';
import { ServizioComponent } from './servizio/servizio.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { UploadInstallationDrawComponent } from './upload-installation-draw/upload-installation-draw.component'; // for FullCalendar
import { ModalListNoteComponent } from './modal-list-note/modal-list-note.component';
import { ModalVendorReferenceComponent } from './modal-vendor-reference/modal-vendor-reference.component';
import { environment } from "src/environments/environment";
import { SealedPlantComponent } from './sealed-plant/sealed-plant.component';
import { DocumentiComponent } from './documenti/documenti.component';
import { ConsulenzaEnergeticaPvComponent } from './consulenza-energetica-pv/consulenza-energetica-pv.component';
import { AutorizzazioneInstallazioneConsenergComponent } from './autorizzazione-installazione-consenerg/autorizzazione-installazione-consenerg.component';
import { CoperturaPareteComponent } from './copertura-parete/copertura-parete.component';
import { ZonaAbitazioneComponent } from './zona-abitazione/zona-abitazione.component';
import { DimensionitettoComponent } from './dimensionitetto/dimensionitetto.component';
import { StrutturaTettoComponent } from './struttura-tetto/struttura-tetto.component';
import { InserimentoContatorekwComponent } from './inserimento-contatorekw/inserimento-contatorekw.component';
import { FasciaOrariaConsumoComponent } from './fascia-oraria-consumo/fascia-oraria-consumo.component';
import { PiastraInduzioneComponent } from './piastra-induzione/piastra-induzione.component';
import { PompaCaloreComponent } from './pompa-calore/pompa-calore.component';
import { ClimatizzatoriComponent } from './climatizzatori/climatizzatori.component';
import { AutoElettricaComponent } from './auto-elettrica/auto-elettrica.component';
import { PreventivoFotovoltaicoComponent } from './preventivo-fotovoltaico/preventivo-fotovoltaico.component';
import { KoPreventivoFvComponent } from './ko-preventivo-fv/ko-preventivo-fv.component';
import { DatiClientefvComponent } from './dati-clientefv/dati-clientefv.component';
import { KoDefaultComponent } from './ko-default/ko-default.component';
import {LoginComponent} from "./login/login.component";

@NgModule({
  declarations: [
    MODULE_COMPONENTS,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ItalyComponent,
    TipoImpiantoComponent,
    CondizionatoreInstallatoComponent,
    PredisposizioneComponent,
    DistanzaComponent,
    StaffaComponent,
    ShukoComponent,
    ProfonditaPareteComponent,
    PreventivoComponent,
    SmontaggioComponent,
    LavaggioComponent,
    DatiClienteComponent,
    StesseStaffeComponent,
    PredisposizioneCondizionatoreComponent,
    MenuComponent,
    AreaRiservataComponent,
    DistanzaSplitComponent,
    ModalLoginComponent,
    ModalPickupPointComponent,
    RicercaComponent,
    MieiDatiComponent,
    ModalErrorComponent,
    ModalConfirmComponent,
    HomePageComponent,
    SopralluogoComponent,
    CookieLawComponent,
    AdditionalWorkComponent,
    OtherServicesComponent,
    SplitSelectionComponent,
    ModalListComponent,
    LoaderComponent,
    DatiPreventivoComponent,
    DatiInstallazioneComponent,
    SelectPackageComponent,
    UcSingleChoiceComponent,
    GatewayComponent,
    ServizioComponent,
    CalendarioComponent,
    UploadInstallationDrawComponent,
    ModalListNoteComponent,
    ModalVendorReferenceComponent,
    SealedPlantComponent,
    DocumentiComponent,
    ConsulenzaEnergeticaPvComponent,
    AutorizzazioneInstallazioneConsenergComponent,
    CoperturaPareteComponent,
    ZonaAbitazioneComponent,
    DimensionitettoComponent,
    StrutturaTettoComponent,
    InserimentoContatorekwComponent,
    FasciaOrariaConsumoComponent,
    PiastraInduzioneComponent,
    PompaCaloreComponent,
    ClimatizzatoriComponent,
    AutoElettricaComponent,
    PreventivoFotovoltaicoComponent,
    KoPreventivoFvComponent,
    DatiClientefvComponent,
    KoDefaultComponent,
    ProdottiComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(MODULE_ROUTES, {
      enableTracing: !environment.production
    }),
    HttpClientModule,
    LightboxModule,
    ModalGalleryModule.forRoot(), // <----------------- angular-modal-gallery module import,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ShowHidePasswordModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    CurrencyMaskModule,
    Ng2OdometerModule.forRoot(),
    FullCalendarModule, // for FullCalendar

  ],
  providers: [PreventivoService, BsLocaleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
