import { HttpHeaders } from "@angular/common/http"; //from version5
import { RequestOptions } from "@angular/http";
import { Headers } from "@angular/http";

export class Parameters {

  public static getBaseUrl() {
    let baseUrl: string;

    let protocol = window.location.href.split("/")[0];

    switch (window.location.hostname) {
      case "localhost":
        baseUrl = protocol + "//localhost:52789";
        break;
      case "butaliclima.covercare.it":
        baseUrl = protocol + "//butaliclimaapi.covercare.it";
        break;

      case "butalipreventivatore.azurewebsites.net":
        baseUrl = protocol + "//apibutalipreventivatore.azurewebsites.net";
        break;

      case "butaliclimatest.covercare.it":
        baseUrl = protocol + "//butaliclimaapitest.covercare.it";
        break;

      case "butalipreventivatore-staging.azurewebsites.net":
        baseUrl = protocol + "//apibutalipreventivatore-staging.azurewebsites.net";
        break;

      case "mediaclima.covercare.it":
        baseUrl = protocol + "//mediaclimaapi.covercare.it";
        break;

      case "mediaworldpreventivatore.azurewebsites.net":
        baseUrl = protocol + "//apimediaworldpreventivatore.azurewebsites.net";
        break;

      case "mediaclimatest.covercare.it":
        baseUrl = protocol + "//mediaclimatestapi.covercare.it";
        break;

      case "mediaworldpreventivatore-staging.azurewebsites.net":
        baseUrl = protocol + "//apimediaworldpreventivatore-staging.azurewebsites.net";
        break;
      case "clima.covercare.it":
      case "unieuropreventivatore.azurewebsites.net":
        baseUrl = protocol + "//climaapi.covercare.it";
        break;

      case "climatest.covercare.it":
      case "unieuropreventivatore-staging.azurewebsites.net":
        baseUrl = protocol + "//climatestapi.covercare.it";
        break;

      case "climaqa.covercare.it":
      case "unieuropreventivatore-quality.azurewebsites.net":
        baseUrl = protocol + "//climaqaapi.covercare.it";
        break;

      case "climautilitytest.covercare.it":
      case "multiutilitiespreventivatore-staging.azurewebsites.net":
        baseUrl = protocol + "//climautilityapitest.covercare.it";
        break;

      case "climautility.covercare.it":
      case "multiutilitiespreventivatore.azurewebsites.net":
        baseUrl = protocol + "//climautilityapi.covercare.it";
        break;

      default:
        console.warn(
          "getBaseUrl unhandled url. window.location.hostname" +
          window.location.hostname
        );
        baseUrl = protocol + "//climatestapi.covercare.it";
        break;
    }
    //console.log("xyxyxy ", baseUrl);
    return baseUrl;
  }

  public static getHeaderAppJson(): any {
    return { headers: new HttpHeaders({ "Content-Type": "application/json" }) };
  }

  public static getHeaderToken(): any {
    return {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("userToken")
      })
    };
  }

  public static getHeaderTokenForBlob(): any {
    return {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("userToken")
      }),
      responseType: 'blob'
    };
  }

  public static getHeaderAppJsonAndToken(): { headers?: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("userToken")
      })
    };
  }

}
