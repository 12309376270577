import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClientData, PriceCalc, ResponsePCalc, SopralluogoData, CustomerData, PriceEstimationCalc } from "../_models/models";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { Parameters } from "../_service/parameters";
import { ShopDetails } from "../_models/models";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Console } from "@angular/core/src/console";
import { of } from "rxjs";
import { delay } from "rxjs/operators";

const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
const capRegex = /^\d{5}$/;
const cfRegex = /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$|([0-9]{11})$/;

@Component({
  selector: "app-dati-cliente",
  templateUrl: "./dati-cliente.component.html",
  styleUrls: ["./dati-cliente.component.css"]
})
export class DatiClienteComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;
  inputNome: string = "";
  inputCognome: string = "";
  inputTelefono: string = "";
  inputMobile: string = "";
  inputEmail: string = "";
  inputIndirizzo: string = "";
  inputCap: string = "";
  inputCitta: string = "";
  inputProvincia: string = "";
  inputLogin: string = "";
  inputCF: string = "";

  loading: boolean = false;
  hideLoader: boolean = true;
  hideButtons: boolean = false;

  quotationValue: number = null;
  quotationInProgress: boolean = true;
  webSiteFlowTracking: number;
  isQuotation: boolean;

  //shopName: string = "";
  public shopDetails: ShopDetails = new ShopDetails();

  public DataClientForm: FormGroup;
  public client: ClientData = new ClientData();
  public customer: CustomerData = new CustomerData();
  public sopralluogo: SopralluogoData = new SopralluogoData();

  public priceCalc: PriceCalc = new PriceCalc();

  public provinceList: Array<any>;
  public shopList: Array<any>;
  public shopDetailsObject: Object;

  public json() : string {return JSON.stringify(this.client);}

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  ngOnInit() {
    if (this.preventivoService.getNomeCliente() || this.preventivoService.getCognomeCliente() ||
      this.preventivoService.getTelefonoCliente() || this.preventivoService.getMobileCliente() ||
      this.preventivoService.getEmailCliente() || this.preventivoService.getCodiceFiscaleCliente() ||
      this.preventivoService.getIndirizzoCliente() ||
      this.preventivoService.getCittaCliente() || this.preventivoService.getProvinciaCliente() ||
      this.preventivoService.getCapCliente() || this.preventivoService.getLoginCliente()) {
      this.client.firstName = this.preventivoService.getNomeCliente();
      this.client.lastName = this.preventivoService.getCognomeCliente();
      this.client.phone = this.preventivoService.getTelefonoCliente();
      this.client.mobile = this.preventivoService.getMobileCliente();
      this.client.mail = this.preventivoService.getEmailCliente();
      this.client.taxCode = this.preventivoService.getCodiceFiscaleCliente();
      this.client.address = this.preventivoService.getIndirizzoCliente();
      this.client.city = this.preventivoService.getCittaCliente();
      this.client.idProvince = +this.preventivoService.getProvinciaCliente();
      this.client.zipCode = this.preventivoService.getCapCliente();
      this.client.idLogin = +(this.preventivoService.getLoginCliente().toString());
      this.client.notes = this.preventivoService.getCustomerNotes();
    }

    this.DataClientForm = this._fb.group({
      DataClientFormGroup: this._fb.group({
        //serviceNumber: ["", []],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        phone: ["", []],
        mobile: ["", []],
        mail: ["", [Validators.required, Validators.pattern(mailRegex)]],
        address: ["", [Validators.required]],
        notes: ["", []],
        city: ["", [Validators.required]],
        province: ["", [Validators.required]],
        zipCode: ["", [Validators.required, Validators.pattern(capRegex)]],
        login: ["", [Validators.required]],
        taxCode: ["", [Validators.required, Validators.pattern(cfRegex)]]
      })
    });

    try {

      console.log('Checking for quotation:' + this.preventivoService.getServiceType());

      this.climaSmartService.GetProvince()
        .subscribe(
          data => {
            //console.debug("climaSmartService.GetProvince" + data);
            this.provinceList = data
            // console.log("province Lists xxxxxxxxxxxxxxxxxxxx" + this.provinceList);

          }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
            }
          }
        )
      this.climaSmartService.GetShopList()
        .subscribe(
          data => {
            //console.debug("ngOnInit data shop list=" + JSON.stringify(data));
            //console.debug("climaSmartService.GetShopList" + data);
            this.shopList = data
            //console.log("shop lists", this.shopList);

          }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
            }
          }
        )

      // console.log("xxxxxxxxxx", this.shopDetails)
      if (localStorage.getItem("userToken") !== null) {

        //console.log("inside if")

        this.climaSmartService.GetShopDetails()
          .subscribe(
            data => {
              // console.debug("climaSmartService.GetShopDetails" + data);
              this.shopDetails = data
              //console.log("GetShopDetails" + this.shopDetails);

            }
            , err => {

              console.error("ngOnInit Error ", err);
              if (err.status === 401) {
                this.climaSmartService.logoutAndRedirect();
              }
            }
          );


      }
    }

    catch (ex) {
      console.exception("ngOnInit Exception " + ex)
    }
  }

  getAdditionalWorkArray(): { idElement: number; description: string; quantity: number; }[] {
    return this.preventivoService.getAdditionalWorks();
  }

  goToPriceTable() {
    //console.log("goToPriceTable ", this.getResponseArray());
    let arrResponse: Array<ResponsePCalc> = this.getResponseArray();
    let arrAdditionalWork = this.getAdditionalWorkArray();
     //Apointment Date
    let appointmentDate = this.preventivoService.getAppointmentDate()

    this.client.phone = this.client.phone ? this.client.phone : null;
    this.client.mobile = this.client.mobile ? this.client.mobile : null;

    this.preventivoService.setNomeCliente(this.client.firstName);
    this.preventivoService.setCognomeCliente(this.client.lastName);
    this.preventivoService.setTelefonoCliente(this.client.phone);
    this.preventivoService.setMobileCliente(this.client.mobile);
    this.preventivoService.setEmailCliente(this.client.mail);
    this.preventivoService.setCodiceFiscaleCliente(this.client.taxCode);
    this.preventivoService.setIndirizzoCliente(this.client.address);
    this.preventivoService.setCittaCliente(this.client.city);
    this.preventivoService.setProviniciaCliente(this.client.idProvince);
    this.preventivoService.setCapCliente(this.client.zipCode.toString());
    this.preventivoService.setLoginCliente(+(this.client.idLogin.toString()));

    //this.preventivoService.setOpereExtra(this.priceCalc.extraAccessoriesDescription);
    //this.preventivoService.setOpereExtraPrice(this.priceCalc.extraAccessoriesValue);
    //this.preventivoService.setDiscountPercent(this.priceCalc.discountPercentage);
    // this.preventivoService.setTotal(this.client.total);

    //console.log("Nome : ", this.client.firstName);
    //console.log("Cognome : ", this.client.lastName);
    //console.log("Telefono : ", this.client.phone);
    //console.log("Mobile : ", this.client.mobile);
    //console.log("Email : ", this.client.mail);
    //console.log("Indirrizo : ", this.client.address);
    //console.log("Citta : ", this.client.city);
    //console.log("Provincia : ", this.client.idProvince);
    //console.log("Cap : ", this.client.zipCode);
    //console.log("Login : ", this.client.idLogin);

    //console.log("Total : ", this.client.total);

    let data: PriceCalc = {
      responseList: arrResponse, firstName: this.client.firstName,
      additionalWorks: arrAdditionalWork,
      lastName: this.client.lastName, mobile: this.client.mobile, phone: this.client.phone,
      mail: this.client.mail, taxCode: this.client.taxCode, address: this.client.address, zipCode: this.client.zipCode.toString(),
      city: this.client.city, idProvince: this.client.idProvince, idLogin: +(this.client.idLogin.toString()),
      notes: this.client.notes,
      inspectionCode: this.preventivoService.getInspectionCode(),
      extraAccessoriesDescription: this.preventivoService.getOpereExtra(),
      extraAccessoriesValue: this.preventivoService.getOpereExtraPrice(),
      discountPercentage: this.preventivoService.getDiscountPercent(),
      idWebSiteFlowTracking: this.preventivoService.getEstimationId(),
      appointmentDate : appointmentDate === "" ? null : appointmentDate,
      IDpackage: this.preventivoService.getProductIdPackage(),
    };

    if (this.preventivoService.getInstallationDraw() ) {
      data.idInstallationDrawAttachment = this.preventivoService.getInstallationDraw().idAttachment
    }

    try {
      // console.log("getOpereExtraPrice", this.preventivoService.getOpereExtraPrice() )
      this.hideLoader = false;
      this.loading = true;
      this.hideButtons = true

      //console.log("data", JSON.stringify(data), "response Array", arrResponse)
      //console.log("TRY setDatiCliente")

      if (arrResponse.length > 0) {
        //console.log("inside if data", arrResponse)
        this.climaSmartService
          .QuestionResponses(data)
          .subscribe(data => {
            //console.log("setDatiCliente OK=" + data);
            if (data) {
              if (data.isAdded) {
                this.hideLoader = true;
                this.loading = false;
                // console.log("inside if");
                //   console.log("idWebSiteFlowTracking", data.idWebSiteFlowTracking);
                this.preventivoService.setIdWebSiteTrackingFlow(data.idWebSiteFlowTracking);

                this.router.navigate(["/preventivo"]);
              }
              else {
                // console.log("inside else");
                //  console.error("removeProduct data.message: ", data.message);
                this.modalError.show({ errorTitle: "Errore", errorDetail: data.message });
                // alert(data.message);
                this.hideLoader = true;
                this.loading = false;
                this.hideButtons = false
              }
            }
          }
            , err => {

              // console.error("ngOnInit Error ", err);
              if (err.status === 401) {
                this.climaSmartService.logoutAndRedirect();
                this.hideLoader = true;
                this.loading = false;
              }

              else {

                //console.log("inside else")

                this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
                this.hideLoader = true;
                this.loading = false;
                this.hideButtons = false
                this.preventivoService.setEstimationId(null);

              }
            }

          );
      }

      else {

        //console.log("inside else end");
        this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
        this.hideLoader = true;
        this.loading = false;
        this.hideButtons = false

      }

    }
    catch {
      //console.log("inside catch");
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hideLoader = false;

    }
    // this.router.navigate(["/preventivo"]);
  }

  private getResponseArray() {
    let arrResponse: Array<ResponsePCalc> = [];
    if (this.preventivoService.getService() != "" && this.preventivoService.getService() != null) {
      // console.log("FQ PriceCALC= " + this.preventivoService.getFirstQuestion());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getService(),
        responseValue: null
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getPackage() != "" && this.preventivoService.getPackage() != null) {
      // console.log("FQ PriceCALC= " + this.preventivoService.getFirstQuestion());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getPackage(),
        responseValue: null
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getOtherService() != "" && this.preventivoService.getOtherService() != null) {
      // console.log("OS PriceCALC= " + this.preventivoService.getOtherService());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getOtherService(),
        responseValue: null
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getSplitSelection() != "" && this.preventivoService.getSplitSelection() != null) {
      // console.log("SS PriceCALC= " + this.preventivoService.getSplitSelection());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getSplitSelection(),
        responseValue: null
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getZonaGeografica() != "" && this.preventivoService.getZonaGeografica() != null) {
      // console.log("ZG PriceCALC= " + this.preventivoService.getZonaGeografica());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getZonaGeografica(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getTipoImpianto() != "" && this.preventivoService.getTipoImpianto() != null) {
      // console.log("TI PriceCALC= " + this.preventivoService.getTipoImpianto());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getTipoImpianto(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getCondizionatoreInstallato() != "" && this.preventivoService.getCondizionatoreInstallato() != null) {
      // console.log("CI PriceCALC= " + this.preventivoService.getCondizionatoreInstallato());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getCondizionatoreInstallato(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getSmontaggio() != "" && this.preventivoService.getSmontaggio() != null) {
      // console.log("SM PriceCALC= " + this.preventivoService.getSmontaggio());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getSmontaggio(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getLavaggio() != "" && this.preventivoService.getLavaggio() != null) {
      //console.log("LA PriceCALC= " + this.preventivoService.getLavaggio());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getLavaggio(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getPredispozisione() != "" && this.preventivoService.getPredispozisione() != null) {
      // console.log("PR PriceCALC= " + this.preventivoService.getPredispozisione());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getPredispozisione(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getStaffa() != "" && this.preventivoService.getStaffa() != null) {
      // console.log("ST PriceCALC= " + this.preventivoService.getStaffa());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getStaffa(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getShuko() != "" && this.preventivoService.getShuko() != null) {
      // console.log("SH PriceCALC= " + this.preventivoService.getShuko());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getShuko(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getMeterRangeMotoreSplit()) {
      // console.log("DI PriceCALC= " + this.preventivoService.getDistanzaMotoreSplit());
      // console.log("DI id PriceCALC= " + this.preventivoService.getIdDistanzaMotoreSplit());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getIdDistanzaMotoreSplit(),
        responseValue: this.preventivoService.getDistanzaMotoreSplit().toString(),
        responseValue2: this.preventivoService.getMeterRangeMotoreSplit()
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getProfonditaPareteMaggioreDi40() != "" && this.preventivoService.getProfonditaPareteMaggioreDi40() != null) {
      //console.log("PR PriceCALC= " + this.preventivoService.getProfonditaPareteMaggioreDi40());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getProfonditaPareteMaggioreDi40(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getStesseStaffe() != "" && this.preventivoService.getStesseStaffe() != null) {
      // console.log("SST PriceCALC= " + this.preventivoService.getStesseStaffe());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getStesseStaffe(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getDistanzaPredispozisioneCondizionatore() != 0) {
      // console.log("DI 2 PriceCALC= " + this.preventivoService.getDistanzaPredispozisioneCondizionatore());
      //console.log("DI 2 id PriceCALC= " + this.preventivoService.getIdDistanzaPredispozisioneCondizionatore());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getIdDistanzaPredispozisioneCondizionatore(),
        responseValue: this.preventivoService.getDistanzaPredispozisioneCondizionatore().toString()
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getPosizione() != "" && this.preventivoService.getPosizione() != null) {
      //console.log("PO 2 PriceCALC= " + this.preventivoService.getPosizione());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getPosizione(),
        responseValue: null
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getBTU() != "" && this.preventivoService.getInstallationHeight() != "") {
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getIdInstallation(),
        responseValue: this.preventivoService.getBTU(),
        responseValue2: this.preventivoService.getInstallationHeight()
      };
      arrResponse.push(resp);
    }

    if (this.preventivoService.getSealedPlant() != '' && this.preventivoService.getSealedPlant() != null) {
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getSealedPlant(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    // selezione prodotto
    if (
      this.preventivoService.getProductIdPackage() != ''
      && this.preventivoService.getProductIdPackage() != null
    ) {
      var resp: ResponsePCalc = {
        idWebSiteFlow: parseInt(this.preventivoService.getProductIDWebSiteFlow()),
        responseValue: this.preventivoService.getProductIdPackage(),
        responseValue2: this.preventivoService.getProductPrice(),
      };
      arrResponse.push(resp);
    }

    return arrResponse;
  }

  goBack() {
    // console.log("inside go back button");
    this._location.back();
  }

  isFormValid() {

    if (this.client.phone || this.client.mobile) {
      // console.log("inside true isFormValid");
      return this.DataClientForm.valid && true && (!this.isQuotation || this.quotationValue);
    }

    // console.log("inside false isFormValid");
    return this.DataClientForm.valid && false;
  }

  isMobilePhoneValid() {

    if (this.client.phone || this.client.mobile) {
      //console.log("inside true isMobilePhoneValid");
      return true;
    }
    // console.log("inside false isMobilePhoneValid");
    return false;
  }

  isShopHidden(): boolean {
    if (localStorage.getItem("userToken")) {
      //console.log("user taken si si si si")
      return false;
    } else {
      //console.log("user taken no no no no")
      return true;
    }
  }

  private handleStandardError(e: any) {
    if (e.status === 401) {
      this.climaSmartService.logoutAndRedirect();
      this.hideLoader = true;
      this.loading = false;
    }
    else {
      this.modalError.show({ errorTitle: "Errore", errorDetail: "Si è verificato un errore imprevisto, la invitiamo a riprovare" });
      this.hideLoader = true;
      this.loading = false;
      this.hideButtons = false;
    }
  }
}
