import { Component, OnInit, ViewChild } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  ClientData,
  PriceCalc,
  ResponsePCalc,
  SopralluogoData,
  CustomerData,
  PriceEstimationCalc,
} from "../_models/models";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { ModalErrorComponent } from "../modal-error/modal-error.component";

@Component({
  selector: "app-dati-preventivo",
  templateUrl: "./dati-preventivo.component.html",
  styleUrls: ["./dati-preventivo.component.css"],
})
export class DatiPreventivoComponent implements OnInit {
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  loading: boolean = false;
  hideLoader: boolean = true;
  hideButtons: boolean = false;

  quotationValue: number = null;
  quotationInProgress: boolean = true;
  webSiteFlowTracking: number;
  isQuotation: boolean;
  fatalError: boolean = false;

  public priceCalc: PriceCalc = new PriceCalc();

  public provinceList: Array<any>;
  public shopList: Array<any>;
  public shopDetailsObject: Object;

  public DatiPreventivo: any[];
  public DiscountPercentage: string = "";
  public TotalePriceTitle: string = "Importo Preventivo Imponibile";
  public Total: string = "";
  public error: boolean = false;
  public message: string = "";
  public ProductName: string = "";
  private inspCode: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {}

  get isShowQuantity() {
    return this.inspCode && this.inspCode.length >= 16;
  }

  ngOnInit() {
    try {
      console.log(
        "Checking for quotation:" + this.preventivoService.getServiceType()
      );
      this.estimate();
    } catch (ex) {
      console.exception("ngOnInit Exception " + ex);
    }
  }

  estimate() {
    let arrResponse = this.getResponseArray();
    let arrAdditionalWork = this.getAdditionalWorkArray();
    this.inspCode = this.preventivoService.getInspectionCode();

    console.log("responseList", arrResponse);
    let data: PriceEstimationCalc = {
      responseList: arrResponse,
      idLogin: this.preventivoService.getLoginCliente()
        ? this.preventivoService.getLoginCliente()
        : 1,
      inspectionCode: this.preventivoService.getInspectionCode(),
      additionalWorks: arrAdditionalWork,
      extraAccessoriesDescription: this.preventivoService.getOpereExtra(),
      extraAccessoriesValue: this.preventivoService.getOpereExtraPrice(),
      discountPercentage: this.preventivoService.getDiscountPercent(),
    };


    this.ProductName = this.preventivoService.getProductName()
    this.quotationInProgress = true;
    this.hideLoader = false;
    this.loading = true;
    this.fatalError = false;
    return this.climaSmartService.getEstimation(data).subscribe(
      (d) => {
        this.message = d.message;
        this.error = d.message != "OK";
        this.quotationInProgress = false;
        this.hideLoader = true;
        this.loading = false;
        this.quotationValue = d.estimation;
        this.webSiteFlowTracking = d.webSiteFlowTracking;
        this.preventivoService.setEstimationId(d.webSiteFlowTracking);
        console.log("quotationValue", this.quotationValue);

        this.Total = d.item1[0].estimateTot;
        this.DatiPreventivo = d.item1;
        this.DiscountPercentage = d.item1[0].discountPercentage;
      },
      (e) => {
        this.message = "Si è verificato un errore imprevisto";
        this.fatalError = true;
        this.error = true;
        this.quotationInProgress = false;
        this.hideLoader = true;
        this.loading = false;
        this.handleStandardError(e);
      }
    );
  }

  getAdditionalWorkArray(): {
    idElement: number;
    description: string;
    quantity: number;
  }[] {
    return this.preventivoService.getAdditionalWorks();
  }

  goToDatiCliente() {
    this.router.navigate(["/datiCliente"]);
  }

  private getResponseArray() {
    let arrResponse: Array<ResponsePCalc> = [];
    if (
      this.preventivoService.getPackage() != "" &&
      this.preventivoService.getPackage() != null
    ) {
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getPackage(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getService() != "" &&
      this.preventivoService.getService() != null
    ) {
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getService(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (
      this.preventivoService.getOtherService() != "" &&
      this.preventivoService.getOtherService() != null
    ) {
      // console.log("OS PriceCALC= " + this.preventivoService.getOtherService());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getOtherService(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (
      this.preventivoService.getSplitSelection() != "" &&
      this.preventivoService.getSplitSelection() != null
    ) {
      // console.log("SS PriceCALC= " + this.preventivoService.getSplitSelection());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getSplitSelection(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (
      this.preventivoService.getZonaGeografica() != "" &&
      this.preventivoService.getZonaGeografica() != null
    ) {
      // console.log("ZG PriceCALC= " + this.preventivoService.getZonaGeografica());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getZonaGeografica(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getTipoImpianto() != "" &&
      this.preventivoService.getTipoImpianto() != null
    ) {
      // console.log("TI PriceCALC= " + this.preventivoService.getTipoImpianto());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getTipoImpianto(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getCondizionatoreInstallato() != "" &&
      this.preventivoService.getCondizionatoreInstallato() != null
    ) {
      // console.log("CI PriceCALC= " + this.preventivoService.getCondizionatoreInstallato());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getCondizionatoreInstallato(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getSmontaggio() != "" &&
      this.preventivoService.getSmontaggio() != null
    ) {
      // console.log("SM PriceCALC= " + this.preventivoService.getSmontaggio());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getSmontaggio(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getLavaggio() != "" &&
      this.preventivoService.getLavaggio() != null
    ) {
      //console.log("LA PriceCALC= " + this.preventivoService.getLavaggio());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getLavaggio(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getPredispozisione() != "" &&
      this.preventivoService.getPredispozisione() != null
    ) {
      // console.log("PR PriceCALC= " + this.preventivoService.getPredispozisione());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getPredispozisione(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getStaffa() != "" &&
      this.preventivoService.getStaffa() != null
    ) {
      // console.log("ST PriceCALC= " + this.preventivoService.getStaffa());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getStaffa(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getShuko() != "" &&
      this.preventivoService.getShuko() != null
    ) {
      // console.log("SH PriceCALC= " + this.preventivoService.getShuko());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getShuko(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (this.preventivoService.getMeterRangeMotoreSplit()) {
      //onsole.log("DI PriceCALC= " + this.preventivoService.getDistanzaMotoreSplit());
      //console.log("DI id PriceCALC= " + this.preventivoService.getIdDistanzaMotoreSplit());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getIdDistanzaMotoreSplit(),
        responseValue: this.preventivoService
          .getDistanzaMotoreSplit()
          .toString(),
        responseValue2: this.preventivoService.getMeterRangeMotoreSplit().toString(),
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getProfonditaPareteMaggioreDi40() != "" &&
      this.preventivoService.getProfonditaPareteMaggioreDi40() != null
    ) {
      //console.log("PR PriceCALC= " + this.preventivoService.getProfonditaPareteMaggioreDi40());
      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getProfonditaPareteMaggioreDi40(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getStesseStaffe() != "" &&
      this.preventivoService.getStesseStaffe() != null
    ) {
      // console.log("SST PriceCALC= " + this.preventivoService.getStesseStaffe());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getStesseStaffe(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getDistanzaPredispozisioneCondizionatore() != 0
    ) {
      // console.log("DI 2 PriceCALC= " + this.preventivoService.getDistanzaPredispozisioneCondizionatore());
      //console.log("DI 2 id PriceCALC= " + this.preventivoService.getIdDistanzaPredispozisioneCondizionatore());
      var resp: ResponsePCalc = {
        idWebSiteFlow:
          +this.preventivoService.getIdDistanzaPredispozisioneCondizionatore(),
        responseValue: this.preventivoService
          .getDistanzaPredispozisioneCondizionatore()
          .toString(),
      };
      arrResponse.push(resp);
    }
    if (
      this.preventivoService.getPosizione() != "" &&
      this.preventivoService.getPosizione() != null
    ) {
      //console.log("PO 2 PriceCALC= " + this.preventivoService.getPosizione());
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getPosizione(),
        responseValue: null,
      };
      arrResponse.push(resp);
    }

    if (
      this.preventivoService.getBTU() != "" &&
      this.preventivoService.getInstallationHeight() != ""
    ) {
      //console.log("BTU PriceCALC= " + this.preventivoService.getBTU());
      //console.log("Height PriceCALC= " + this.preventivoService.getInstallationHeight());
      //console.log("installation PriceCALC ID= " + this.preventivoService.getIdInstallation())
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getIdInstallation(),
        responseValue: this.preventivoService.getBTU(),
        responseValue2: this.preventivoService.getInstallationHeight(),
      };
      arrResponse.push(resp);
    }

    if (
      this.preventivoService.getSealedPlant() != ''
      && this.preventivoService.getSealedPlant() != null
    ) {
      var resp: ResponsePCalc = {
        idWebSiteFlow: +this.preventivoService.getSealedPlant(),
        responseValue: null
      };
      arrResponse.push(resp);
    }
    // selezione prodotto
    if (
      this.preventivoService.getProductIdPackage() != ''
      && this.preventivoService.getProductIdPackage() != null
    ) {
      var resp: ResponsePCalc = {
        idWebSiteFlow: parseInt(this.preventivoService.getProductIDWebSiteFlow()),
        responseValue: this.preventivoService.getProductIdPackage(),
        responseValue2: this.preventivoService.getProductPrice(),
      };
      arrResponse.push(resp);
    }
    return arrResponse;
  }

  goBack() {
    this._location.back();
  }

  private handleStandardError(e: any) {
    if (e.status === 401) {
      this.climaSmartService.logoutAndRedirect();
      this.hideLoader = true;
      this.loading = false;
    } else {
      this.modalError.show({
        errorTitle: "Errore",
        errorDetail:
          "Si è verificato un errore imprevisto, la invitiamo a riprovare",
      });
      this.hideLoader = true;
      this.loading = false;
      this.hideButtons = false;
    }
  }
}
