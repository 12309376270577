import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cookie-law',
  templateUrl: './cookie-law.component.html',
  styleUrls: ['./cookie-law.component.css']
})
export class CookieLawComponent implements OnInit {

  @Input()
  cookieLawText: string = '';

  @Input()
  cookieLawLinkText: string = '';

  @Input()
  cookieLawLink: string = '';

  @Input()
  cookieLawButtonText: string = 'Accetta';

  cookieBannerSeen: boolean = false;

  constructor() { }

  get showcookieLawLink(): boolean { if (this.cookieLawLinkText) return true; else return false; }

  ngOnInit() {
    this.cookieBannerSeen = this.exists('clima.cookieConsent');
  }

  public dismiss(): void {
    this.set('clima.cookieConsent');
    this.cookieBannerSeen = this.exists('clima.cookieConsent');
  }

  private exists(name: string): boolean {
    let storage: { expiration: number } = JSON.parse(localStorage.getItem(name));
    return storage != null
      && storage.expiration != null
      && storage.expiration > new Date().getTime();
  }


  private set(name: string): void {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    localStorage.setItem(name, JSON.stringify({ expiration: date.getTime() }));
  }
}
