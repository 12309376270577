import { Component, OnInit } from '@angular/core';
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { Location } from "@angular/common";
import { InstallationData } from "../_models/models";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";

@Component({
  selector: 'app-prodotti',
  templateUrl: './prodotti.component.html',
  styleUrls: ['./prodotti.component.css']
})
export class ProdottiComponent implements OnInit {

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideMessage: boolean = true;

  public QuestionResponseList: Array<any>;
  public ProductResponseList: Array<any>;
  public Question: string = '';
  public ResponseId: string = '';
  public IdQuestion: number = 0;
  public IdPredisposizione: string = '';

  public tipoImpiantoMnemonic: string = '';
  public SelectedProductPrice: string = '';
  public SelectedProductName: string = '';
  public SelectedProductIdPackage: string = '';
  public SelectedProductIDWebSiteFlow: string = '';


  public InstallationForm: FormGroup;
  public installation: InstallationData = new InstallationData();

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private _location: Location,
  ) { }

  ngOnInit() {

    if (this.preventivoService.getProductName()) {
      this.SelectedProductName = this.preventivoService.getProductName();
    }

    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService;

      this.climaSmartService
        .GetQuestionResponseList("prodotti")
        .subscribe(data => {
            (this.QuestionResponseList = data),
              (this.Question = this.QuestionResponseList[0].questionLabel),
              (this.IdQuestion = this.QuestionResponseList[0].idQuestion),
              this.tipoImpiantoMnemonic = this.preventivoService.getTipoImpiantoMnemonic();
              this.IdPredisposizione =  this.preventivoService.getPredispozisione()
            this.tipoImpiantoMnemonic === "singleSplit" ? this.tipoImpiantoMnemonic = 'MON' : this.tipoImpiantoMnemonic === "doubleSplits" ?
              this.tipoImpiantoMnemonic = 'DUA' : this.tipoImpiantoMnemonic = 'TRI'
            this.climaSmartService.GetProductList(this.tipoImpiantoMnemonic, this.IdQuestion, this.IdPredisposizione).subscribe(data => {
              (this.ProductResponseList = data)
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }, err => {
              console.error("ngOnInit Error ", err);
              if (err.status === 401) {
                this.climaSmartService.logoutAndRedirect();
                this.loading = false;
                this.hidePage = false;
                this.hideLoader = true;
              }
            });


              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
          }
          , err => {
            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });

    } catch {
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }

    this.InstallationForm = this._fb.group({
      DataInstallationFormGroup: this._fb.group({
        btu: ["", [Validators.required]],
        height: ["", [Validators.required]],
      })
    });
  }

  selectProduct(event: Event) {
    this.SelectedProductIdPackage = (event.target as HTMLSelectElement).value;
    let findSelectedProductObj = this.ProductResponseList.find(product => product.idPackage === (event.target as HTMLSelectElement).value);
    this.SelectedProductName = findSelectedProductObj.description
    this.SelectedProductPrice = findSelectedProductObj.price
    this.SelectedProductIDWebSiteFlow = findSelectedProductObj.idWebSiteFlow
  }

  isFormValid() {
    if (this.SelectedProductPrice !== '') {
      return true
    }
    return false
  }

  setSelectedProduct() {
    this.preventivoService.setProductName(this.SelectedProductName)
    this.preventivoService.setProductPrice(this.SelectedProductPrice)
    this.preventivoService.setProductIdPackage(this.SelectedProductIdPackage)
    this.preventivoService.setProductIDWebSiteFlow(this.SelectedProductIDWebSiteFlow)

    if (this.preventivoService.isSonOfDigitalInspection()) {
      this.router.navigate(['/disegno']);
      return;
    }

    // TODO: move to configuration
    const answers = [
      { answer: "priceCalculator", route: "/datiPreventivo" },
      { answer: "priceCalculatorWithNumber", route: "/datiSopralluogo" },
      { answer: "priceCalculatorWithEcobonus", route: "/datiPreventivo" }
    ];

    const nextRoute = answers.find((a) => a.answer == this.preventivoService.getPackageType()
      || a.answer == this.preventivoService.getServiceType());
    if (!nextRoute) {
      return;
    }

    this.router.navigate([nextRoute.route]);
  }

  goBack() {
    this.preventivoService.setProductPrice('')
    this.preventivoService.setProductName('')
    this.preventivoService.setProductIdPackage('')
    this.preventivoService.setProductIDWebSiteFlow('')
    this._location.back();
  }

}
