import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-distanza",
  templateUrl: "./distanza.component.html",
  styleUrls: ["./distanza.component.css"]
})
export class DistanzaComponent implements OnInit {
  inputDistanza: string = "";
  inputMeterRange: string = "";
  get meterRanges() {
    const a = [
      { v: "EXCEED_0", label: "Nessuna eccedenza oltre collegamento base", plants: ["singleSplit","doubleSplits", "tripleSplits"] },
      { v: "EXCEED_5", label: "Eccedenza fino 5mt", plants: ["singleSplit","doubleSplits", "tripleSplits"] },
      { v: "EXCEED_12", label: "Eccedenza fino 12mt", plants: ["singleSplit","doubleSplits", "tripleSplits"] },
      { v: "EXCEED_19", label: "Eccedenza fino 19mt", plants: ["singleSplit","doubleSplits", "tripleSplits"] },
      { v: "CUSTOM", label: "Eccedenza oltre 19mt", plants: ["singleSplit","doubleSplits", "tripleSplits"] }
    ];
    return [...a.filter(f => f.plants.findIndex(p => p === this.selectedPlan) !== -1)];
  }

  meterRangeSelected: string = "";
  inputBends = '';
  selectedPlan: string = "";
  public albums: Array<IAlbum>;
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelDistance: string = "";
  public Question: string = "";
  public ResponseIdDistance: string = "";
  public meterRangeDescription: string = '';

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.selectedPlan = this.preventivoService.getTipoImpiantoMnemonic();
    this.albums = [];

    if (this.selectedPlan == preventivoService.getMONO()) {
      //console.log("inside");

      var src = "../assets/images/distanza-mono-L.jpg";
      var caption = "";
      var thumb = "../../assets/images/distanza-mono-S.jpg";
      var album = {
        src: src,
        caption:
          "DISTANZA TRA UNITA’ ESTERNA E UNITA’ INTERNA - Impianto Mono ",
        thumb: thumb
      };

      this.albums.push(album);
    }

    if (this.selectedPlan == preventivoService.getDUAL()) {
      //console.log("inside 2");

      src = "../assets/images/distanza-dual-L.jpg";
      caption = "";
      thumb = "../assets/images/distanza-dual-S.jpg";
      album = {
        src: src,
        caption: "DISTANZA TRA UNITA’ ESTERNA E UNITA’ INTERNA - Impianto Dual",
        thumb: thumb
      };

      this.albums.push(album);
    }

    if (this.selectedPlan == preventivoService.getTRIAL()) {
      src = "../assets/images/distanza-trial-L.jpg";
      caption = "";
      thumb = "../assets/images/distanza-trial-L.jpg";
      album = {
        src: src,
        caption:
          "DISTANZA TRA UNITA’ ESTERNA E UNITA’ INTERNA - Impianto Trial",
        thumb: thumb
      };

      this.albums.push(album);
    }
  }


  selectMetersRange(event: any) {
    console.log(event);
    this.meterRangeSelected = event;
    this.inputMeterRange = event;
    this.inputDistanza = '';
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  ngOnInit() {

    //console.log("getDistanzaMotoreSplit", this.preventivoService.getDistanzaMotoreSplit())
    if (this.preventivoService.getDistanzaMotoreSplit()) {
      this.inputDistanza = this.preventivoService.getDistanzaMotoreSplit().toString();
      this.meterRangeSelected = this.preventivoService.getMeterRangeMotoreSplit();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    // console.log("inside distnace ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList(window.location.pathname.indexOf('distanzaSplit') >= 0 ? 'distanzaSplit' : 'distanza')
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelDistance = this.QuestionResponseList[0].responseLabel),
            (this.ResponseIdDistance = this.QuestionResponseList[0].idWebSiteFlow),
            //console.log("xxxx", this.QuestionResponseList, this.ResponseIdDistance);
            this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });

    } catch {
      //  console.log("inside catch");
      // console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  //  onKey(event: any) {
  //      this.inputDistanza +=  event.target.inputDistanza;

  //     console.log("Distance : ", parseInt(this.inputDistanza))
  //    }

  setDistanzaMotoreSplit() {
    this.preventivoService.setMeterRangeMotoreSplit(this.meterRangeSelected);
    if (this.meterRangeSelected === 'CUSTOM') {
      this.preventivoService.setDistanzaMotoreSplit(parseInt(this.inputDistanza));
    }
    this.preventivoService.setIdDistanzaMotoreSplit(this.ResponseIdDistance);
    this.router.navigate([this.QuestionResponseList[0].pageNameNext]);
  }

  onBlurMethod() {
    //console.log("Distance : ", this.inputDistanza);
  }

  goBack() {
    this.preventivoService.setMeterRangeMotoreSplit('');
    this.preventivoService.setDistanzaMotoreSplit(0);
    this._location.back();
  }

  canGoOn() {
    return this.inputDistanza === 'NONE'
      || (this.inputDistanza !== '' && +this.inputDistanza !== 0 && this.inputMeterRange === 'CUSTOM')
      || (this.inputMeterRange !== 'CUSTOM' && this.inputMeterRange !== '');
  }
}
