import { Component, OnInit } from '@angular/core';
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { Location } from "@angular/common";
import { DimensioniTetto, InstallationData } from "../_models/models";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";

@Component({
  selector: 'app-dimensionitetto',
  templateUrl: './dimensionitetto.component.html',
  styleUrls: ['./dimensionitetto.component.css']
})
export class DimensionitettoComponent implements OnInit {

  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;
  hideMessage: boolean = true;
  btuSelected: string = '';
  heightSelected: string = '';

  public heightAbove200: string = null;
  public QuestionResponseList: Array<any>;
  public Question: string = '';
  public ResponseId: string = '';

  heightDescription: string = '';
  answer: any;

  public InstallationForm: FormGroup;
  public dimensioni: DimensioniTetto = new DimensioniTetto();
  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private _location: Location,
  ) { }

  ngOnInit() {

    if (this.preventivoService.getDimensioniTettoSelected()) {
      this.dimensioni.dim = this.preventivoService.getDimensioniTettoSelected();
      if (this.dimensioni.dim  == 'GT600') {
        this.hideMessage = false
      }
      else {
        this.hideMessage = true
      }
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("Dimensionitetto")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseId = this.QuestionResponseList[0].idWebSiteFlow),
            this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
          , err => {

            console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });

      this.climaSmartService.GetTranslation('HEIGHT_ABOVE_200')
        .subscribe(data => {
          this.heightDescription = data.translation;
        }, err => {
          if (err.status === 404) {
            this.heightDescription = null;
          }
        });
    } catch {
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }


    this.InstallationForm = this._fb.group({
      DataInstallationFormGroup: this._fb.group({
        height: ["", [Validators.required]]
      })
    });
  }
  isFormValid() {
    if (this.dimensioni.dim) {
      return true
    }
    return false
  }
  selectValue(event: any) {
    this.dimensioni.dim = event.target.value;
    this.answer = this.QuestionResponseList.find(qr => qr.idWebSiteFlow.toString() === this.dimensioni.dim)

    
  }
  setInstallation() {

    this.preventivoService.setDimensioniTettoSelected(this.dimensioni.dim)
    this.preventivoService.setIdDimensioni(this.ResponseId)
      var nextRoute: string = this.QuestionResponseList[0].pageNameNext
      this.router.navigate([nextRoute]);

  }

  goBack() {
    this.preventivoService.setDimensioniTettoSelected('');
    this.preventivoService.setIdDimensioni('');
    this._location.back();
  }

}
