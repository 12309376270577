import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalLoginComponent } from "../modal-login/modal-login.component";
import { Login } from "../_models/models";
import { Router, ActivatedRoute } from "@angular/router";
import { PreventivoService } from "../preventivo.service";
import { print } from "util";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"]
})
export class MenuComponent implements OnInit {
  @ViewChild(ModalLoginComponent) public ModalLogin: ModalLoginComponent;
  constructor(
    public router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService) { }

  ngOnInit() {
  }

  loginModal() {
    this.ModalLogin.show({
      title: "Inserisci Username e Password",
      titleResetPassword:
        "Inserisci la tua mail di registrazione e ti invieremo una mail con le istruzioni per poter accedere"
    });
  }

  isMenuHidden(): boolean {
    if (localStorage.getItem("userToken")) {
      return false;
    } else {
      return true;
    }
  }

  isAreaRiservataHidden(): boolean {
    const currentUrl = this.router.url;
    if(currentUrl === '/login'){
      return true
    } else if(localStorage.getItem("userToken")){
      return true
    } else {
      return false
    }
  }


  logOut() {
    try {
      this.preventivoService.clear();
      let reloadPage = false;
      if (window.location.href.endsWith('/')) {
        reloadPage = true;
      }
      this.climaSmartService.logoutAndRedirect(reloadPage, false);

    } catch (ex) {
      console.exception("logOut Exception " + ex);
    }
  }

  newPreventive() {

    this.preventivoService.clear();

  }
}
