import { AbstractControl } from "@angular/forms";
import { Util } from "./../util";

const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const specials = " !\"#$%& '()*+,-./:;<=>?@[]^_`{|}~";

export class PasswordValidators {
  public static isPassword(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (Util.isNotPresent(control)) return null;

    let password = control.value;
    var passwordUpper = password.toUpperCase();

    var isValid1 = false;
    for (var i = 0; i < passwordUpper.length; i++) {
      if (chars.indexOf(passwordUpper.charAt(i)) > -1) {
        isValid1 = true;
        break;
      }
    }

    if (!isValid1) return { passwordalpha: true };

    var isValid2 = false;
    for (var i = 0; i < passwordUpper.length; i++) {
      if (specials.indexOf(passwordUpper.charAt(i)) > -1) {
        isValid2 = true;
        break;
      }
    }

    if (!isValid2) return { passwordspecial: true };

    var isValid3 = false;
    for (var i = 2; i < passwordUpper.length; i++) {
      if (
        passwordUpper.charAt(i) === passwordUpper.charAt(i - 1) &&
        passwordUpper.charAt(i) === passwordUpper.charAt(i - 2)
      )
        break;
      if (i == passwordUpper.length - 1) isValid3 = true;
    }

    if (!isValid3) return { passwordconsecutive: true };

    ////currentPassword
    const currentpassword = control.parent.controls["oldPassword"];
    if (currentpassword.valid && currentpassword.touched) {
      var isValid4 = false;
      for (var i = 2; i < passwordUpper.length; i++) {
        if (
          currentpassword.value.indexOf(passwordUpper.substring(i - 2, 3)) == -1
        ) {
          isValid4 = true;
          break;
        }
      }

      if (!isValid4) return { passworddiffer: true };
    }

    var isValid5 = false;
    if (passwordUpper.indexOf(" ") == -1) isValid5 = true;

    if (!isValid5) return { passwordspace: true };

    return null;
  }
}
