import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class TokenCheckGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("userToken") !== null) {
      const token: any = jwt_decode(localStorage.getItem("userToken"));

      if (!token) {
        localStorage.removeItem("userToken");
        this.router.navigate(["/login"]);
        return false;
      }

      const exp = token.exp;
      if (!exp) {
        this.router.navigate(["/login"]);
        localStorage.removeItem("userToken");
        return false;
      }

      const now = Date.now().valueOf() / 1000;
      if (exp < now) {
        this.router.navigate(["/login"]);
        localStorage.removeItem("userToken");
        return false;
      }
      return true;
    }
    this.router.navigate(["/login"]);
    return false;
  }
}
