import { Component, OnInit, ɵConsole } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-stesse-staffe",
  templateUrl: "./stesse-staffe.component.html",
  styleUrls: ["./stesse-staffe.component.css"]
})
export class StesseStaffeComponent implements OnInit {
  optionStessaStaffa: string = "";
  public albums: Array<IAlbum>;
  optionPredisposizione: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelSI: string = "";
  public ResponseLabelNO: string = "";
  public ResponseLabelSI2: string = "";
  public ResponseLabelSI3: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdNO: string = "";
  public ResponseIdSI2: string = "";
  public ResponseIdSI3: string = "";
  public Question: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.optionPredisposizione = this.preventivoService.getPredispozisione();

    this.albums = [];

    var src = "../assets/images/3DSTAFFA-L.jpg";
    var caption = "";
    var thumb = "../assets/images/3DSTAFFA-S.jpg";
    var album = {
      src: src,
      caption: "STAFFA",
      thumb: thumb
    };

    this.albums.push(album);
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  goBack() {
    this.preventivoService.setStesseStaffe('');
    this._location.back();
  }

  ngOnInit() {

   // console.log("inside ngOnInIt")

    //console.log("getStaffa", this.preventivoService.getStesseStaffe())
    if (this.preventivoService.getStesseStaffe()) {
      this.optionStessaStaffa = this.preventivoService.getStesseStaffe();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
   // console.log("inside same support ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
     // console.log("inside try", this.hidePage);
      this.climaSmartService
        .GetQuestionResponseList("staffaEsistente")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
            (this.ResponseLabelSI2 = this.QuestionResponseList[2].responseLabel),
            // (this.ResponseLabelSI3 = this.QuestionResponseList[3].responseLabel),
            (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
            (this.ResponseIdSI2 = this.QuestionResponseList[2].idWebSiteFlow),
            // (this.ResponseIdSI3 = this.QuestionResponseList[3].idWebSiteFlow),
          //  console.log("xxxx", this.QuestionResponseList, this.hidePage);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
        , err => {

          console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
     // console.log("inside catch");
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  //  console.log("inside ggggggggggggggggggggggggggggggggg", this.hidePage);
  }

  //GetQuestionResponseListSameSupportPage;

  isChecked(selected: any) {

  // console.log("isChecked value", selected)

    if (selected == this.preventivoService.getStesseStaffe()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  //event andler
  radioChangeHandler(event: any) {
    this.optionStessaStaffa = event.target.value;
    //console.log(" selected Stessa Staffa: ", this.optionStessaStaffa);
  }

  setStesseStaffe() {
    this.preventivoService.setStesseStaffe(this.optionStessaStaffa);
    // this.router.navigate(["/predisposizione-condizionatore"]);
    this.router.navigate([this.QuestionResponseList[0].pageNameNext]);
  }
}
