import { Component, OnInit, ViewChild } from '@angular/core';
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { ClimaSmartService } from "../_service/clima-smart.service";
import { SopralluogoData } from "../_models/models";
import { Location } from "@angular/common";
import { ModalErrorComponent } from "../modal-error/modal-error.component";

import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

@Component({
  selector: 'app-split-selection',
  templateUrl: './split-selection.component.html',
  styleUrls: ['./split-selection.component.css']
})
export class SplitSelectionComponent implements OnInit {


  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;

  optionSplitSelected: string = "";
  loading: boolean = false;
  hidePage: boolean = false;
  hideLoader: boolean = true;

  public ResponseList: any;

  public QuestionResponseList: Array<any>;
  public ResponseLabelPreventivo: string = "";
  public ResponseLabelSopralluogo: string = "";
  public ResponseLabelpreventivoConNumero: string = "";
  public ResponseIdPreventivo: string = "";
  public ResponseIdSopralluogo: string = "";
  public ResponseIdpreventivoConNumero: string = "";
  public ResponseIdOtherServices: string = "";
  public Question: string = "";

  inputSopralluogo: string = "";
  public DataSopralluogoForm: FormGroup;
  public Sopralluogo: SopralluogoData = new SopralluogoData();
  constructor(
    private _fb: FormBuilder,
    private router: Router,
    public preventivoService: PreventivoService,
    private climaSmartService: ClimaSmartService,
    private _location: Location
  ) { }

  ngOnInit() {


    console.log("OtherServicesComponent", this.preventivoService.getSplitSelection());
    if (this.preventivoService.getSplitSelection()) {
      this.optionSplitSelected = this.preventivoService.getSplitSelection();
    }

    console.log("SplitSelectionComponent ngOnInit");
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;

    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      console.log("inside try", this.hidePage);

      this.climaSmartService.GetQuestionResponseList("selezioneSplit").subscribe(
        (data) => {
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;

          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            console.log("QuestionResponseList", this.QuestionResponseList);
        },
        (err) => {
          // console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        }
      );
    } catch {
      // console.log("inside catch");
      //console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {
    console.log("isChecked", selected, "getSplitSelection", this.preventivoService.getSplitSelection());
    if (selected == this.preventivoService.getSplitSelection()) {
      return true;
    }



    return false;
  }

  //event andler
  radioChangeHandler(event: any) {
    this.optionSplitSelected = event.target.value;
    console.log("Selected option is: ", this.optionSplitSelected);
  }


  setOtherServiceSplit() {
    this.preventivoService.setSplitSelection(this.optionSplitSelected);
    console.log("selected other service split ", this.optionSplitSelected);
    this.router.navigate(["/datiCliente"]);

  }

  goBack() {
    this.preventivoService.setSplitSelection('');
    this._location.back();
  }

}
