import { AfterViewInit ,Component, OnInit, ViewChild } from "@angular/core";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { PageEvent } from '@angular/material/paginator';
import { ChangeDetectorRef } from '@angular/core';

import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl
} from "@angular/forms";
import { Router } from '@angular/router';
import { ClimaSmartService } from "../_service/clima-smart.service";
import { defineLocale } from "ngx-bootstrap/chronos";
import { itLocale } from "ngx-bootstrap/locale";
import { SearchtData, ModalConfirm } from "../_models/models";
import { DatePipe } from '@angular/common';
import { Parameters } from "../_service/parameters";
import { getDate } from "ngx-bootstrap/chronos/utils/date-getters";
//import { access } from "fs";
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { ModalListComponent } from "../modal-list/modal-list.component";
import { ListItemWithAction } from "../_models/list-item-with-action";
import { ModalListNoteComponent } from "../modal-list-note/modal-list-note.component";
import { environment } from "src/environments/environment"
import { ModalPickupPointComponent } from "../modal-pickup-point/modal-pickup-point.component";
import { ModalVendorReferenceComponent } from '../modal-vendor-reference/modal-vendor-reference.component';
import { ModalDirective } from "ngx-bootstrap/modal";
import {debounceTime} from 'rxjs/operators';
import {HttpEventType} from "@angular/common/http";
defineLocale("it", itLocale);

@Component({
  selector: "app-ricerca",
  templateUrl: "./ricerca.component.html",
  styleUrls: ["./ricerca.component.css"]
})

export class RicercaComponent implements OnInit, AfterViewInit{

  @ViewChild(ModalPickupPointComponent) public ModalPickup: ModalPickupPointComponent;
  @ViewChild(ModalVendorReferenceComponent) public ModalVendorRefrence: ModalVendorReferenceComponent;
  @ViewChild(ModalErrorComponent) public modalError: ModalErrorComponent;
  @ViewChild('childModal') public childModal: ModalDirective;

  modal: ModalConfirm = new ModalConfirm();

  loadingModal: boolean = false;

  showMessageErr: boolean = false;
  messageErr: string = '';

  confirmationOK: boolean = false;


  @ViewChild(ModalListComponent) public modalAttachments: ModalListComponent;
  @ViewChild(ModalListNoteComponent) public modalListNote: ModalListNoteComponent;

  today = new Date();


  displayedColumns: string[] = environment.searchColumns;

  private loadingButtons: string[] = [];

  attachmentFor: any;
  currentAttachments: ListItemWithAction[] = [];
  loadingAttachments: boolean;
  donwloadingAttachments: { id: string, loading: boolean }[] = [];

  applyFilter = new FormControl('');


  applyFilterEcobonus(filterValue: any) {

    if (filterValue.checked) {
      this.ecobonusOnly = true;
      //this.searchOffset = 0

    } else {
      this.ecobonusOnly = false;
    }

    this.ricerca()
  }


  dataSource = new MatTableDataSource<PeriodicElement>();
  pipe: DatePipe;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  SearchForm: FormGroup;

  loading: boolean = false;
  hideTable: boolean = true;
  hideMessage: boolean = true;
  tableHead: boolean = false;
  hideLoader: boolean = true;
  lists: Array<any>;

  selectedDateStart: Date = null;
  selectedDateEnd: Date = null;


  ecobonusOnly: boolean;
  searchTerm : string;
  searchTotalItem : number;
  searchPageSize : number;
  searchCurrentPage : number = 0;
  searchOffset : number = 0

  ticket: string = '';
  idWebSiteFlowTracking: number;

  PriceCalcListStoreList: Array<any>;
  PriceCalcListStoreListWithDate: Array<any>;

  uploadedFile = null
  filename:string = ''
  filesize:number = 0
  uploading:boolean = false

  uploadProgress = 0;
  error = null;

  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(
    private _fb: FormBuilder,
    private localeService: BsLocaleService,
    private climaSmartService: ClimaSmartService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.pipe = new DatePipe('en');
    localeService.use("it");

    this.datePickerConfig = Object.assign(
      {},

      {
        containerClass: "theme-dark-blue",
        showWeekNumbers: false,
        dateInputFormat: "DD/MM/YYYY"
      }
    );
  }


  isDownloadinAttachment(item: ListItemWithAction): boolean {
    return this.donwloadingAttachments.findIndex(a => a.id === item.id && a.loading) >= 0;
  }



  ngOnInit() {

    this.applyFilter.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(value => {
      if(value.length >= 3) {
        this.searchTerm = value;
        this.ricerca()
      }
      if(!value.length) {
        this.searchTerm = '';
        this.ricerca()
      }
    })

    this.idTicketItemLogType = environment.shopTicketNoteType;
    this.climaSmartService.downloadingAttachment$.subscribe(o => {

      let item = this.donwloadingAttachments.find(a => a.id === o.id);
      if (!item) {
        item = { id: o.id, loading: false }
        this.donwloadingAttachments.push(item);
      }

      item.loading = o.loading;

    });

    this.SearchForm = this._fb.group({
      ////subDiv: this._fb.group({
      dateStart: ['', []],
      dateEnd: ['', []]
    });

    this.loadTable();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  pageChanged(event: PageEvent) {
    this.searchPageSize = event.pageSize;
    this.searchCurrentPage = event.pageIndex;
    this.searchOffset = (this.searchPageSize * (this.searchCurrentPage + 1)) - this.searchPageSize;
    this.changePage(this.searchPageSize, this.searchOffset, this.ecobonusOnly, this.searchTerm);
  }

  loadTable() {
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hideTable = true;
      this.climaSmartService
        .GetPriceCalcListStore()
        .subscribe(data => {
          this.PriceCalcListStoreList = data.items;
          this.PriceCalcListStoreList.forEach(function (element) {
            element.completeName = element.firstName + ' ' + element.lastName;
            element.bollettina = element.sellVendorReference;
          });

          this.searchTotalItem = data.total
          this.searchPageSize = data.limit
          this.paginator.pageIndex = this.searchCurrentPage
          this.paginator.length = data.count
          this.paginator.pageSize = data.limit
          this.dataSource = new MatTableDataSource(this.PriceCalcListStoreList);
          this.loading = false;
          this.hideTable = false;
          this.hideLoader = true;
        }
          , err => {

            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hideTable = false;
              this.hideLoader = true;
            }
          }
        );
    } catch (ex) {

      this.loading = true;
      this.hideTable = true;
      this.hideLoader = false;
    }

  }

  ricerca() {

    this.searchCurrentPage = 0;
    this.searchOffset = 0;
    if(this.ecobonusOnly === false){
    this.searchTotalItem = 0;
    }
    this.loading = true;
    this.hideLoader = false;
    this.hideTable = true;

    try {
      if (this.SearchForm.get('dateStart').value !== null && this.SearchForm.get('dateStart').value.toString('yyyy-MM-dd') !== '') {
        this.selectedDateStart = this.SearchForm.get('dateStart').value;
      }

      if (this.SearchForm.get('dateEnd').value !== null && this.SearchForm.get('dateEnd').value.toString('yyyy-MM-dd') !== '') {
        this.selectedDateEnd = this.SearchForm.get('dateEnd').value;
        this.selectedDateEnd.setHours(23);
      }

      if (this.SearchForm.get('dateEnd').value && this.SearchForm.get('dateEnd').value.toString('yyyy-MM-dd') == "")

      if (!this.selectedDateEnd) this.selectedDateEnd = null

      this.climaSmartService
        .GetPriceCalcListStoreWithDate(this.selectedDateStart, this.selectedDateEnd, this.searchPageSize,  this.searchOffset, this.ecobonusOnly, this.searchTerm)
        .subscribe(data => {
          this.PriceCalcListStoreListWithDate = data.items;
          this.PriceCalcListStoreListWithDate.forEach(function (element) {
            element.completeName = element.firstName + ' ' + element.lastName;
            element.bollettina = element.sellVendorReference;
          });

          this.searchTotalItem = data.total
          this.searchPageSize = data.limit

          //pagination
            this.paginator.length = this.searchTotalItem;
            this.paginator.pageSize = this.searchPageSize;
            this.paginator.pageIndex = this.searchCurrentPage;

          this.dataSource = new MatTableDataSource(this.PriceCalcListStoreListWithDate);
          this.loading = false;
          this.hideTable = false;
          this.hideLoader = true;

            // Forzo il rilevamento dei cambiamenti dei dati
            this.cdr.detectChanges();

        }, err => {
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hideTable = false;
              this.hideLoader = true;
            }
          }
        );
    } catch (ex) {
      console.log('Exception', ex);

    }

  }




  reset() {
    this.SearchForm = this._fb.group({
      dateStart: ['', []],
      dateEnd: ['', []]
    });
    this.applyFilter.setValue('');
    this.selectedDateStart = null;
    this.selectedDateEnd = null;
    this.searchTerm = ""
    this.ricerca()
  }

  public downloadPdf(idWebSiteFlowTracking) {
    this.climaSmartService.openPdf(idWebSiteFlowTracking);
  }

  public applyAction(item: ListItemWithAction) {
    console.log(item);
    this.isDownloadinAttachment(item);
    if (!this.isDownloadinAttachment(item)) {
      item.action();
    }
  }

  public openAttachments(idWebSiteFlowTracking, ticket) {
    this.modalAttachments.show({ title: "Documenti ticket " + ticket, subtitle: '' });
    this.attachmentFor = idWebSiteFlowTracking;
    this.loadingAttachments = true;
    this.climaSmartService.getPdfList(idWebSiteFlowTracking, ticket).subscribe(
      o => {

        if (this.attachmentFor === idWebSiteFlowTracking) {
          this.attachmentFor = null;
          let arr: { fileType: string, fileName: string, idAttachment: number, attachmentType: string }[] = o;
          this.currentAttachments = []
          this.donwloadingAttachments = []
          for (let a of arr) {
            const indexof = a.fileName.lastIndexOf('.')
            const ext = a.fileName.substring(indexof);
            let img = '';
            switch (a.fileType) {

              case 'pdf':
                img = '../../assets/images/pdf-files.png';
                break;
              case 'png':
              case 'apng':
              case 'jpg':
              case 'jpeg':
              case 'gif':
                img = '../../assets/images/img-files.png';
                break;

              default:
                img = '../../assets/images/gen-files.png';
                break;

            }

            this.currentAttachments.push({ id: `${ticket}_${a.idAttachment}`, icon: img, description: a.attachmentType, action: () => { this.climaSmartService.downloadDocument(idWebSiteFlowTracking, ticket, a.idAttachment, `${a.attachmentType}${ext}`) } });
          }

          this.loadingAttachments = false;
        }

      }, err => {

        if (this.attachmentFor === idWebSiteFlowTracking) {
          this.modalAttachments.hide();
          this.loadingAttachments = false;
        }

      }
    )
  }

  isReprinting(element: PeriodicElement): boolean {
    return this.loadingButtons.findIndex(x => x === element.ticket) != -1;
  }

  //Note List
  loadingNotes: boolean = false
  notesList = []
  idTicketItem: number = 0
  pageSize: number = 5
  page: number = 1
  //pagination start and end note numbers in table
  start : number = 0
  end: number = 0
  total: number = 0
  totalPages : number = 0
  showErrorMsg : boolean
  errorCode : number
  idTicketItemLogType;

  //open Note list modal
   public openNotes(ticket, idTicketItem){
    this.page = 1;
    this.idTicketItem = idTicketItem
    this.modalListNote.show({ ticket: "Note - " + ticket });
    this.getNotesList(this.idTicketItem, this.page, this.pageSize)

    }

    //Next page
    nextPage(){
      this.page = this.page + 1
      this.getNotesList(this.idTicketItem, this.page, this.pageSize)
    }

    //Previous page
    prevPage(){
      this.page = this.page - 1
      this.getNotesList(this.idTicketItem, this.page, this.pageSize)
    }

    getNotesList(idTicketItem, page, pageSize){
      this.resetModalNote()
         this.loadingNotes = true;
         this.climaSmartService
          .getNotesList(idTicketItem, page, pageSize, this.idTicketItemLogType)
          .subscribe(data => {
            this.notesList = data.items
            this.total = data.total
            this.getTotalPages(this.total, pageSize)
            this.getPagination(page, pageSize)
            this.loadingNotes = false;

          }
            , err => {
              if (err.status === 401) {
                this.climaSmartService.logoutAndRedirect();
              }
                else if(err.status === 400 || err.status === 404){
                this.showErrorMsg = false
              }

              // else if(err.status === 404){
              //   this.showErrorMsg = false
              // }

              else if(err.status === 500){
                this.showErrorMsg = true
              }

             else {
               this.showErrorMsg = true
             }

              this.loadingNotes = false;
            }
          );
    }

    //calculate total pages
    getTotalPages(total, pageSize){
       this.totalPages = Math.ceil(total / pageSize)
       this.loadingNotes = false;
    }

    //calculate pagination
    getPagination(page, pageSize){
      this.start = (page - 1) * pageSize + 1
      this.end = this.total

      if (pageSize < this.total) {
        this.end = pageSize * page
        if (this.end > this.total) {
          this.end = this.total
        }
      }

    }

    //reset Note list modal
    resetModalNote(){
      this.notesList = [];
      this.showErrorMsg = false;
      this.start = 0,
      this.end = 0
    }

    //reload Note List
    reloadNoteList(idTicketItem, page, pageSize){
      this.getNotesList(idTicketItem, page, pageSize)
    }

  openModalPickupPoint(idWebSiteFlowTracking, ticket) {

    console.log('openModalPickupPoint', idWebSiteFlowTracking, ticket);
    this.ModalPickup.show(idWebSiteFlowTracking, ticket);

  }

  openModalPaymentConfirm(idWebSiteFlowTracking, ticket, service, bollettina) {
    console.log('openModalPaymentConfirm', idWebSiteFlowTracking, service, ticket, bollettina);
    this.show(idWebSiteFlowTracking, ticket, {
      title: 'Conferma Vendita',
      details: ''
    });
  }

  openModalVendorRefrence(idWebSiteFlowTracking, ticket) {

    console.log('openModalVendorRefrence', idWebSiteFlowTracking, ticket);
    this.ModalVendorRefrence.show(idWebSiteFlowTracking, ticket);
  }

  handleFile(files){
     this.uploadedFile = files
    let fileToUpload = <File>this.uploadedFile[0];
    this.filename = fileToUpload.name;
    this.filesize = fileToUpload.size;
  }

  handleFileInput(){
     let inputElement = document.getElementById('inputFile')
    if(inputElement){
      inputElement.click()
    }
  }



  refreshTableMehtod() {
    console.log('refreshTableMehtod', this.selectedDateStart);
    if (this.selectedDateStart !== undefined) {
      this.ricerca();
    } else {
      this.loadTable();
    }
  }

  show(idWebSiteFlowTracking, ticket, modal: ModalConfirm) {

    console.log('show confirm payment', idWebSiteFlowTracking, ticket);
    this.ticket = ticket;
    this.idWebSiteFlowTracking = idWebSiteFlowTracking;
    this.modal = modal;
    this.childModal.show();
  }

  hide() {
    this.loadingModal = false;
    this.childModal.hide();
    this.showMessageErr = false;

    this.uploadedFile = null
    this.filename = ''
    this.filesize = 0
    this.uploading = false

    this.uploadProgress = 0;
    this.error = null;
  }

  confirmPayment(idWebSiteFlowTracking) {
    this.loadingModal = true;

    let fileToUpload = <File>this.uploadedFile[0];

    if (fileToUpload.type !== 'application/pdf') {
      this.loadingModal = false;
      this.showMessageErr = true;
      this.messageErr = 'Selezione un file PDF.';
      this.uploading = false;
      this.uploadedFile = null
      this.filename = ''
      return;
    }

    const formData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    this.uploading = true;

    this.climaSmartService.uploadPdfContract(formData).subscribe(
      (event) => {

        try {
          this.climaSmartService
            .SetPaymentConfirmation(idWebSiteFlowTracking, event.idAttachment)
            .subscribe(data => {
                if (data.message === 'OK') {
                  this.loadingModal = false;
                  console.log('data', data.message);
                  if (this.selectedDateStart !== undefined) {

                    this.ricerca();
                    this.hide();
                  }
                  else {
                    this.loadTable();
                    this.hide();
                  }
                }
                else {
                  this.loadingModal = false;
                  this.showMessageErr = true;
                  this.messageErr = data.message;

                  this.uploadedFile = null
                  this.filename = ''
                  this.filesize = 0
                  this.uploading = false
                }
              },
              err => {
                this.loadingModal = false;
              }
            );
        } catch (ex) {
          this.loadingModal = false;
        }

      },
      (e) => {
        console.log(e);
        this.uploadProgress = 0;
        this.uploading = false;
        this.error = e.error;
      },
      () => {
        console.log('Upload Complete')
      }
    );
  }

  changePage(limit, offset, ecobonusOnly, searchTerm) {
    this.loading = true;
    this.hideLoader = false;
    this.hideTable = true;

    try {
      if (this.SearchForm.get('dateStart').value !== null && this.SearchForm.get('dateStart').value.toString('yyyy-MM-dd') !== '') {
        this.selectedDateStart = this.SearchForm.get('dateStart').value;
      }

      if (this.SearchForm.get('dateEnd').value !== null && this.SearchForm.get('dateEnd').value.toString('yyyy-MM-dd') !== '') {
        this.selectedDateEnd = this.SearchForm.get('dateEnd').value;
        this.selectedDateEnd.setHours(23);
      }

      if (!this.selectedDateEnd) this.selectedDateEnd = null

      this.climaSmartService
        .GetPriceCalcListStoreWithDate(this.selectedDateStart, this.selectedDateEnd, limit, offset, ecobonusOnly, searchTerm)
        .subscribe(data => {
          this.PriceCalcListStoreListWithDate = data.items;
          this.PriceCalcListStoreListWithDate.forEach(function (element) {
            element.completeName = element.firstName + ' ' + element.lastName;
            element.bollettina = element.sellVendorReference;
          });

          this.searchTotalItem = data.total
          this.searchPageSize = data.limit
          // pagination
          this.paginator.pageIndex = this.searchCurrentPage;
          this.paginator.pageSize = data.limit;
          this.dataSource = new MatTableDataSource(this.PriceCalcListStoreListWithDate);

          this.loading = false;
          this.hideTable = false;
          this.hideLoader = true;

          // Forzo il rilevamennto dei cambiamenti
          this.cdr.detectChanges();
        }, err => {
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
          }
          this.loading = false;
          this.hideTable = false;
          this.hideLoader = true;
        });
    } catch (ex) {
      console.log('Exception', ex);
    }
  }

}

export interface PeriodicElement {
  insertDate: string;
  ticket: string;
  inspectionCode: string;
  extWarranty: number;
  bollettina: string;
  ticketType: string;
  service: string;
  status: string;
  appointmentDate: string;
  cancelDate: string;
  estimateTot: number;
  fullName: string;
  idWebSiteFlowTracking: number;
  reprintable: boolean;
  ecobonus: boolean;
  idTicketItem: string;
  afsName: string;
  sellConfirmation: boolean;
  availability: boolean;
  inspectionReference: string;

}
