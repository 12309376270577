import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-smontaggio",
  templateUrl: "./smontaggio.component.html",
  styleUrls: ["./smontaggio.component.css"]
})
export class SmontaggioComponent implements OnInit {
  optionSmontaggio: string = "";
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelSI: string = "";
  public ResponseLabelNO: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdNO: string = "";
  public Question: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) { }

  ngOnInit() {

    //  console.log("inside ngOnInIt")

    console.log("getSmontaggio", this.preventivoService.getSmontaggio())
    if (this.preventivoService.getSmontaggio()) {
      this.optionSmontaggio = this.preventivoService.getSmontaggio();
      console.log('getSmontaggio yyyyy', this.optionSmontaggio)
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
    //console.log("inside Disassembly ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("smontaggio")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
            (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
            //  console.log("xxxx", this.QuestionResponseList);
            this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
          , err => {

            //console.error("ngOnInit Error ", err);
            if (err.status === 401) {
              this.climaSmartService.logoutAndRedirect();
              this.loading = false;
              this.hidePage = false;
              this.hideLoader = true;
            }
          });
    } catch {
      //console.log("inside catch");
      // console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

    //console.log("isChecked value smontaggio", selected)

    if (selected == this.preventivoService.getSmontaggio()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }


  //event andler
  radioChangeHandler(event: any) {
    this.optionSmontaggio = event.target.value;
    // console.log("SMONTAGGIO is: ", this.optionSmontaggio);
  }

  setSmontaggio() {
    this.preventivoService.setSmontaggio(this.optionSmontaggio);
    var nextRoute: string = this.QuestionResponseList[0].pageNameNext;
    if (this.optionSmontaggio == this.ResponseIdSI) {
      this.router.navigate(['./datiInstallazione']);
      // console.log('iff', this.ResponseIdSI, this.optionSmontaggio, this.ResponseIdNO)
      // this.router.navigate(['/datiInstallazione']);
      // if (this.preventivoService.getFirstQuestion() == "50") {
      //   console.log("inside if")
      //   // nextRoute = this.QuestionResponseList[1].pageNameNext;
      //  //nextRoute = "/datiPreventivo";
      //   nextRoute = "/datiInstallazione";
      // }

      // else {

      //  // console.log("inside else")
      //   nextRoute = "/datiSopralluogo";
      // }
    }
    else {
    this.router.navigate([nextRoute]);
    //this.router.navigate(['./datiInstallazione']);
  }
}

goBack() {
  this.preventivoService.setSmontaggio('');
  this._location.back();
}
}
