import { Component, OnInit } from "@angular/core";
import { PreventivoService } from "../preventivo.service";
import { Router } from "@angular/router";
import { Lightbox, IAlbum } from "ngx-lightbox";
import { Location } from "@angular/common";
import { ClimaSmartService } from "../_service/clima-smart.service";

@Component({
  selector: "app-predisposizione-condizionatore",
  templateUrl: "./predisposizione-condizionatore.component.html",
  styleUrls: ["./predisposizione-condizionatore.component.css"]
})
export class PredisposizioneCondizionatoreComponent implements OnInit {
  optionPosizione: string = "";
  selectedPlan: string = "";
  inputDistanza: string = "";
  // public albums: Array<IAlbum>;
  showInputImage: boolean = false;
  loading: boolean = true;
  hidePage: boolean = true;
  hideLoader: boolean = false;

  public QuestionResponseList: Array<any>;
  public ResponseLabelSI: string = "";
  public ResponseLabelNO: string = "";
  public ResponseIdSI: string = "";
  public ResponseIdNO: string = "";
  public Question: string = "";

  constructor(
    private router: Router,
    public preventivoService: PreventivoService,
    private _lightbox: Lightbox,
    private _location: Location,
    private climaSmartService: ClimaSmartService
  ) {
    this.selectedPlan = this.preventivoService.getTipoImpiantoMnemonic();

    // this.albums = [];

    // if (this.selectedPlan == preventivoService.getMONO()) {
    //   console.log("inside");

    //   var src = "../assets/images/mono-predisposizione3D.jpg";
    //   var caption = "";
    //   var thumb = "../assets/images/mono-predisposizione3D-S.jpg";
    //   var album = {
    //     src: src,
    //     caption: "PREDISPOSIZIONE - Impianto Mono",
    //     thumb: thumb
    //   };

    //   this.albums.push(album);
    // }

    // if (this.selectedPlan == preventivoService.getDUAL()) {
    //   //console.log("inside 2");

    //   src = "../assets/images/dual-predisposizione3D.jpg";
    //   caption = "";
    //   thumb = "../assets/images/dual-predisposizione3D-S.jpg";
    //   album = {
    //     src: src,
    //     caption: "PREDISPOSIZIONE - Impianto Dual",
    //     thumb: thumb
    //   };

    //   this.albums.push(album);
    // }

    // if (this.selectedPlan == preventivoService.getTRIAL()) {
    //   src = "../assets/images/trial-predisposizione3D.jpg";
    //   caption = "";
    //   thumb = "../assets/images/trial-predisposizione3D-S.jpg";
    //   album = {
    //     src: src,
    //     caption: "PREDISPOSIZIONE - Impianto Trial",
    //     thumb: thumb
    //   };

    //   this.albums.push(album);
    // }
  }

  // open(index: number): void {
  //   // open lightbox
  //   this._lightbox.open(this.albums, index);
  // }

  // close(): void {
  //   // close lightbox programmatically
  //   this._lightbox.close();
  // }

  ngOnInit() {

   // console.log("inside ngOnInIt")

    //console.log("getLavaggio", this.preventivoService.getPosizione())
    if (this.preventivoService.getPosizione()) {
      this.optionPosizione = this.preventivoService.getPosizione();
    }
    this.loading = true;
    this.hidePage = true;
    this.hideLoader = false;
   // console.log("inside predisposition split ngOnInit");
    try {
      this.loading = true;
      this.hideLoader = false;
      this.hidePage = true;
      this.climaSmartService
        .GetQuestionResponseList("predisposizioneCondizionatore")
        .subscribe(data => {
          (this.QuestionResponseList = data),
            (this.Question = this.QuestionResponseList[0].questionLabel),
            (this.ResponseLabelNO = this.QuestionResponseList[0].responseLabel),
            (this.ResponseLabelSI = this.QuestionResponseList[1].responseLabel),
            (this.ResponseIdNO = this.QuestionResponseList[0].idWebSiteFlow),
            (this.ResponseIdSI = this.QuestionResponseList[1].idWebSiteFlow),
           // console.log("xxxx", this.QuestionResponseList);
          this.loading = false;
          this.hidePage = false;
          this.hideLoader = true;
        }
        , err => {

          //console.error("ngOnInit Error ", err);
          if (err.status === 401) {
            this.climaSmartService.logoutAndRedirect();
            this.loading = false;
            this.hidePage = false;
            this.hideLoader = true;
          }
        });
    } catch {
     // console.log("inside catch");
      console.exception("lineSelected Exception ");
      this.loading = true;
      this.hidePage = true;
      this.hideLoader = false;
    }
  }

  isChecked(selected: any) {

    //console.log("isChecked value", selected)

    if (selected == this.preventivoService.getPosizione()) {
      //this.selectedZone = event;
      return true
    }

    return false
  }

  //showInputImage( ){

  //};

  //event andler
  radioChangeHandler(event: any) {
    this.optionPosizione = event.target.value;
   // console.log("selected Posizione : ", this.optionPosizione);

    // if (this.optionPosizione == "NO") {
    //   this.showInputImage = true;
    // } else this.showInputImage = false;
  }

  // setDistanzaPredispozisioneCondizionatore() {
  //   this.preventivoService.setPredispozisioneCondizionatore(
  //     this.optionPredispozisioneCondizionatore
  //   );

  //   this.router.navigate(["/smontaggio"]);
  // }

  // setDistanzaPredispozisioneCondizionatore() {
  //   this.preventivoService.setDistanzaPredispozisioneCondizionatore(
  //     parseInt(this.inputDistanza)
  //   );
  //   this.preventivoService.setPosizione;

  //   this.router.navigate(["/smontaggio"]);
  // }

  setPosizionePredispozisioneCondizionatore() {
    this.preventivoService.setPosizione(this.optionPosizione);
    // var nextRoute: string = "/smontaggio";
    var nextRoute: string = this.QuestionResponseList[0].pageNameNext;
    if (this.optionPosizione == this.ResponseIdSI) {
      nextRoute = this.QuestionResponseList[1].pageNameNext;
    }

    this.router.navigate([nextRoute]);
  }

  // setSmontaggio() {

  //   this.preventivoService.setSmontaggio(this.optionSmontaggio);
  //   var nextRoute : string = "/lavaggio";
  //   if(this.optionSmontaggio == this.preventivoService.getSI())
  //   {
  //     this.preventivoService.setLavaggio(this.preventivoService.getSI());
  //     nextRoute="/dati-cliente";
  //   }

  //   this.router.navigate([nextRoute]);

  // }

  // setDistanzaMotoreSplit() {

  //   this.preventivoService.setDistanzaMotoreSplit(parseInt(this.inputDistanza));
  //   this.router.navigate(['/staffa']);

  // }

  onBlurMethod() {
    //console.log("Distance : ", this.inputDistanza);
  }

  goBack() {
    this.preventivoService.setPosizione('');
    this._location.back();
  }
}
