import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-area-riservata",
  templateUrl: "./area-riservata.component.html",
  styleUrls: ["./area-riservata.component.css"]
})
export class AreaRiservataComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  login() {}
}
