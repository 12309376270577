import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ListModal } from '../_models/list-modal';

@Component({
  selector: 'app-modal-list',
  templateUrl: './modal-list.component.html',
  styleUrls: ['./modal-list.component.css']
})
export class ModalListComponent implements OnInit {

  @ViewChild('childModal') public childModal: ModalDirective;
  modal: ListModal = { title: '', subtitle: '' }

  constructor() { }

  ngOnInit() {
  }

  public show(modal: ListModal) {
    this.modal = modal
    this.childModal.show()
  }

  hide() {
    this.childModal.hide()
  }

}
